import { useState, useEffect } from 'react'
import {
  Icon,
  Modal,
  Button,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { CardError, Uploader } from 'components'
import { useTranslation } from 'react-i18next'
import { useEvent, useReactRouter, useRequest } from 'hooks'
import { advanceFlagStatus } from 'requests/flags'
import { formatErrorMessage } from 'utils/formatting'
import { getBatchFilename } from 'shifts/ShiftDataEntry/utils'
import { reuploadDigitalBatch } from 'requests/shifts'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import { getPageFromFilename, getTotalPagesFromFilename } from 'shifts/utils'
import { usePacketAssignment } from '../packetAssignment/PacketAssignmentContext'
import { useCurrentFlagContext } from './contexts/CurrentFlagContext'

const FlagSingleReuploadButton = () => {
  const { t } = useTranslation()
  const { history, match } = useReactRouter()

  const { currentFlag } = useCurrentFlagContext()
  const { unassignPacket } = usePacketAssignment()

  const currentPacket = currentFlag.shift
  const currentTrigger = currentFlag.trigger

  const [isOpen, setOpen] = useState(false)
  const [didSucceed, setDidSucceed] = useState(false)
  const [nextRoute, setNextRoute] = useState('')
  const [files, setFiles] = useState(null)

  const [, setCurrentPacketId] = useCurrentPacket()
  const { makeRequest, hasErrors, errors } = useRequest(
    async batch => {
      await reuploadDigitalBatch(currentPacket.id, batch)
      await unassignPacket(currentPacket)
      await advanceFlagStatus(match.params.flagId, 'review')
      setNextRoute(`/quality_control/flags/${match.params.type}`)
      setCurrentPacketId(currentPacket.id, true)
    },
    {
      onSuccess: () => {
        setDidSucceed(true)
      },
    }
  )

  const handleIfUploadsComplete = useEvent(() => {
    if (files?.every(page => page !== null)) {
      const cover_sheet = files[0]
      makeRequest({
        cover_sheet,
        filename: getBatchFilename(cover_sheet.file_locator.metadata.filename),
        forms: files.slice(1),
      })
    }
  })

  useEffect(() => {
    handleIfUploadsComplete()
  }, [files])

  const handleSeparatedScans = async fileData => {
    const page = getPageFromFilename(fileData.metadata.filename)
    const totalPages = getTotalPagesFromFilename(fileData.metadata.filename)

    setFiles(files => {
      let newFiles = files
      if (!newFiles || newFiles.length !== totalPages) {
        newFiles = new Array(totalPages).fill(null)
      }
      newFiles[page] = {
        file_locator: fileData,
      }
      return [...newFiles]
    })
  }

  const canReupload = (type, trigger) =>
    type !== 'start' && type !== 'complete' && trigger && trigger.needs_reupload

  return (
    <>
      {canReupload(match.params.type, currentTrigger) && (
        <ButtonBlock>
          <Button.Secondary
            onClick={() => {
              setDidSucceed(false)
              setOpen(true)
              setFiles(null)
            }}
          >
            {t('Reupload')}
          </Button.Secondary>
        </ButtonBlock>
      )}
      {isOpen && (
        <Modal
          id="reupload-packet-modal"
          onHide={() => {
            if (didSucceed) {
              history.push(nextRoute)
            }
            setOpen(false)
          }}
          title={t('Reupload Packet')}
          isOpen={isOpen}
        >
          <Modal.Body>
            <CardError hide={!hasErrors} message={formatErrorMessage(errors)} />
            {didSucceed ? (
              <div>
                <div className="space">
                  {t('The packet has been successfully reuploaded')}
                </div>
                <div className="space">
                  <Icon.Check />
                </div>
              </div>
            ) : (
              <Uploader
                label={t('Scan')}
                onUpload={handleSeparatedScans}
                autoUpload
                error={formatErrorMessage(errors)}
                fileTypes={['.pdf']}
                endpoint="/system/files/upload?url=true"
                stringifyData={false}
                fileValidator={file => {
                  // eslint-disable-next-line no-useless-escape
                  const invalidChars = file.name.match(/[^\w\.]/)

                  if (invalidChars) {
                    return `Filename contains invalid characters: ${invalidChars.join(
                      ', '
                    )}`
                  }
                }}
                splitPDFs
              />
            )}
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button.Accent
                onClick={() => {
                  if (didSucceed) {
                    history.push(nextRoute)
                  }
                  setOpen(false)
                }}
              >
                {t('Done')}
              </Button.Accent>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default FlagSingleReuploadButton
