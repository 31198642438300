import { NavLink } from 'react-router-dom'
import { Icon, Font } from '@politechdev/blocks-design-system'
import { useNavigation } from 'layout/NavigationProvider/NavigationProvider'
import classNames from 'classnames/bind'
import logo from 'assets/images/logo.svg'
import logoMark from 'assets/images/logo-mark.svg'
import styles from './Header.module.scss'

const cx = classNames.bind(styles)

const Header = () => {
  const { isCollapsed, toggleCollapsed } = useNavigation()

  return (
    <header className={cx('header', { 'header--collapsed': isCollapsed })}>
      <NavLink alt="Dashboard" to="/" exact className={styles.logo}>
        <img src={isCollapsed ? logoMark : logo} alt="Blocks" />
      </NavLink>
      <button
        type="button"
        className={cx('toggle', {
          'toggle--collapsed': isCollapsed,
        })}
        alt="Expand / collapse"
        onClick={() => toggleCollapsed()}
      >
        <Font.Copy variant="hint" className={styles.collapse__button__text}>
          {isCollapsed ? <Icon.ArrowRight /> : <Icon.ArrowLeft />}
        </Font.Copy>
      </button>
    </header>
  )
}

export default Header
