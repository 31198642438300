import { sumBy, uniq } from 'lodash'
import { alphaNumericCompare } from 'utils/array'
import { isInParentTurfScope } from 'utils/turfs'
import {
  EligibleShift,
  EligibleRegistrationForm,
  Delivery,
} from '../DeliveryContext'
import { FormWithShiftInfo, ResolutionType } from './types'

function getIsFormInDeliveryTurf(form: FormWithShiftInfo, delivery: Delivery) {
  return isInParentTurfScope(delivery.office, form.shift.turf)
}

export function getFormIsRegistrationForm(form: EligibleRegistrationForm) {
  return !form.visual_review_responses.some(
    response => response.implies_not_form
  )
}

function formOnOtherDelivery(form: FormWithShiftInfo, delivery: Delivery) {
  return form.delivery_id && form.delivery_id !== delivery.id
}

export function formIneligibleOnlyByCounty(
  form: FormWithShiftInfo,
  delivery: Delivery
) {
  return (
    getFormIsRegistrationForm(form) &&
    !formOnOtherDelivery(form, delivery) &&
    getIsFormInDeliveryTurf(form, delivery)
  )
}

export function getAssembledCount(assembledPacketIds: Array<string>): number {
  return assembledPacketIds.length
}

export function getEligibleCount(packets: Array<EligibleShift>): number {
  return sumBy(packets, packet => packet.forms.length)
}

export function getIneligibleCount(
  ineligibleLookupCodes: Array<string>
): number {
  return ineligibleLookupCodes.length
}

export function getIncludedCounties(delivery: Delivery): string[] {
  return delivery.form_filter_counties
}

export function getCompletedPercent(assembled: number, total: number) {
  if (!assembled || !total) return 1
  return Math.round((assembled / total) * 100)
}

export function getIneligibilityData(
  delivery: Delivery,
  form: FormWithShiftInfo
) {
  if (!getIsFormInDeliveryTurf(form, delivery)) {
    return {
      reason: 'Wrong turf',
      type: 'Ineligible form',
    }
  }
  if (form.delivery_id && form.delivery_id !== delivery.id) {
    return {
      reason: 'Already in delivery {{ deliveryId }}',
      type: 'Ineligible form',
    }
  }
  if (!!form.county && !delivery.form_filter_counties.includes(form.county)) {
    return {
      reason: 'Wrong county',
      type: 'Ineligible form',
      resolutionType: 'county' as ResolutionType,
    }
  }
  if (!form.county && !delivery.form_filters_no_county) {
    return {
      reason: 'Wrong county',
      type: 'Ineligible form',
      resolutionType: 'county' as ResolutionType,
    }
  }
  if (!getFormIsRegistrationForm(form)) {
    return {
      reason: 'Not a form',
      type: 'Invalid QC response',
      resolutionType: 'assemble' as ResolutionType,
    }
  }
  return {
    reason: 'Unknown',
    type: 'Unkown eligibility',
  }
}

export const formFields = [
  'id',
  'scan_number',
  'county',
  'delivery_id',
  'display_name',
  'notes',
  'lookup_code',
  'created_at',
  'file_url',
  { phone_verification_responses: ['notes'] },
  { visual_review_responses: ['implies_not_form'] },
  { flags: ['status'] },
  {
    shift: [
      'id',
      'name',
      'shift_start',
      'shift_end',
      'status',
      { location: ['state'] },
      { turf: ['lft', 'rgt', 'id'] },
    ],
  },
] as const

export const buildCountyOptions = (
  eligibleFormIds: number[],
  packets: EligibleShift[]
) => {
  const counties = packets.flatMap(packet =>
    packet.forms
      .filter(form => eligibleFormIds.includes(form.id) && !!form.county)
      .map(form => form.county!)
  )

  return uniq(counties)
    .toSorted(alphaNumericCompare)
    .map(county => ({ value: county, label: county }))
}
