import { ButtonBlock, Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const GalleryButtonBlock = ({ shift, scanNumber }) => {
  const { t } = useTranslation()

  const usePledgeCardGallery = shift.forms.some(form => form.pledge_card_url)

  const gallery = usePledgeCardGallery
    ? '/quality_control/pledge_gallery'
    : '/quality_control/gallery'

  const pledgeLink = `${gallery}/${shift.id}?type=Form&firstScanId=${scanNumber}`
  const scanLink = `${gallery}/${shift.id}?scans[]=${scanNumber}`

  return (
    <ButtonBlock>
      <Button
        flat
        primary
        iconChildren="picture_as_pdf"
        iconBefore={false}
        onClick={() =>
          window.open(usePledgeCardGallery ? pledgeLink : scanLink, '_blank')
        }
        disabled={!scanNumber}
      >
        {t('View Scan')}
      </Button>
      <Button
        flat
        primary
        iconChildren="library_add"
        iconBefore={false}
        onClick={() => window.open(`${gallery}/${shift.id}`, '_blank')}
      >
        {t(`${usePledgeCardGallery ? 'Pledge Card' : 'Packet'} Gallery`)}
      </Button>
    </ButtonBlock>
  )
}

export default GalleryButtonBlock
