import { combineName } from 'utils/reporting'
import { baseMatchProcessColumns } from '../columns/baseMatchProcessColumns'

export default {
  id: 'match_process_by_canvasser',
  name: 'Canvasser',
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    ...baseMatchProcessColumns,
  ],
}
