import { ReactNode, useState } from 'react'
import {
  ContentBlock,
  ProgressBar,
  SectionLabel,
  Sheet,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useTranslation } from 'react-i18next'
import tableConfig from './assembledTableConfig'
import ScanInspect from './ScanInspect'
import { FormWithShiftInfo } from './types'

const AssembledTable = ({
  assembled,
  formByIdMap,
  isLoading,
}: {
  assembled: string[]
  formByIdMap: Record<string, FormWithShiftInfo>
  isLoading: boolean
}) => {
  const { t } = useTranslation()
  const { renderTable } = useAutoTable() as {
    renderTable: (props: {
      data: Array<FormWithShiftInfo>
      columns: unknown
      emptyHeight: number
      emptyMessage: string
      loading: boolean
      totalRecords: number
      fetchAction: () => void
    }) => ReactNode
  }

  const [inspectedFormKey, setInspectedFormKey] = useState<null | number>(null)

  const tableData: Array<FormWithShiftInfo | undefined> = assembled.map(
    id => formByIdMap[id]
  )

  const columns = [
    {
      actions: [
        {
          primaryText: t('View form'),
          onClick: (form: FormWithShiftInfo) => {
            setInspectedFormKey(form.id)
          },
        },
      ],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  if (tableData.some(rowData => rowData === undefined)) {
    return (
      <ContentBlock>
        <Sheet>
          <ProgressBar show={isLoading} />
        </Sheet>
      </ContentBlock>
    )
  }

  return (
    <ContentBlock>
      <Sheet>
        <TextBlock>
          <SectionLabel>{t('Assembled')}</SectionLabel>
        </TextBlock>
        {!inspectedFormKey &&
          renderTable({
            fetchAction: () => {},
            columns,
            data: tableData as FormWithShiftInfo[],
            loading: false,
            totalRecords: tableData.length,
            emptyHeight: 200,
            emptyMessage: 'No forms assembled',
          })}
        {!!inspectedFormKey && (
          <ScanInspect
            isAssembledForm
            form={formByIdMap[inspectedFormKey]}
            setInspectedFormKey={setInspectedFormKey}
          />
        )}
      </Sheet>
    </ContentBlock>
  )
}

export default ({
  assembled,
  formByIdMap,
  isLoading,
}: {
  assembled: string[]
  formByIdMap: Record<string, FormWithShiftInfo>
  isLoading: boolean
}) => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    showQuickSearch
    showDownloadButton
  >
    <AssembledTable
      assembled={assembled}
      formByIdMap={formByIdMap}
      isLoading={isLoading}
    />
  </AutoTableProvider>
)
