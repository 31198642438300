import { Link, useRouteMatch } from 'react-router-dom'
import {
  CardError,
  Sheet,
  Stepper,
  Step,
  FormattedData,
  View,
} from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  ContentBlock,
  Icon,
  Font,
  PageHeader,
  Grid,
  DetailItem,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { DELIVERY_STATUSES } from 'constants/deliveries'
import { useEvent } from 'hooks'
import { useCurrent } from 'contexts'
import SelectionStep from './SelectionStep'
import ManualReview from './ManualReview'
import DeliveryStep from './DeliveryStep'
import DeliveredStep from './DeliveredStep'
import DeliveryDeleteModal from '../DeliveryDeleteModal/DeliveryDeleteModal'
import DeliveryWarnVdrModal from '../DeliveryWarnVdrModal/DeliveryWarnVdrModal'
import DeliveryContextProvider, {
  useDeliveryActions,
  useDeliveryState,
} from './DeliveryContext'
import useDeliverySingle from './hooks/useDeliverySingle'
import { getSelectedCounties } from './utils'
import ScanAssemblyStep from './ScanAssemblyStep'

const deliveryMethods = {
  mail: 'Mail',
  in_person: 'In person',
}

const DeliverySingle = () => {
  const { t } = useTranslation()
  const match = useRouteMatch()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canModifyDelivery = doesCurrentUserHavePermission({
    resource: 'delivery',
    ability: 'modify',
  })

  const {
    selectedFormIds,
    currentDelivery,
    isDeliveryLoading,
    deliveryError,
    filteredPackets,
  } = useDeliveryState()

  const { updateDeliveryStatus } = useDeliveryActions()

  const shouldUseVdrs =
    currentDelivery &&
    currentDelivery.office.voter_registration_config.uses_canvasser_vdrs

  const usesLookupCodes =
    !!currentDelivery?.office.voter_registration_config.uses_scan_lookup_codes

  const { modalIsOpen, setModalIsOpen, issueCounties, handleSelectionNext } =
    useDeliverySingle(shouldUseVdrs)

  const checkVdrAndSelect = useEvent(() => {
    const formIds = usesLookupCodes ? [] : selectedFormIds
    return handleSelectionNext(
      updateDeliveryStatus,
      match,
      formIds,
      currentDelivery.canvasser,
      getSelectedCounties(filteredPackets, selectedFormIds)
    )
  })

  const statusConfig = {
    [DELIVERY_STATUSES.SELECTION]: {
      step: 1,
      label: t('Ready for selection'),
      color: '#F8E71C',
    },
    [DELIVERY_STATUSES.REVIEW]: {
      step: 2,
      label: t('Ready for review'),
      color: '#F5A623',
    },
    [DELIVERY_STATUSES.DELIVERY]: {
      step: 3,
      label: t('Ready for delivery'),
      color: '#4A90E2',
    },
    [DELIVERY_STATUSES.COMPLETED]: {
      step: 4,
      label: t('Delivered'),
      color: '#0ED5C5',
    },
  }

  if (isDeliveryLoading || !currentDelivery) {
    return <DeliveryLoading />
  }

  if (deliveryError) {
    return <DeliveryError />
  }

  const { step, ...statusIndicator } = statusConfig[currentDelivery.status]

  return (
    <View>
      <ContentBlock>
        <Sheet>
          <DeliveryHeader
            currentDelivery={currentDelivery}
            statusIndicator={statusIndicator}
            canEdit={canModifyDelivery}
          />
          <Grid>
            <DeliveryDetails
              currentDelivery={currentDelivery}
              statusIndicator={statusIndicator}
            />
            <DetailItem label={t('Notes')}>
              <TextBlock>
                {currentDelivery.notes ? (
                  <Font.Copy variant="reading-block">
                    {currentDelivery.notes}
                  </Font.Copy>
                ) : (
                  <Link
                    onClick={e => !canModifyDelivery && e.preventDefault()}
                    to={`${match.url}/edit`}
                  >
                    <Button
                      primary
                      disabled={!canModifyDelivery}
                      aria-label={t('Edit')}
                      alt={t('Edit')}
                    >
                      Add Notes
                    </Button>
                  </Link>
                )}
              </TextBlock>
            </DetailItem>
          </Grid>
          <DeliveryWarnVdrModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            deliveryStepRequest={() =>
              updateDeliveryStatus(match.params.id, 'select', selectedFormIds)
            }
            issueCounties={issueCounties}
          />
        </Sheet>
        <Sheet>
          <Stepper horizontal activeStep={step}>
            <Step
              label={t('Select')}
              hidePreviousButton
              nextButtonLabel={t('Commit forms for review')}
              customNextButtonFunction={checkVdrAndSelect}
              disableNext={!selectedFormIds || !selectedFormIds.length}
            >
              <SelectionStep />
            </Step>
            {usesLookupCodes ? (
              <ScanAssemblyStep updateDeliveryStatus={updateDeliveryStatus} />
            ) : (
              <Step
                label={t('Review')}
                nextButtonLabel={t('Complete review')}
                previousButtonLabel={t('Go back to selection')}
                onNext={() => updateDeliveryStatus(match.params.id, 'review')}
                onPrevious={() =>
                  updateDeliveryStatus(match.params.id, 'select', [])
                }
              >
                <ManualReview deliveryId={currentDelivery.id} />
              </Step>
            )}
            <Step
              label={t('Deliver')}
              previousButtonLabel={t('Go back to selection review')}
              hideNextButton
              onPrevious={() => updateDeliveryStatus(match.params.id, 'review')}
            >
              <DeliveryStep currentDelivery={currentDelivery} />
            </Step>
            <Step
              summaryStep
              label={t('Delivered')}
              hidePreviousButton
              onPrevious={() =>
                updateDeliveryStatus(match.params.id, 'deliver')
              }
            >
              <DeliveredStep currentDelivery={currentDelivery} />
            </Step>
          </Stepper>
        </Sheet>
      </ContentBlock>
    </View>
  )
}

const DeliveryLoading = () => {
  const { t } = useTranslation()

  return (
    <View loading>
      <ContentBlock>
        <Sheet customHeader>
          <PageHeader title={t('Loading')} />
        </Sheet>
      </ContentBlock>
    </View>
  )
}

const DeliveryError = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ContentBlock>
        <Sheet>
          <TextBlock>
            <CardError
              message={t(
                'An internal error occurred while fetching this delivery'
              )}
            />
          </TextBlock>
        </Sheet>
      </ContentBlock>
    </View>
  )
}

const DeliveryHeader = ({ currentDelivery, statusIndicator, canEdit }) => {
  const { t } = useTranslation()
  const match = useRouteMatch()

  return (
    <PageHeader title={`${t('Delivery')} ${currentDelivery.id}`}>
      {statusIndicator && (
        <ContentBlock className="delivery__single__status">
          <i
            className="delivery__single__status__icon"
            style={{ backgroundColor: statusIndicator.color }}
          />
          <Font.Copy variant="hint">{statusIndicator.label}</Font.Copy>
        </ContentBlock>
      )}
      <ButtonBlock justify="right">
        <Link
          onClick={e => !canEdit && e.preventDefault()}
          to={`${match.url}/edit`}
        >
          <Button disabled={!canEdit} aria-label={t('Edit')} alt={t('Edit')}>
            <Icon.Pencil />
          </Button>
        </Link>
        {currentDelivery.status === DELIVERY_STATUSES.SELECTION && (
          <DeliveryDeleteModal />
        )}
      </ButtonBlock>
    </PageHeader>
  )
}

const DeliveryDetails = ({ currentDelivery, statusIndicator }) => {
  const { t } = useTranslation()

  return (
    <>
      <DeliveryDetail label={t('Due')}>
        {currentDelivery.turn_in_date ? (
          <FormattedData type="date" value={currentDelivery.turn_in_date} />
        ) : (
          'Not specified'
        )}
      </DeliveryDetail>
      <DeliveryDetail label={t('Total registrations')}>
        {currentDelivery.status === DELIVERY_STATUSES.SELECTION
          ? 'Not yet assembled'
          : currentDelivery.attached_forms_count}
      </DeliveryDetail>
      <DeliveryDetail label={t('Delivery location')}>
        {currentDelivery.turn_in_location.name}
      </DeliveryDetail>
      <DeliveryDetail label={t('Runner')}>
        {currentDelivery.canvasser.full_name}
      </DeliveryDetail>
      <DeliveryDetail label={t('Status')}>
        {statusIndicator && statusIndicator.label}
      </DeliveryDetail>
      <DeliveryDetail label={t('Office of origin')}>
        {currentDelivery.office.name}
      </DeliveryDetail>
      <DeliveryDetail label={t('Type')}>
        {currentDelivery.delivery_method
          ? deliveryMethods[currentDelivery.delivery_method]
          : 'Not specified'}
      </DeliveryDetail>
    </>
  )
}

const DeliveryDetail = ({ label, children }) => (
  <DetailItem label={label}>
    <TextBlock>
      <Font.Copy>{children}</Font.Copy>
    </TextBlock>
  </DetailItem>
)

export default () => (
  <DeliveryContextProvider>
    <DeliverySingle />
  </DeliveryContextProvider>
)
