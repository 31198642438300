import React from 'react'
import { useRouteQueryParams } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useReactRouter, useRoutePathParams } from 'hooks/router'
import { ExclamationCircle } from 'components/uiKit/icons'
import PropTypes from 'prop-types'
import styles from './ScanListItem.module.scss'

const ScanListItem = ({ scan, onClick }) => {
  if (!scan) return <div>scan not found</div>

  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [queryParams, setQueryParams] = useRouteQueryParams()
  const [{ packetId: stringPacketId }, replaceRouteParams] =
    useRoutePathParams()
  const packetId = +stringPacketId

  const pushToPacket = newPacketId => {
    if (newPacketId === packetId) return
    const newRoute = replaceRouteParams({ packetId: newPacketId })
    return history.push(newRoute)
  }

  const handleItemClick = (scanId, packetId) => {
    setQueryParams({ ...queryParams, scanId }, 'replace')
    if (packetId) pushToPacket(packetId)
    if (onClick) onClick()
  }

  const scanId = scan?.id
  const packetName = scan.shift.name
  const issueRemoved = scan.qc_history?.length > 0

  return (
    <div
      className={styles.item}
      onClick={() => {
        handleItemClick(scanId, scan.shift.id)
      }}
      onKeyPress={e =>
        e.charCode === 13 && handleItemClick(scanId, scan.shift.id)
      }
      role="button"
      tabIndex="0"
    >
      <strong className="triggered-scans-list__list-item__packet-name">
        {issueRemoved ? (
          <>
            <ExclamationCircle className={styles.icon} title="issue removed" />
            <del>{packetName}</del>
          </>
        ) : (
          packetName
        )}
      </strong>
      <span className="triggered-scans-list__list-item__scan-id">
        {t('Scan')} #{scan.scan_number}
      </span>
    </div>
  )
}

ScanListItem.propTypes = {
  scan: PropTypes.object.isRequired,
}

export default ScanListItem
