import {
  buildDefaultFilters,
  formatActiveColumn,
  formatDeliveryStatus,
  formatQcStatus,
} from 'utils/reporting'
import { YES_NO_OPTIONS } from 'registrationReports/constants'

export default {
  id: 'qc_status',
  name: 'Packet Status',
  permissionId: 'qc_program',
  description:
    'This report provides information on how many total forms and total shifts are in each stage of QC, using cover sheet counts.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=1997694548',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'active_turf',
      title: 'Active Turf',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.turf_archived),
    },
    {
      dataKey: 'qc_status',
      title: 'QC Status',
      type: 'string',
      deriveFunction: ({ rowData }) => formatQcStatus(rowData.qc_status),
      resizable: true,
    },
    {
      dataKey: 'delivered',
      title: 'Delivery Status',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) => formatDeliveryStatus(rowData.delivered),
    },
    {
      dataKey: 'total_shifts',
      title: 'Total Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_forms_cover',
      title: 'Total Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'complete_forms_cover',
      title: 'Complete Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incomplete_forms_cover',
      title: 'Incomplete Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'oldest_upload_date',
      title: 'Oldest Upload Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'most_recent_upload_date',
      title: 'Most Recent Upload Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'oldest_collection_date',
      title: 'Oldest Collection Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'most_recent_collection_date',
      title: 'Most Recent Collection Date',
      type: 'date',
      resizable: true,
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Default Filter',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'qc_status',
          'delivered',
          'total_shifts',
          'total_forms_cover',
          'oldest_upload_date',
        ],
      },
      {
        title: 'Ready for Delivery',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'qc_status',
          'delivered',
          'total_shifts',
          'total_forms_cover',
          'complete_forms_cover',
          'incomplete_forms_cover',
          'oldest_upload_date',
          'oldest_collection_date',
        ],
        rules: [
          {
            column: 'qc_status',
            operator: 'is',
            param: 'Completed',
            id: 'qcstatusiscompleted',
          },
          {
            column: 'delivered',
            operator: 'is',
            param: 'Not Delivered',
            id: 'deliveredisNotDelivered',
          },
        ],
      },
    ])
  },
}
