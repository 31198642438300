const phoneMethods = ['manual', 'voip'] as const
export type PhoneMethod = (typeof phoneMethods)[number]

export function hasPhoneMethod(
  currentUser: {
    turf: { options: { phone_methods: PhoneMethod[] } }
  },
  method: PhoneMethod
): boolean {
  const {
    turf: {
      options: { phone_methods },
    },
  } = currentUser

  return phone_methods.includes(method)
}
