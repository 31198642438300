import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  READY,
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'

const DISABLE_DURATION = 1000

const CallPanelControls = ({
  callStep,
  startCall,
  endCall,
  disabled,
  endSession,
  resetCall,
}) => {
  const { t } = useTranslation()
  const [controlsDisabled, setControlsDisabled] = useState(false)
  const controlsTimeout = useRef()

  const disableControls = () => {
    setControlsDisabled(true)
    controlsTimeout.current = setTimeout(() => {
      setControlsDisabled(false)
    }, DISABLE_DURATION)
  }

  useEffect(() => () => clearTimeout(controlsTimeout.current), [])

  const dialButtonProps = {
    [READY]: {
      text: 'START CALL',
      action: startCall,
    },
    [CONNECTING]: {
      text: 'END CALL',
      action: endCall,
    },
    [ACTIVE]: {
      text: 'END CALL',
      action: endCall,
    },
  }

  return (
    <ButtonBlock>
      {callStep === REVIEW && (
        <Button.Secondary
          disabled={controlsDisabled}
          onClick={() => {
            disableControls()
            resetCall()
          }}
        >
          {t('Try again')}
        </Button.Secondary>
      )}
      {callStep !== REVIEW && callStep !== COMPLETE && (
        <Button.Accent
          onClick={() => {
            disableControls()
            dialButtonProps[callStep].action()
          }}
          disabled={disabled || controlsDisabled}
        >
          {dialButtonProps[callStep].text}
        </Button.Accent>
      )}
      <Button
        onClick={endSession}
        disabled={callStep !== READY || controlsDisabled}
      >
        {t('End session')}
      </Button>
    </ButtonBlock>
  )
}

export default CallPanelControls

CallPanelControls.propTypes = {
  callStep: PropTypes.string,
  startCall: PropTypes.func,
  endCall: PropTypes.func,
  disabled: PropTypes.bool,
  endSession: PropTypes.func,
  resetCall: PropTypes.func,
}
