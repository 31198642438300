import nanoid from 'nanoid'
import { createContext, useContext, useEffect, useState } from 'react'
import { useRequest } from 'hooks/useRequest'
import { difference } from 'lodash'
import { useRouteMatch } from 'react-router'
import {
  fetchEligiblePackets,
  fetchDelivery,
  fetchPacket,
  updateDeliveryRequest,
  deliveryEvent,
  getEligibleFormIds,
} from './utils'

export const DeliveryStateContext = createContext()
export const DeliveryActionContext = createContext()

const removeNonForms = packet => {
  const forms = packet.forms.filter(
    form =>
      !form.visual_review_responses.some(
        response => response.implies_not_form === true
      )
  )
  return { ...packet, forms }
}

const DeliveryContextProvider = ({ children }) => {
  const match = useRouteMatch()

  const [currentDelivery, setCurrentDelivery] = useState()
  const [pendingUpdates, setPendingUpdates] = useState([])

  const applyOptimisticDeliveryUpdate = attrs => {
    const id = nanoid()
    setPendingUpdates(current => [...current, { id, change: attrs }])
    return () =>
      setPendingUpdates(current => current.filter(change => change.id !== id))
  }

  const [packets, setPackets] = useState([])

  const [packetSortType, setPacketSortType] = useState('alpha')
  const [packetSortOrder, setPacketSortOrder] = useState('ASC')
  const [packetSearchTerm, setPacketSearchTerm] = useState('')

  const [currentPacket, setCurrentPacket] = useState({
    name: '',
    forms: [],
  })

  const {
    makeRequest: makeDeliveryRequest,
    isLoading: isDeliveryLoading,
    hasErrors: fetchDeliveryHasErrors,
  } = useRequest(fetchDelivery, {
    onSuccess: async ({ delivery }) => {
      setCurrentDelivery(delivery)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateDeliveryStatus } = useRequest(deliveryEvent, {
    onSuccess: ({ delivery }) => {
      setCurrentDelivery(delivery)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makePacketRequest } = useRequest(fetchPacket, {
    onSuccess: ({ shift }) => {
      setCurrentPacket(shift)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: makePacketsRequest,
    isLoading: packetsLoading,
    hasErrors: fetchPacketsHasErrors,
  } = useRequest(fetchEligiblePackets, {
    onSuccess: ({ shifts }) => setPackets(shifts),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: updateDelivery, isLoading: isUpdateDeliveryLoading } =
    useRequest(updateDeliveryRequest, {
      onSuccess: ({ delivery }) => {
        setCurrentDelivery(delivery)
      },
    })

  useEffect(() => {
    if (match.params.id) {
      makeDeliveryRequest(match.params.id)
    }
  }, [match.params.id])

  const eligibleFormIds = getEligibleFormIds({
    delivery: currentDelivery,
    pendingUpdates,
    packets,
    searchTerm: packetSearchTerm,
  })

  const filteredPackets = packets
    .filter(({ forms }) =>
      forms.some(form => eligibleFormIds.includes(form.id))
    )
    .map(removeNonForms)

  const selectedFormIds = currentDelivery?.excluded_forms
    ? difference(
        eligibleFormIds,
        currentDelivery.excluded_forms.map(f => f.id)
      )
    : []

  const deliveryError = fetchDeliveryHasErrors || fetchPacketsHasErrors

  return (
    <DeliveryStateContext.Provider
      value={{
        selectedFormIds,
        eligibleFormIds,
        currentDelivery,
        isDeliveryLoading,
        isUpdateDeliveryLoading,
        deliveryError,
        packets,
        filteredPackets,
        packetsLoading,
        packetSortType,
        packetSortOrder,
        packetSearchTerm,
        currentPacket,
        pendingUpdates,
      }}
    >
      <DeliveryActionContext.Provider
        value={{
          setPacketSortType,
          setPacketSortOrder,
          setPacketSearchTerm,
          makePacketRequest,
          makePacketsRequest,
          setCurrentDelivery,
          updateDeliveryStatus,
          updateDelivery,
          setCurrentPacket,
          applyOptimisticDeliveryUpdate,
        }}
      >
        {children}
      </DeliveryActionContext.Provider>
    </DeliveryStateContext.Provider>
  )
}

export const useDeliveryState = () => useContext(DeliveryStateContext)
export const useDeliveryActions = () => useContext(DeliveryActionContext)

export default DeliveryContextProvider
