import { calculateRate, summaryFunctions } from 'utils/reporting'

const baseQcSummaryColumns = [
  {
    dataKey: 'total_hours',
    title: 'Total Hours',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) => rowData.total_minutes / 60,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'total_scans_viz',
    title: 'Forms Viz QC',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'total_scans_phone',
    title: 'Forms called',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'role_name',
    title: 'Role name',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'qc_office',
    title: 'QC Office',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'total_shifts',
    title: 'Total shifts',
    type: 'number',
    resizable: true,
  },
  {
    dataKey: 'avg_shift_length',
    title: 'Avg Shift Length (in hours)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(rowData.total_minutes / 60, rowData.total_shifts),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customRatio(data, acc, ['total_hours', 'total_shifts']),
  },
  {
    dataKey: 'viz_qc_rate',
    title: 'Viz QC rate (per hour)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(rowData.total_scans_viz, rowData.total_minutes / 60),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customRatio(data, acc, [
        'total_scans_viz',
        'total_hours',
      ]),
  },
  {
    dataKey: 'phone_qc_rate',
    title: 'Phone QC rate (per hour)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(rowData.total_scans_phone * 3, rowData.total_minutes / 60),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customRatio(data, acc, [
        'total_scans_phone',
        'total_hours',
      ]) * 3,
  },
  {
    dataKey: 'total_qc_rate',
    title: 'Total QC rate (per hour) (Day)',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculateRate(
        (rowData.total_scans_phone * 3 + rowData.total_scans_viz) / 4,
        rowData.total_minutes / 60
      ),
    summaryFunction: ({ data }) => {
      const total_scans_phone = data.reduce(
        (acc, row) => row.total_scans_phone + acc,
        0
      )
      const total_scans_viz = data.reduce(
        (acc, row) => row.total_scans_viz + acc,
        0
      )
      const total_hours = data.reduce((acc, row) => row.total_hours + acc, 0)

      return calculateRate(
        (total_scans_phone * 3 + total_scans_viz) / 4,
        total_hours
      )
    },
  },
]

export default baseQcSummaryColumns
