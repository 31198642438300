import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { View, CardError, ViewContainer } from 'components'
import {
  ButtonBlock,
  Button,
  Icon,
  ProgressBar,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useRequest, useReactRouter, useRoutePathParams } from 'hooks'
import {
  defaultOptions,
  fetchCanvasser,
  putCanvasser,
  destroyCanvasser,
} from 'requests/canvassers'
import { createChartId, fetchChartData } from 'requests/charts'
import { SHIFT_TYPE } from 'shifts/constants'
import { useCurrent } from 'contexts/index'
import CanvasserReport from './CanvasserReport/CanvasserReport'
import CanvasserSummary from './CanvasserSummary/CanvasserSummary'
import CanvasserContextProvider, {
  CanvasserContext,
} from '../CanvasserContext/CanvasserContext'
import CanvasserIssueFlags from './CanvasserIssueFlags/CanvasserIssueFlags'
import CanvasserShifts from './CanvasserShifts/CanvasserShifts'
import CanvasserFeedback from './CanvasserFeedback/CanvasserFeedback'
import { buildStats, buildStatsByDate } from './statUtils'

const composeDeleteErrorMsg = errorsObj => {
  const keys = Object.keys(errorsObj)
  const values = Object.values(errorsObj)
  const composedError = values.reduce((errorMsg, value, index) => {
    if (index === values.length - 1) {
      return `${errorMsg}${keys[index]} ${value}`
    }
    return `${errorMsg}${keys[index]} ${value}, `
  }, '')
  return composedError.charAt(0).toUpperCase() + composedError.slice(1)
}

const CanvasserSingle = () => {
  const { t } = useTranslation()
  const [{ id, shiftType }] = useRoutePathParams()
  const canvasserId = +id
  const { match, history } = useReactRouter()
  const {
    currentUser: { time_zone: timezone },
    currentTurfPerformsExternalQC: qcExternal,
  } = useCurrent()
  const {
    currentCanvasser,
    setCurrentCanvasser,
    setCanvasserStats,
    setOtherCanvasserStats,
    setAvgStats,
    canvasserStatsLoading,
    setCanvasserVisualReviews,
    setCanvasserStatsLoading,
    setCanvasserStatsByDate,
    setCanvasserVisualReviewsLoading,
    setAvgStatsByDate,
    canvasserStatsByDateLoading,
    setCanvasserStatsByDateLoading,
    reportRange,
    debouncedReportRange,
  } = useContext(CanvasserContext)

  const isRegistrationCanvasser = shiftType === SHIFT_TYPE.REGISTRATION

  const { isLoading, makeRequest, hasErrors, isRequestComplete } = useRequest(
    () =>
      fetchCanvasser(canvasserId, {
        fields: [
          'id',
          'first_name',
          'last_name',
          'full_name',
          'phone_number',
          'notes',
          'email',
          'archived',
          { turf: ['id', 'name', 'archived'] },
          {
            flags: [
              'id',
              { trigger: ['name', 'implies_canvasser_issue'] },
              { shift: 'name' },
              { comments: ['created_at'] },
              'created_at',
              { triggered_by_forms: ['scan_number'] },
            ],
          },
          {
            shifts: [
              'id',
              'delivered',
              'name',
              'status',
              'completed_at',
              'shift_start',
              'shift_end',
              'field_start',
              'field_end',
              { location: ['name'] },
              {
                forms: [
                  {
                    phone_verification_responses: [
                      'response',
                      'notes',
                      'created_at',
                      { caller: ['full_name', 'email'] },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        indexed: true,
      }),
    {
      onSuccess: ({ canvasser }) => setCurrentCanvasser(canvasser),
    }
  )

  const getCanvasserStats = async id => {
    const { statusCode, response } = await fetchChartData(id)

    if (statusCode === 204) {
      return setTimeout(() => {
        getCanvasserStats(id)
      }, 3000)
    }

    setCanvasserStatsLoading(false)

    setCanvasserStats(
      buildStats(
        response.rows.find(row => +row.canvasser_id === +canvasserId) || {}
      )
    )

    setOtherCanvasserStats(
      response.rows
        .filter(row => row.canvasser_id && +row.canvasser_id !== +canvasserId)
        .map(row => buildStats(row))
    )

    setAvgStats(buildStats(response.rows.find(row => !row.canvasser_id)) || {})
  }

  const getCanvasserStatsByDate = async id => {
    const { statusCode, response } = await fetchChartData(id)

    if (statusCode === 204) {
      return setTimeout(() => {
        getCanvasserStatsByDate(id)
      }, 3000)
    }

    setCanvasserStatsByDateLoading(false)

    setCanvasserStatsByDate(
      buildStatsByDate(
        response.rows.filter(row => row.canvasser_name !== 'Turf Average')
      )
    )

    setAvgStatsByDate(
      buildStatsByDate(
        response.rows.filter(row => row.canvasser_name === 'Turf Average')
      )
    )
  }

  const getCanvasserVisualReviews = async id => {
    const { statusCode, response } = await fetchChartData(id)

    if (statusCode === 204) {
      return setTimeout(() => {
        getCanvasserVisualReviews(id)
      }, 3000)
    }

    setCanvasserVisualReviewsLoading(false)

    setCanvasserVisualReviews(response.rows)
  }

  const requestCanvasserStats = async () => {
    setCanvasserStatsLoading(true)
    const { id } = await createChartId('canvasser_stats', {
      canvasser_id: +canvasserId,
      start_date: reportRange.startDate.slice(0, 10),
      end_date: reportRange.endDate.slice(0, 10),
      time_zone: timezone,
      qc_external: qcExternal,
    })
    getCanvasserStats(id)
  }

  const requestCanvasserStatsByDate = async () => {
    setCanvasserStatsByDateLoading(true)
    const { id } = await createChartId('canvasser_stats_by_date', {
      canvasser_id: +canvasserId,
      start_date: reportRange.startDate.slice(0, 10),
      end_date: reportRange.endDate.slice(0, 10),
      time_zone: timezone,
      qc_external: qcExternal,
    })
    getCanvasserStatsByDate(id)
  }
  const requestCanvasserVisualReviews = async () => {
    setCanvasserVisualReviewsLoading(true)
    const { id } = await createChartId('canvasser_visual_review_responses', {
      canvasser_id: +canvasserId,
      start_date: reportRange.startDate.slice(0, 10),
      end_date: reportRange.endDate.slice(0, 10),
      time_zone: timezone,
      qc_external: qcExternal,
    })
    getCanvasserVisualReviews(id)
  }

  const {
    makeRequest: updateRequest,
    isLoading: updateIsLoading,
    hasErrors: updateHasErrors,
  } = useRequest(
    (canvasserId, data) => putCanvasser(canvasserId, data, defaultOptions),
    {
      onSuccess: () => {
        history.push('../canvassers')
      },
    }
  )

  const {
    makeRequest: deleteRequest,
    isLoading: deleteIsLoading,
    hasErrors: deleteHasErrors,
    errors: deleteErrors,
  } = useRequest(canvasserId => destroyCanvasser(canvasserId), {
    onSuccess: () => {
      history.push('../canvassers')
    },
  })

  const deleteErrorMsg = composeDeleteErrorMsg(deleteErrors)

  useEffect(() => {
    makeRequest(canvasserId)
  }, [canvasserId])

  useEffect(() => {
    if (isRegistrationCanvasser) {
      requestCanvasserStatsByDate()
      requestCanvasserStats()
      requestCanvasserVisualReviews()
    }
  }, [
    canvasserId,
    debouncedReportRange.startDate,
    debouncedReportRange.endDate,
  ])

  const canvasserLoaded =
    isRequestComplete &&
    !updateIsLoading &&
    !deleteIsLoading &&
    !hasErrors &&
    !updateHasErrors &&
    !deleteHasErrors &&
    currentCanvasser?.id === +canvasserId

  return (
    <View>
      <ProgressBar
        show={
          isLoading ||
          updateIsLoading ||
          deleteIsLoading ||
          (canvasserStatsLoading && isRegistrationCanvasser) ||
          (canvasserStatsByDateLoading && isRegistrationCanvasser)
        }
      />
      <CardError
        hide={!hasErrors && !updateHasErrors && !deleteHasErrors}
        message={deleteErrorMsg ?? t("We're unable to retrieve this canvasser")}
      />
      {canvasserLoaded && (
        <div>
          <PageHeader
            title={currentCanvasser.full_name}
            subtitle={currentCanvasser.turf.name}
          >
            <ButtonBlock justify="right">
              {currentCanvasser.person_id && (
                <Link to={`/organize/people/${currentCanvasser.person_id}`}>
                  <Button.Accent>{t('View my person profile')}</Button.Accent>
                </Link>
              )}
              <Link to={`${match.url}/edit`}>
                <Button tooltipLabel={t('Edit')} tooltipPosition="bottom">
                  <Icon.Pencil />
                </Button>
              </Link>
              <Button
                tooltipLabel={
                  currentCanvasser.archived ? t('Unarchive') : t('Archive')
                }
                tooltipPosition="bottom"
                onClick={() =>
                  updateRequest(canvasserId, {
                    archived: !currentCanvasser.archived,
                  })
                }
                disabled={currentCanvasser.turf.archived}
              >
                {currentCanvasser.archived ? (
                  <Icon.InboxOut />
                ) : (
                  <Icon.Archive />
                )}
              </Button>
              <Button
                tooltipLabel={t('Delete')}
                tooltipPosition="bottom"
                onClick={() => deleteRequest(canvasserId)}
              >
                <Icon.TrashAlt />
              </Button>
            </ButtonBlock>
          </PageHeader>
          <CanvasserSummary />
          <ViewContainer>
            {isRegistrationCanvasser && <CanvasserReport />}
            {isRegistrationCanvasser && <CanvasserIssueFlags />}
            <CanvasserShifts />
            {isRegistrationCanvasser && <CanvasserFeedback />}
          </ViewContainer>
        </div>
      )}
    </View>
  )
}

export default () => (
  <CanvasserContextProvider>
    <CanvasserSingle />
  </CanvasserContextProvider>
)
