import {
  SectionLabel,
  List,
  ListItem,
  ContentBlock,
  Font,
  ButtonBlock,
  Button,
} from '@politechdev/blocks-design-system'
import { CanvasserContext } from 'canvassers/CanvasserContext/CanvasserContext'
import { Paginator } from 'components/index'
import { useReactRouter } from 'hooks/router'
import moment from 'moment'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

const CanvasserShifts = () => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(0)
  const { currentCanvasser } = useContext(CanvasserContext)
  const { history } = useReactRouter()

  const canvasserShifts = currentCanvasser.shifts
    .map(shift => ({
      packetName: shift.name,
      completionDate: shift.completed_at,
      delivered: !!shift.delivered,
      collectionDate: shift.shift_start,
      location: shift.location.name,
      id: shift.id,
    }))
    .sort((a, b) => Date.parse(b.collectionDate) - Date.parse(a.collectionDate))

  const paginatedShifts = canvasserShifts.slice(
    currentPage * 5,
    Math.min(currentPage * 5 + 5, canvasserShifts.length)
  )

  return (
    <section>
      <SectionLabel>{t('Shifts')}</SectionLabel>
      <List
        itemData={paginatedShifts}
        render={item => (
          <ListItem>
            <ContentBlock>
              <Font.Copy>{item.packetName || t('No packet')}</Font.Copy>
              <Font.Copy variant="hint">
                {item.completionDate
                  ? `${t('Finished QC on')} ${moment(
                      item.completionDate
                    ).format('ll')}, ${
                      item.delivered ? t('delivered') : t('not yet delivered')
                    }`
                  : `${t('Not yet completed')}, ${
                      item.delivered ? t('delivered') : t('not yet delivered')
                    }`}
              </Font.Copy>
            </ContentBlock>
            <ContentBlock>
              <Font.Copy>
                {t('Collected on')} {moment(item.collectionDate).format('ll')}
              </Font.Copy>
              <Font.Copy variant="hint">{item.location}</Font.Copy>
            </ContentBlock>
            <ButtonBlock justify="right">
              <Button.Secondary
                onClick={() =>
                  history.push(
                    `/collect/voter_registration/shifts/${item.id}/packet`
                  )
                }
                disabled={!item.packetName}
              >
                View
              </Button.Secondary>
            </ButtonBlock>
          </ListItem>
        )}
      />
      <Paginator
        currentPage={currentPage + 1}
        totalPages={Math.ceil(canvasserShifts.length / 5) || 1}
        onNext={() => setCurrentPage(old => old + 1)}
        onPrevious={() => setCurrentPage(old => old - 1)}
        justify="left"
      />
    </section>
  )
}

export default CanvasserShifts
