import county from './reports/county'
import countyTablet from './reports/countyTablet'
import districtByLocation from './reports/districtByLocation'
import location from './reports/location'
import locationParty from './reports/locationParty'
import locationTablet from './reports/locationTablet'
import partisanScoreByLocation from './reports/partisanScoreByLocation'
import partyByLocation from './reports/partyByLocation'
import partyLeanByLocation from './reports/partyLeanByLocation'

const reportConfigs = [
  location,
  locationTablet,
  locationParty,
  county,
  countyTablet,
  partyByLocation,
  partyLeanByLocation,
  partisanScoreByLocation,
  districtByLocation,
]

export default {
  reportCategory: 'locations',
  categoryTitle: 'Location Reports',
  reportConfigs,
}
