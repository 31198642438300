import { useEffect, useState } from 'react'
import { useCurrent, useAutoTable, useFilters } from 'contexts'
import { useBackgroundRequest } from 'hooks'
import { areAllFiltersValid } from 'components/DataTable/TableFilters/utils'
import { createReportId, fetchReportData } from 'requests/registrationReports'
import { convertToUTCYMD } from 'utils/dateTime'
import { formatReportData } from 'registrationReports/utils'

const ReportTable = ({ reportType, dateRange, turfId }) => {
  const {
    filterRules,
    isLoading: filterLoading,
    errorMsg: filterErrorMsg,
  } = useFilters()
  const { renderTable } = useAutoTable()

  const { currentTurfPerformsExternalQC } = useCurrent()

  const [reportData, setReportData] = useState({})

  const {
    makeRequest: reportReq,
    isLoading: isReportReqLoading,
    errorMsg: reportReqErrorMsg,
    response: reportReqResponse,
  } = useBackgroundRequest({
    key: reportType.id,
    requestId: type =>
      createReportId(type, {
        start_date: convertToUTCYMD(dateRange.start),
        end_date: convertToUTCYMD(dateRange.end),
        turf_id: turfId,
        qc_external: currentTurfPerformsExternalQC,
      }).then(({ id }) => id),
    requestData: async reportId => {
      const { statusCode, response } = await fetchReportData(reportId)
      if (statusCode === 204) return
      return { id: reportId, response }
    },
  })

  useEffect(() => {
    if (reportReqResponse) {
      setReportData(formatReportData(reportReqResponse))
    }
  }, [reportReqResponse])

  useEffect(() => {
    reportReq()
  }, [dateRange, turfId, reportType])

  if (!areAllFiltersValid(filterRules, reportType.columns)) {
    return null
  }

  return renderTable({
    data: reportData.data,
    sqlQuery: reportData.sqlQuery,
    loading: isReportReqLoading || filterLoading,
    error: reportReqErrorMsg || filterErrorMsg,
    columns: reportType.columns,
    rowKey: reportType.rowKey || 'id',
    showTotals: reportType.showTotals ?? true,
    fetchAction: reportReq,
  })
}

export default ReportTable
