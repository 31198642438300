import {
  combineName,
  buildDefaultFilters,
  formatActiveColumn,
} from 'utils/reporting'
import baseValidatedFormColumns from 'registrationReports/reportsConfig/columns/baseValidatedFormsColumns'
import { YES_NO_OPTIONS } from 'registrationReports/constants'

export default {
  id: 'validated_forms_by_canvasser',
  name: 'Canvasser',
  description: '',
  permissionId: 'form_validation',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=541603218',
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'canvasser_id',
      title: 'Canvasser ID',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'active_canvasser',
      title: 'Active Canvasser',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.canvasser_archived),
    },
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    ...baseValidatedFormColumns,
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Default Filter',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          ...baseValidatedFormColumns.visibleColumns,
        ],
      },
    ])
  },
}
