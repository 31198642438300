import { Switch, Route } from 'react-router-dom'
import { View } from 'components'
import { PhoneVerificationQuestionsProvider } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import ScriptsSelect from './ScriptsSelect'
import ScriptEdit from './ScriptEdit'
import CallScriptsContextProvider from './CallScriptsContext'

const Scripts = ({ match }) => (
  <PhoneVerificationQuestionsProvider>
    <CallScriptsContextProvider>
      <Switch>
        <Route exact path={`${match.url}/:scriptId`}>
          <View>
            <ScriptEdit />
          </View>
        </Route>
        <Route exact path={`${match.url}`}>
          <View>
            <ScriptsSelect />
          </View>
        </Route>
      </Switch>
    </CallScriptsContextProvider>
  </PhoneVerificationQuestionsProvider>
)

export default Scripts
