import { useState } from 'react'
import {
  Grid,
  ContentBlock,
  Font,
  Section,
  Button,
  TextBlock,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  EligibleShift,
  useDeliveryActions,
  useDeliveryState,
} from '../DeliveryContext'
import {
  getAssembledCount,
  getEligibleCount,
  getIncludedCounties,
  getIneligibleCount,
} from './utils'
import styles from './AssembleStats.module.scss'
import AddCountyModal from './Modals/AddCountyModal'
import { FormWithShiftInfo, IneligibleFormsData } from './types'

const AssemblyStats = ({
  packets,
  assembled,
  ineligible,
  ineligibleFormsData,
  bulkAssemble,
  isBulkAssembleLoading,
}: {
  packets: Array<EligibleShift>
  assembled: Array<string>
  ineligible: Array<string>
  ineligibleFormsData: IneligibleFormsData
  bulkAssemble: (forms: FormWithShiftInfo[]) => Promise<void>
  isBulkAssembleLoading: boolean
}) => {
  const { t } = useTranslation()
  const [isAddCountiesOpen, setIsAddCountiesOpen] = useState(false)

  const { updateDelivery } = useDeliveryActions()
  const { currentDelivery, isUpdateDeliveryLoading } = useDeliveryState()

  const updateDeliveryCounties = (newCounties: string[]) => {
    const selectedCounties = [
      ...currentDelivery.form_filter_counties,
      ...newCounties,
    ]
    return updateDelivery(currentDelivery.id, {
      form_filter_counties: selectedCounties,
    })
  }

  const handleDeliveryCountyUpdate = async (
    newCounties: string[],
    newlyEligibleForms: FormWithShiftInfo[]
  ) => {
    await updateDeliveryCounties(newCounties)
    await bulkAssemble(newlyEligibleForms)
  }

  const assembledCount = getAssembledCount(assembled)
  const eligibleCount = getEligibleCount(packets)

  const ineligibleCount = getIneligibleCount(ineligible)

  const includedCounties = getIncludedCounties(currentDelivery)

  return (
    <Section label={t('Assemble forms and prepare delivery')}>
      <Grid className={styles.stats__container}>
        <ContentBlock>
          <Font.Display variant="small">{`${assembledCount}/${eligibleCount}`}</Font.Display>
          <Font.Label variant="detail">{t('Assembled')}</Font.Label>
        </ContentBlock>
        <ContentBlock>
          <Font.Display variant="small">{ineligibleCount}</Font.Display>
          <Font.Label variant="detail">{t('Ineligible')}</Font.Label>
        </ContentBlock>
        <ContentBlock>
          <TextBlock>
            <Font.Label variant="detail">{t('Included counties')}</Font.Label>
          </TextBlock>
          <TextBlock>
            <Font.Copy>{includedCounties.join(', ')}</Font.Copy>
            {currentDelivery.form_filters_no_county ? (
              <Font.Copy variant="hint">
                {t('Includes forms with no county')}
              </Font.Copy>
            ) : null}
          </TextBlock>
          <ButtonBlock>
            <Button.Secondary onClick={() => setIsAddCountiesOpen(true)}>
              {t('Add more counties')}
            </Button.Secondary>
          </ButtonBlock>
        </ContentBlock>
      </Grid>
      <AddCountyModal
        isOpen={isAddCountiesOpen}
        setIsOpen={setIsAddCountiesOpen}
        showLoading={isUpdateDeliveryLoading || isBulkAssembleLoading}
        handleContinue={handleDeliveryCountyUpdate}
        ineligibleFormsData={ineligibleFormsData}
      />
    </Section>
  )
}
export default AssemblyStats
