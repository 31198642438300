import {
  ListItem,
  ContentBlock,
  Font,
  Button,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRoutePathParams, useRequest, useEvent } from 'hooks'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  READY_FOR_QC,
  READY_FOR_PHONE_VERIFICATION,
  IN_QC,
  IN_PHONE_VERIFICATION,
} from 'constants/qualityControl'
import classNames from 'classnames/bind'
import { advanceShiftStatus } from 'requests/qualityControl'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { getScanLabel } from '../utils'
import styles from './InboxViewer.module.scss'

const PacketItem = ({ packet, isSidebarOpen }) => {
  const match = useRouteMatch()
  const { t } = useTranslation()
  const cx = classNames.bind(styles)

  const { replacePacket } = useQualityControl()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: advancePacket } = useRequest(
    (shift, status) => advanceShiftStatus(shift.id, status),
    {
      onSuccess: newShift => {
        replacePacket({
          ...packet,
          ...newShift,
        })
      },
    }
  )

  const advanceToQc = useEvent(packet => advancePacket(packet, IN_QC))

  const advanceToPhoneVerification = useEvent(packet =>
    advancePacket(packet, IN_PHONE_VERIFICATION)
  )

  const { push } = useHistory()

  const isActive = +match.params.packetId === +packet.id
  const [{ packetId }, replaceParams] = useRoutePathParams()

  const selectPacket = () => {
    const { id, in_viz_qc, status } = packet
    if (status === READY_FOR_QC) {
      advanceToQc(packet)
    }
    if (status === READY_FOR_PHONE_VERIFICATION) {
      advanceToPhoneVerification(packet)
    }
    if (id !== packetId) {
      const nextUrl = replaceParams({ packetId: undefined })
      push(`${nextUrl}/${id}/${in_viz_qc ? 'visual_qc' : 'phone_verification'}`)
    }
  }

  if (!packet) return null

  return (
    <ListItem
      className={cx({
        'list-item--collapsed': isSidebarOpen,
        'list-item--collapsed--active': isSidebarOpen && isActive,
      })}
    >
      <div onClick={isSidebarOpen ? selectPacket : () => null}>
        <ContentBlock>
          <Font.Copy variant="highlight" className={styles.text}>
            {packet.name}
          </Font.Copy>
          <Font.Copy variant="hint" className={styles.text}>
            {getScanLabel(packet)}
          </Font.Copy>
        </ContentBlock>
      </div>
      {!isSidebarOpen && (
        <ButtonBlock key="actions" justify="right">
          <Button onClick={selectPacket}>{t('Review')}</Button>
        </ButtonBlock>
      )}
    </ListItem>
  )
}

export default PacketItem
