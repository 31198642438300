import { baseMatchProcessColumns } from '../columns/baseMatchProcessColumns'

export default {
  id: 'match_process_by_turf',
  name: 'Turf',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    ...baseMatchProcessColumns,
  ],
}
