import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { assembleForm, getIneligibleFormData } from 'requests/deliveries'
import RequestException from 'utils/RequestException'
import { putForm } from 'requests/registrationForms'
import AssemblyStats from './ScannerAssembly/AssemblyStats'
import AssemblyMain from './ScannerAssembly/AssemblyMain'
import IneligibleList from './ScannerAssembly/IneligibleList'
import AssembledTable from './ScannerAssembly/AssembledTable'
import { useDeliveryActions, useDeliveryState } from './DeliveryContext'
import {
  FormWithShiftInfo,
  IneligibleFormsData,
  UpdateFormRequest,
} from './ScannerAssembly/types'
import { useScannerAssemblyContext } from './ScannerAssembly/ScannerAssemblyContext'
import { formFields } from './ScannerAssembly/utils'

const ScannerAssembly = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const { currentDelivery, packets, packetsLoading } = useDeliveryState()
  const { assembled, setAssembled, assemblablePackets } =
    useScannerAssemblyContext()

  const [isBulkAssembleLoading, setIsBulkAssembleLoading] = useState(false)

  const [ineligible, setIneligible] = useState<string[]>([])
  const [formsLoading, setFormsLoading] = useState<string[]>([])
  const [ineligibleFormsData, setIneligibleFormsData] =
    useState<IneligibleFormsData>({})

  const lookupToFormIdMap = assemblablePackets
    .flatMap(shift => shift.forms)
    .reduce(
      (mapping, form) =>
        form.lookup_code
          ? { ...mapping, [form.lookup_code]: form.id }
          : mapping,
      {} as Record<string, number>
    )

  const formByIdMap = packets
    .flatMap(shift =>
      shift.forms.map(form => ({
        ...form,
        shift,
      }))
    )
    .reduce(
      (mapping, form) => {
        mapping[form.id.toString()] = form
        return mapping
      },
      {} as Record<string, FormWithShiftInfo>
    )

  const { makePacketsRequest } = useDeliveryActions()

  const addIneligibleFormData = (formData: FormWithShiftInfo) => {
    setIneligibleFormsData(current => ({
      ...current,
      [formData.lookup_code!]: formData,
    }))
  }

  const getIneligibleFormDataRequest = useRequest(
    async (lookupCode: string) => {
      setFormsLoading(current => [...current, lookupCode])
      return getIneligibleFormData<{
        registration_forms: Array<FormWithShiftInfo>
      }>({
        fields: formFields,
        filters: {
          rules: [
            {
              column: 'lookup_code',
              operator: 'is',
              param: lookupCode,
            },
          ],
        },
      }).finally(() =>
        setFormsLoading(current => current.filter(code => code !== lookupCode))
      )
    },
    {
      onSuccess: ({ registration_forms }) => {
        const foundForm = registration_forms.pop()
        if (foundForm) {
          addIneligibleFormData(foundForm)
        }
      },
      onError: e => {
        if (e instanceof RequestException && e.status === 404) {
          return
        }
        setToast({
          message: t(
            'An issue occurred while searching for a form matching this lookup code'
          ),
          variant: 'error',
        })
      },
    }
  )

  const updateFormRequest = useRequest(
    putForm<{
      registration_form: FormWithShiftInfo & {
        packet: { original_filename: string }
      }
    }>,
    {
      onSuccess: ({ registration_form }) => {
        addIneligibleFormData(registration_form)
        void makePacketsRequest(currentDelivery.id)
      },
      onError: e => {
        if (e instanceof RequestException && e.status === 404) {
          return
        }
        setToast({
          message: t('An issue occurred while attempting to update a form'),
          variant: 'error',
        })
      },
    }
  )

  const handleUpdateForm: UpdateFormRequest = (id, formData) =>
    updateFormRequest.makeRequest(id, formData, {
      fields: formFields,
    })

  useEffect(() => {
    if (currentDelivery.id) {
      void makePacketsRequest(currentDelivery.id)
    }
  }, [currentDelivery.id])

  useEffect(() => {
    setAssembled((currentDelivery.forms ?? []).map(({ id }) => id.toString()))
  }, [currentDelivery])

  const assembleScanRequest = useRequest<unknown>(assembleForm, {
    onError: () =>
      setToast({
        variant: 'error',
        message: t('Failed to add form to delivery'),
      }),
  })

  const bulkAssembleScanRequest = async (
    deliveryId: number,
    formIds: Array<number>
  ) => {
    setIsBulkAssembleLoading(true)
    const requests = formIds.map(async id => {
      await assembleForm(deliveryId, id)
      return id
    })
    const assembleResults: PromiseSettledResult<number>[] =
      await Promise.allSettled(requests)

    const successfulResults = assembleResults.filter(
      result => result.status === 'fulfilled'
    ) as PromiseFulfilledResult<number>[]

    if (assembleResults.find(result => result.status === 'rejected')) {
      setToast({
        variant: 'error',
        message: t('Failed to add one or more forms to delivery'),
      })
    }
    setIsBulkAssembleLoading(false)
    return successfulResults.map(result => result.value)
  }

  const addIneligible = (lookupCode: string) => {
    setIneligible(current =>
      current.includes(lookupCode) ? current : current.concat(lookupCode)
    )
    void getIneligibleFormDataRequest.makeRequest(lookupCode)
  }

  const assembleIneligible = async (form: FormWithShiftInfo) => {
    await assembleScanRequest.makeRequest(currentDelivery.id, form.id)
    setAssembled(current => {
      const formIdStr = form.id.toString()
      return current.includes(formIdStr) ? current : current.concat(formIdStr)
    })
    setIneligible(current => current.filter(code => code !== form.lookup_code))
  }

  const bulkAssemble = async (forms: FormWithShiftInfo[]) => {
    const assembledIds = await bulkAssembleScanRequest(
      currentDelivery.id,
      forms.map(({ id }) => id)
    )

    const assembledForms = forms.filter(({ id }) => assembledIds.includes(id))
    setAssembled(current =>
      current.concat(assembledForms.map(({ id }) => id.toString()))
    )
    setIneligible(current =>
      current.filter(
        code => !assembledForms.find(({ lookup_code }) => lookup_code === code)
      )
    )
  }

  return (
    <>
      <AssemblyStats
        packets={assemblablePackets}
        assembled={assembled}
        ineligible={ineligible}
        ineligibleFormsData={ineligibleFormsData}
        bulkAssemble={bulkAssemble}
        isBulkAssembleLoading={isBulkAssembleLoading}
      />
      <AssemblyMain
        packets={assemblablePackets}
        assembled={assembled}
        makeAssembleScanRequest={formId => {
          void assembleScanRequest.makeRequest(currentDelivery.id, formId)
          setAssembled(current => {
            const formIdStr = formId.toString()
            return current.includes(formIdStr)
              ? current
              : current.concat(formIdStr)
          })
        }}
        addIneligible={addIneligible}
        lookupToFormIdMap={lookupToFormIdMap}
        formByIdMap={formByIdMap}
      />
      <IneligibleList
        ineligible={ineligible}
        formsLoading={formsLoading}
        assembleIneligible={assembleIneligible}
        handleUpdateForm={handleUpdateForm}
        ineligibleFormsData={ineligibleFormsData}
        isLoading={
          updateFormRequest.isLoading || getIneligibleFormDataRequest.isLoading
        }
      />
      <AssembledTable
        assembled={assembled}
        formByIdMap={formByIdMap}
        isLoading={packetsLoading}
      />
    </>
  )
}

export default ScannerAssembly
