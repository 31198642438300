import { formCompositePdf, isError } from 'utils/pdfPagesToComposite'

type ObjectWithFileUrl = {
  file_url: string
}

type FormObject = ObjectWithFileUrl & {
  pledge_card_url?: string
}

export const getUrls = <T extends FormObject>(form: T): string[] | string =>
  form.pledge_card_url ? [form.file_url, form.pledge_card_url] : form.file_url

export const getPdf = async <T extends FormObject, U extends ObjectWithFileUrl>(
  forms: T[],
  coverSheet: U
): Promise<string | undefined> => {
  const urls = [coverSheet.file_url].concat(forms.flatMap(getUrls))
  const pdfDoc = await formCompositePdf(urls)

  if (!isError(pdfDoc)) {
    return pdfDoc.saveAsBase64({ dataUri: true })
  }
}
