import { useTranslation } from 'react-i18next'
import { Button, Icon, ButtonBlock } from '@politechdev/blocks-design-system'
import { CsvDownloadButton, TxtDownloadButton } from 'components'
import { useCurrent, useAutoTable, useFilters } from 'contexts'
import { permissionCategories as regReportCategories } from 'registrationReports/reportsConfig'
import { buildCsvData, buildCsvColumns } from '../utils'
import TableLinkButton from '../TableLinkButton/TableLinkButton'

const TableActions = ({
  data,
  sqlQuery,
  columns,
  showDownloadButton,
  enableFilters,
  isFrontend,
  totalRecords,
  headerActions,
  showTableLink,
  downloadName,
}) => {
  const { t } = useTranslation()
  const { tableId, startDownloading } = useAutoTable()
  const { hiddenColumns } = useFilters(enableFilters)
  const { doesCurrentUserHavePermission } = useCurrent()

  const downloadEnabled = showDownloadButton && (isFrontend || totalRecords > 0)

  const permissionId = [...regReportCategories].find(category =>
    category.reportTypes.includes(tableId)
  )?.permissionId

  return (
    <ButtonBlock justify="right">
      {headerActions}
      {showTableLink && <TableLinkButton url={window.location.href} />}
      {downloadEnabled && !isFrontend && (
        <Button.Secondary
          tooltipPosition="left"
          tooltipLabel={t('Download CSV')}
          onClick={startDownloading}
        >
          <Icon.Download />
        </Button.Secondary>
      )}
      {downloadEnabled &&
        (permissionId
          ? doesCurrentUserHavePermission({
              resource: permissionId,
              ability: 'download',
            })
          : true) &&
        isFrontend && (
          <CsvDownloadButton
            columnHeaders={buildCsvColumns(columns, hiddenColumns)}
            data={buildCsvData(data, columns)}
            filename={downloadName || `${tableId}.csv`}
          />
        )}
      {downloadEnabled &&
        (permissionId
          ? doesCurrentUserHavePermission({
              resource: permissionId,
              ability: 'sql',
            })
          : false) &&
        isFrontend && (
          <TxtDownloadButton
            txtString={sqlQuery}
            filename={downloadName || `${tableId}.txt`}
          />
        )}
    </ButtonBlock>
  )
}

export default TableActions
