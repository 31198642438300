import { CardError, List, ListItem } from 'components'
import {
  Section,
  ProgressBar,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { useRequest } from 'hooks'
import {
  getFlagItemPrimaryText,
  getFlagItemPrimaryIcon,
  getFlagItemSecondaryText,
  doesFlagHaveUnreadComments,
} from './utils'
import { ListPager } from './ListPager'
import ViewFlagButton from './buttons/ViewFlagButton'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'

const GroupQcActionList = ({ setListPage }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrent()

  const { filters, fetchGroupFlags, listPages } = useFlagsFilteredListContext()

  const currentPage = listPages.group

  const fetchGroupFlagsRequest = useRequest(fetchGroupFlags)

  const flagsErrorMsg = formatErrorMessage(fetchGroupFlagsRequest.errors)

  useEffect(() => {
    fetchGroupFlagsRequest.makeRequest(filters, currentPage)
  }, [currentPage, filters, currentUser.id])

  const flags = fetchGroupFlagsRequest.response?.['quality_control/flags'] || []
  const meta = fetchGroupFlagsRequest.response?.meta
  const totalCount = meta?.total_count ?? 0
  const per = meta?.per ?? 0
  const totalPages =
    per === 0 || totalCount < per ? 1 : Math.ceil(totalCount / per)

  return (
    <>
      <ProgressBar show={fetchGroupFlagsRequest.isLoading} />
      <CardError
        hide={!fetchGroupFlagsRequest.hasErrors}
        message={flagsErrorMsg}
      />
      {fetchGroupFlagsRequest.isRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`flag-awaiting-group-qc-action-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                primaryIcon={getFlagItemPrimaryIcon(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={() => <ViewFlagButton flagId={flag.id} />}
              />
            ))}
          </List>
          <ListPager
            currentPage={currentPage}
            setPage={setListPage}
            totalPages={totalPages}
          />
        </>
      )}
      {fetchGroupFlagsRequest.isRequestComplete && !flags.length && (
        <Section secondary collapsed>
          <TextBlock>
            {t('There are no flags awaiting group qc action')}
          </TextBlock>
        </Section>
      )}
    </>
  )
}

export default GroupQcActionList
