import {
  calculatePercent,
  calculateRate,
  combineName,
  formatActiveColumn,
  summaryFunctions,
} from 'utils/reporting'
import { YES_NO_OPTIONS } from 'registrationReports/constants'

export default {
  id: 'canvasser_quality_tablet',
  name: 'Field (tablet)',
  description:
    'This report compares total forms from the cover sheet to total forms from the scans and counts from the visual QC process by canvasser. The data is broken out by both raw totals and percentages.',
  permissionId: 'cover_sheet_tablet',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=663985049',
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'active_canvasser',
      title: 'Active Canvasser',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.canvasser_archived),
    },
    {
      dataKey: 'last_canvass_date',
      title: 'Last Canvass Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'total_scans_cover',
      title: 'Total Forms',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_field_hours_cover',
      title: 'Total Field Hours',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'scans_per_field_hour_cover',
      title: 'Forms per Field Hour',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_scans_cover,
          rowData.total_field_hours_cover
        ),
      summaryFunction: summaryFunctions.average,
    },
    {
      dataKey: 'total_shift_hours',
      title: 'Total Shift Hours',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_scans_qc',
      title: 'Total Forms Through QC',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'scans_with_phones_qc',
      title: 'Form with Phones',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_with_phones_qc',
      title: 'Percent with Phones',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.scans_with_phones_qc, rowData.total_scans_qc),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'percent_scans_sms',
      title: '% of Registrations with Cell Opt-in',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.total_scans_sms_qc, rowData.total_scans_qc),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'percent_scans_email',
      title: '% of Registrations with Email Opt-in',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.total_scans_email_qc, rowData.total_scans_qc),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'percent_scans_ssn',
      title: '% of Registrations with SSN',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.total_scans_ssn_qc, rowData.total_scans_qc),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'percent_scans_state_id',
      title: '% Registrations with State ID',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.total_scans_state_id_qc,
          rowData.total_scans_qc
        ),
      summaryFunction: summaryFunctions.avg,
    },
  ],
}
