import { useEffect } from 'react'
import { CardError } from 'components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { PageHeader, ProgressBar } from '@politechdev/blocks-design-system'
import {
  useRequest,
  useRoutePathParams,
  useRouteQueryParams,
  useReactRouter,
} from 'hooks'
import { fetchShift } from 'requests/shifts'
import GalleryHeader from '../GalleryHeader/GalleryHeader'
import GalleryCompare from '../GalleryCompare/GalleryCompare'
import GalleryScans from '../GalleryScans/GalleryScans'
import GalleryViewer from '../GalleryViewer/GalleryViewer'

const Gallery = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const location = useLocation()
  const [{ shiftId }] = useRoutePathParams()
  const [{ scans: selectedScanNums }] = useRouteQueryParams()

  const {
    makeRequest: fetchShiftRequest,
    isRequestComplete: isShiftReqComplete,
    response,
    hasErrors,
  } = useRequest(fetchShift, {
    onError: e => {
      if (e.status === 404) {
        history.push(`/not_found?from=${location.pathname}`)
      }
    },
  })

  const shift = response?.shift

  useEffect(() => {
    fetchShiftRequest(shiftId, {
      fields: [
        'shift_start',
        'name',
        { cover_sheet: ['file_url'] },
        {
          forms: [
            'id',
            'file_url',
            'file_locator',
            'scan_number',
            { visual_reviews: 'id' },
          ],
        },
        { canvasser: 'full_name' },
      ],
    })
  }, [shiftId])

  const renderGalleryContent = shift => {
    const { forms, cover_sheet } = shift

    if (selectedScanNums === undefined) {
      return <GalleryScans cover={cover_sheet} forms={forms} />
    }
    if (selectedScanNums.length === 1) {
      return (
        <GalleryViewer
          cover={cover_sheet}
          forms={forms}
          selectedNum={selectedScanNums[0]}
        />
      )
    }

    return (
      <GalleryCompare
        cover={cover_sheet}
        forms={forms}
        selectedScanNums={selectedScanNums}
      />
    )
  }

  if (hasErrors) return <CardError hide={false} />

  if (!isShiftReqComplete || !shift) return <ProgressBar show />

  return (
    <>
      <PageHeader title={`${t('Packet Gallery')} - ${shift.name}`} />
      <GalleryHeader shift={shift} />
      {renderGalleryContent(shift)}
    </>
  )
}

export default Gallery
