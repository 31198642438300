import { summaryFunctions } from 'utils/reporting'
import QCResponseNameCell from 'registrationReports/bodyCells/QCResponseNameCell/QCResponseNameCell'

export default {
  id: 'qc_by_visual_response',
  name: 'Visual QC Response',
  permissionId: 'qc_program',
  description: 'This report provides counts of visual qc reviews by response.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=2066303461',
  columns: [
    {
      dataKey: 'response_description',
      title: 'Name',
      type: 'string',
      resizable: true,
      bodyCell: QCResponseNameCell,
    },
    {
      dataKey: 'total_reviews',
      title: 'Total',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'implies_incomplete_form',
      title: 'Implies Incomplete Form',
      type: 'enum',
      options: [
        { label: 'true', value: 'true' },
        { label: 'false', value: 'false' },
      ],
      deriveFunction: ({ rowData }) =>
        rowData.implies_incomplete_form?.toString(),
      resizable: true,
    },
    {
      dataKey: 'implies_not_form',
      title: 'Implies Not Form',
      type: 'enum',
      options: [
        { label: 'true', value: 'true' },
        { label: 'false', value: 'false' },
      ],
      deriveFunction: ({ rowData }) => rowData.implies_not_form?.toString(),
      resizable: true,
    },
    {
      dataKey: 'newest_review_date',
      title: 'Most Recent Review Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'oldest_review_date',
      title: 'Oldest Review Date',
      type: 'date',
      resizable: true,
    },
  ],
}
