import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EnumMenuButton } from 'components/DataTable/TableFilters/EnumMenuButton'
import { NumStringMenuButton } from 'components/DataTable/TableFilters/NumStringMenuButton'
import { MultiChoiceMenuButton } from 'components/DataTable/TableFilters/MultiChoiceMenuButton'
import { nullableOperators } from './operatorsConfig'

const AddRuleMenu = ({
  column,
  setRuleDetails,
  setRuleColumn,
  operatorOptions,
}) => {
  const [isOpen, setOpen] = useState(true)
  const [selectedOperator, setOperator] = useState(operatorOptions[0].value)
  const [param, setParam] = useState('')
  const [isInverted, setIsInverted] = useState(undefined)

  const openMenu = () => setOpen(true)

  const handleCancelClick = () => {
    setOpen(false)
    setRuleColumn('')
  }

  const handleDoneClick = param => {
    setRuleDetails({
      operator: selectedOperator,
      param,
      invert: isInverted,
    })
    setOpen(false)
  }

  const { type, nullable = true } = column
  const isEnum = type === 'enum'
  const isMultiChoice = type === 'multiple_choice'

  const menuButtonProps = {
    isOpen,
    openMenu,
    operatorOptions: operatorOptions.concat(nullable ? nullableOperators : []),
    selectedOperator,
    setParam,
    handleDoneClick,
    handleCancelClick,
    column,
    param,
    setOperator,
    isInverted,
    setIsInverted,
  }
  if (isEnum) {
    return <EnumMenuButton {...menuButtonProps} />
  }
  if (isMultiChoice) {
    return <MultiChoiceMenuButton {...menuButtonProps} />
  }
  return <NumStringMenuButton {...menuButtonProps} />
}

AddRuleMenu.propTypes = {
  column: PropTypes.object.isRequired,
  setRuleDetails: PropTypes.func.isRequired,
  setRuleColumn: PropTypes.func.isRequired,
  operatorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      prefix: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      inputProps: PropTypes.object.isRequired,
      suffix: PropTypes.string,
    })
  ).isRequired,
}

export default AddRuleMenu
