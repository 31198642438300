import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Section, DetailItem, LinkToClipboard } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { useReactRouter } from 'hooks'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { EventContext } from '../EventContext/EventContext'

const EventDetails = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { currentEvent } = useContext(EventContext)

  return (
    <div>
      <Grid>
        <Section
          label={t('Date')}
          tight
          empty={!currentEvent.start_time}
          emptyMessage={t('Not set')}
        >
          <TextBlock>
            <Font.Copy>
              {moment(currentEvent.start_time).format(dateFormat)}
            </Font.Copy>
            <Font.Copy>
              {moment(currentEvent.start_time).format('h:mm A')}
              {' - '}
              {moment(currentEvent.end_time).format('h:mm A')}
            </Font.Copy>
          </TextBlock>
        </Section>
        <Section
          label={t('Venue')}
          empty={!currentEvent.location}
          emptyMessage={t('None')}
        >
          {currentEvent.location && (
            <TextBlock>
              <div>
                <Font.Copy>
                  {currentEvent.location.name}
                  <br />
                  {currentEvent.location.street_address}
                  <br />
                  {currentEvent.location.city}, {currentEvent.location.state}
                  <br />
                  {currentEvent.location.zipcode}
                </Font.Copy>
              </div>
            </TextBlock>
          )}
        </Section>
        <Section
          label={t('URL')}
          empty={!currentEvent.url}
          emptyMessage={t('None')}
        >
          <TextBlock>
            {currentEvent.url && (
              <LinkToClipboard
                label={currentEvent.url_label}
                url={currentEvent.url}
                showCopiedMessage
              />
            )}
          </TextBlock>
        </Section>
        <Section
          label={t('Type')}
          tight
          empty={!currentEvent.type}
          emptyMessage={t('No event type')}
        >
          <TextBlock>
            <Font.Copy>{currentEvent.type}</Font.Copy>
          </TextBlock>
        </Section>
      </Grid>
      <Section label={t('Notes')} tight>
        {currentEvent.notes ? (
          <TextBlock>
            <Font.Copy variant="reading-block">{currentEvent.notes}</Font.Copy>
          </TextBlock>
        ) : (
          <ButtonBlock>
            <Link to={`${match.url}/edit`}>
              <Button>Add Notes</Button>
            </Link>
          </ButtonBlock>
        )}
      </Section>
      <Section label={t('About this event')} tight>
        <TextBlock>
          <Font.Copy>{currentEvent.description}</Font.Copy>
        </TextBlock>
        <DetailItem
          label={t('Organization')}
          hide={!currentEvent.organization || !currentEvent.organization.id}
        >
          {currentEvent.organization ? currentEvent.organization.name : null}
        </DetailItem>
        <DetailItem
          label={t('Co-organizations')}
          hide={!currentEvent.coorganizations.length}
        >
          {currentEvent.coorganizations
            ?.map(coorganization => (
              <span key={coorganization.name}>&nbsp;{coorganization.name}</span>
            ))
            .reduce(
              (accu, elem) => (accu === null ? [elem] : [...accu, ',', elem]),
              null
            )}
        </DetailItem>
        <DetailItem label={t('Teams')} hide={!currentEvent.teams.length}>
          {currentEvent.teams
            ?.map(team => <span key={team.name}>&nbsp;{team.name}</span>)
            .reduce(
              (accu, elem) => (accu === null ? [elem] : [...accu, ',', elem]),
              null
            )}
        </DetailItem>
        <DetailItem
          label={t('Campaigns')}
          hide={!currentEvent.campaigns || !currentEvent.campaigns.length}
        >
          {currentEvent.campaigns
            ? currentEvent.campaigns.map(c => c.name).join(', ')
            : null}
        </DetailItem>
        <DetailItem label={t('Public page image')} hide={!currentEvent.public}>
          {currentEvent.public_page_header_file_locator?.metadata?.filename ||
            t('Default')}
        </DetailItem>
      </Section>
    </div>
  )
}

export default EventDetails
