import {
  basePartyColumns,
  partyDictionaryUrl,
} from 'registrationReports/reportsConfig/columns/basePartyColumns'

export default {
  id: 'party_by_location',
  name: 'Party Preference',
  permissionId: 'party_report',
  description:
    'This report provides information on the party preferences of registrants. It summarizes this information by the canvassing location.',
  dataDictionaryUrl: partyDictionaryUrl,
  columns: [
    {
      dataKey: 'location_name',
      title: 'Location Name',
      type: 'string',
      resizable: true,
    },
    ...basePartyColumns,
  ],
}
