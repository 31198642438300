import { AutoTableProvider, useAutoTable } from 'contexts'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import { useRequest, UseRequestActions, useRoutePathParams } from 'hooks'
import { fetchOrganizations } from 'requests/organizations'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import tableConfig from './tableConfig'

type Organizations = Array<{
  id: number
  name: string
  members_count: number
  organization_type: string
}>

type FetchOrganizations = {
  organizations: Organizations
  meta: {
    total_count: number
  }
}

type Params = {
  page: string
  pageSize: string
  sortColumn: string
  sortOrder: string
}

const TeamOrganizationsTable = () => {
  const [{ teamId }] = useRoutePathParams()

  const { renderTable } = useAutoTable() as {
    renderTable: (props: {
      data: Organizations
      columns: typeof tableConfig.columns
      isLoading: boolean
      totalRecords: number
      fetchAction: UseRequestActions<FetchOrganizations>['makeRequest']
      emptyMessage: string
      error: boolean
    }) => ReactNode
  }

  const { t } = useTranslation()

  const fetchOrganizationsRequest = useRequest<FetchOrganizations>(
    (params: Params) =>
      fetchOrganizations({
        current_page: params.page,
        per: params.pageSize,
        sort_attr: params.sortColumn,
        sort_dir: params.sortOrder,
        filters: {
          rules: [
            {
              column: 'teams',
              operator: 'is',
              param: teamId,
            },
          ],
        },
        fields: ['id', 'name', 'members_count', 'organization_type'],
      }) as Promise<FetchOrganizations>
  )

  return (
    <>
      <TextBlock>
        <Font.Label variant="section">{t('Organizations')}</Font.Label>
      </TextBlock>
      {renderTable({
        fetchAction: fetchOrganizationsRequest.makeRequest,
        isLoading: fetchOrganizationsRequest.isLoading,
        totalRecords:
          fetchOrganizationsRequest.response?.meta?.total_count || 0,
        data: fetchOrganizationsRequest.response?.organizations || [],
        error: fetchOrganizationsRequest?.hasErrors,
        columns: tableConfig.columns,
        emptyMessage: t('No organizations found for this team.'),
      })}
    </>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    enableSort
  >
    <TeamOrganizationsTable />
  </AutoTableProvider>
)
