import { useTranslation } from 'react-i18next'
import { PdfViewer } from 'components'
import { useRequest } from 'hooks'
import { getPdf } from './utils'

const ShiftPdfViewer = ({
  url,
  name,
  removedAt,
  pageNumber,
  showPaginator = true,
  showDownload = true,
  onNext,
  onPrevious,
  disabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <PdfViewer
      file={url}
      filename={name}
      documentLoadingMessage={t('Shift PDF loading...')}
      pageNumber={pageNumber}
      showPaginator={showPaginator}
      showDownload={showDownload}
      onNext={onNext}
      onPrevious={onPrevious}
      disabled={disabled}
      removedAt={removedAt}
    />
  )
}

const Composite = ({ forms, coverSheet, name }) => {
  const getUri = useRequest(() => getPdf(forms, coverSheet))

  if (!getUri.isRequestComplete && !getUri.isLoading) {
    getUri.makeRequest()
  }

  if (getUri.isLoading) {
    return <PdfViewer.PreLoader />
  }

  const removedAt = forms.find(f => !!f.file_locator.metadata.redacted_at)
    ?.file_locator.metadata.redacted_at

  return (
    <ShiftPdfViewer
      url={getUri.hasErrors ? '/' : getUri.response}
      name={name}
      removedAt={removedAt}
    />
  )
}

ShiftPdfViewer.Composite = Composite
export default ShiftPdfViewer
