import baseQcSummaryColumns from 'registrationReports/reportsConfig/columns/baseQcSummaryColumns'

export default {
  id: 'qc_summary_by_role',
  name: 'QC Work by Staff Role and Office',
  permissionId: 'qc_workflow',
  description:
    'This report provides information on quality control shifts, totals and rates for QC staff roles.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=601943128',
  columns: baseQcSummaryColumns,
}
