import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Font, Icon } from '@politechdev/blocks-design-system'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import GalleryScan from '../GalleryScan/GalleryScan'
import styles from './GalleryCarousel.module.scss'

const CarouselButtons = ({ next, previous }) => (
  <div className={styles.buttons}>
    <div className={styles.button}>
      <Button.Secondary onClick={previous}>
        <Icon.ArrowLeft />
      </Button.Secondary>
    </div>
    <div className={styles.button}>
      <Button.Secondary onClick={next}>
        <Icon.ArrowRight />
      </Button.Secondary>
    </div>
  </div>
)

const GalleryCarousel = ({ cover, forms, selectedScanNums }) => {
  const { t } = useTranslation()
  const [filterReviewed, setFilterReviewed] = useState(false)

  const formsOnCarousel = forms.filter(
    f =>
      !selectedScanNums.includes(`${f.scan_number}`) &&
      (filterReviewed ? !f.visual_reviews.length : true)
  )

  return (
    <div className={styles.carousel}>
      <Font.Display variant="small">{t('Compare to other scan')}</Font.Display>
      <div className={styles.wrapper}>
        <Carousel
          showDots
          keyBoardControl
          renderDotsOutside
          dotListClass={styles.dots}
          slidesToSlide={3}
          renderButtonGroupOutside
          customButtonGroup={<CarouselButtons />}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          arrows={false}
          draggable={false}
          swipeable={false}
        >
          {!selectedScanNums.includes('cover') && (
            <GalleryScan
              url={`?scans[]=${selectedScanNums[0]}&scans[]=cover`}
              removedAt={cover?.file_locator?.metadata?.redacted_at}
              cover={cover}
              width={350}
            />
          )}
          {formsOnCarousel.map(otherScan => (
            <GalleryScan
              url={`?scans[]=${selectedScanNums[0]}&scans[]=${otherScan.scan_number}`}
              removedAt={otherScan?.file_locator?.metadata?.redacted_at}
              scan={otherScan}
              width={350}
            />
          ))}
        </Carousel>
        <Checkbox
          checked={filterReviewed}
          onChange={setFilterReviewed}
          id="skip-reviewed"
          name="skip-reviewed"
          label={t('Skip reviewed scan')}
        />
      </div>
    </div>
  )
}

export default GalleryCarousel
