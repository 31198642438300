import { Switch, Route } from 'react-router-dom'
import EventSelect from '../EventSelect/EventSelect'
import EventNew from '../EventNew/EventNew'
import EventSingle from '../EventSingle/EventSingle'
import EventEditForm from '../EventEditForm/EventEditForm'
import PublicPage from '../PublicPage/PublicPage'

const EventRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={EventSelect} />
    <Route exact path={`${match.url}/new`} component={EventNew} />
    <Route exact path={`${match.url}/:id`} component={EventSingle} />
    <Route exact path={`${match.url}/:id/edit`} component={EventEditForm} />
    <Route exact path={`${match.url}/:id/public`} component={PublicPage} />
  </Switch>
)

export default EventRoutes
