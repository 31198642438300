import moment from 'moment'

import {
  calculatePercent,
  calculateRate,
  combineName,
  summaryFunctions,
} from 'utils/reporting'

export default {
  id: 'qc_by_response',
  name: 'Response',
  permissionId: 'qc_staff',
  description:
    'This report provides information on QC staff regarding the responses they have submitted for each scan reviewed.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1532106555',
  rowKey: 'email',
  columns: [
    {
      dataKey: 'qc_staffer',
      title: 'QC Staffer',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'total_visual_qc_days',
      title: 'Total Visual QC Days',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_scans',
      title: 'Total Visual QC Scans',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_qc',
      title: 'Total Forms Through Visual QC',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc + rowData.incomplete_scans_qc,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'complete_scans_qc',
      title: 'Complete Forms (Visual QC)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'incomplete_scans_qc',
      title: 'Incomplete Forms (Visual QC)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'scans_with_phones_qc',
      title: 'Forms with Phones (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.complete_scans_qc +
        rowData.incomplete_scans_qc -
        rowData.missing_phone_qc,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'approved_qc',
      title: 'Approved (Visual QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'missing_phone_qc',
      title: 'Missing Phone (Visual QC)',
      type: 'number',
      resizable: true,
    },

    {
      dataKey: 'reupload_qc',
      title: 'Reupload (Visual QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'handwriting_qc',
      title: 'Handwriting (Visual QC)',
      type: 'number',
      resizable: true,
    },

    {
      dataKey: 'not_form_qc',
      title: 'Not a Card (Visual QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_assistance_qc',
      title: 'Canvasser Assistance (Visual QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'other_qc',
      title: 'Other (Visual QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_complete_scans_qc',
      title: 'Percent Complete Forms (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.complete_scans_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'complete_scans_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_incomplete_scans_qc',
      title: 'Percent Incomplete Forms (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incomplete_scans_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'incomplete_scans_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_approved_qc',
      title: 'Percent Approved (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.approved_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'approved_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_missing_phone_qc',
      title: 'Percent Missing Phone (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.missing_phone_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'missing_phone_qc',
        'total_scans_qc'
      ),
    },

    {
      dataKey: 'percent_reupload_qc',
      title: 'Percent Reupload (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.reupload_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'reupload_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_handwriting_qc',
      title: 'Percent Handwriting (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.handwriting_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'handwriting_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_canvasser_assistance_qc',
      title: 'Percent Canvasser Assistance (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.canvasser_assistance_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'canvasser_assistance_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_other_qc',
      title: 'Percent Other (Visual QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.other_qc,
          rowData.complete_scans_qc + rowData.incomplete_scans_qc
        ),
      summaryFunction: summaryFunctions.percent('other_qc', 'total_scans_qc'),
    },

    {
      dataKey: 'total_phone_qc_days',
      title: 'Total Phone QC Days',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'total_calls',
      title: 'Total Calls',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'calls_per_day',
      title: 'Calls per Day',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_calls, rowData.total_phone_qc_days),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customRatio(data, acc, [
          'total_calls',
          'total_phone_qc_days',
        ]),
    },
    {
      dataKey: 'total_scans_called_qc',
      title: 'Total Forms Called (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contacted_qc',
      title: 'Contacted (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_needs_attention_qc',
      title: 'Phone Needs Attention (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'confirmed_registered_qc',
      title: 'Confirmed Registration on Phone (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'did_not_register_qc',
      title: 'Did Not Register (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_address_qc',
      title: 'Correct Address (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_address_qc',
      title: 'Incorrect Address (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_dob_qc',
      title: 'Correct DOB (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_dob_qc',
      title: 'Incorrect DOB (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'received_feedback_qc',
      title: 'Received Canvasser Feedback (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_home_qc',
      title: 'Not Home (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'disconnected_qc',
      title: 'Disconnected (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'call_back_qc',
      title: 'Call Back (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'language_barrier_qc',
      title: 'Language Barrier (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'refused_qc',
      title: 'Refused (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_number_qc',
      title: 'Wrong Number (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_voicemail_qc',
      title: 'Wrong Voicemail (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'voicemail_matches_applicant_qc',
      title: 'Voicemail Matches Applicant (Phone QC)',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'busy_qc',
      title: 'Busy (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'do_not_call_qc',
      title: 'Do Not Call (Phone QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'percent_contacted_qc',
      title: 'Percent Contacted (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.contacted_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'percent_phone_needs_attention_qc',
      title: 'Percent Phone Needs Attention (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.phone_needs_attention_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_confirmed_registered_qc',
      title: 'Percent Confirmed Registration on Phone (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_did_not_register_qc',
      title: 'Percent Did Not Register (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.did_not_register_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_correct_address_qc',
      title: 'Percent Correct Address (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.correct_address_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_incorrect_address_qc',
      title: 'Percent Incorrect Address (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_address_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_correct_dob_qc',
      title: 'Percent Correct DOB (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.correct_dob_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'percent_incorrect_dob_qc',
      title: 'Percent Incorrect DOB (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.incorrect_dob_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_received_feedback_qc',
      title: 'Percent Received Canvasser Feedback (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.received_feedback_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_not_home_qc',
      title: 'Percent Not Home (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.not_home_qc, rowData.total_scans_called_qc),
      summaryFunction: summaryFunctions.percent(
        'not_home_qc',
        'total_scans_called_qc'
      ),
    },
    {
      dataKey: 'percent_disconnected_qc',
      title: 'Percent Disconnected (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.disconnected_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_call_back_qc',
      title: 'Percent Call Back (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.call_back_qc, rowData.total_scans_called_qc),
      summaryFunction: summaryFunctions.percent(
        'call_back_qc',
        'total_scans_called_qc'
      ),
    },
    {
      dataKey: 'percent_language_barrier_qc',
      title: 'Percent Language Barrier (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.language_barrier_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_refused_qc',
      title: 'Percent Refused (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.refused_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'percent_wrong_number_qc',
      title: 'Percent Wrong Number (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_number_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_wrong_voicemail_qc',
      title: 'Percent Wrong Voicemail (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_voicemail_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_voicemail_matches_applicant_qc',
      title: 'Percent Voicemail Matches Applicant (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.voicemail_matches_applicant_qc,
          rowData.total_scans_called_qc
        ),
      summaryFunction: summaryFunctions.percent(
        'voicemail_matches_applicant_qc',
        'total_scans_called_qc'
      ),
    },
    {
      dataKey: 'percent_busy_qc',
      title: 'Percent Busy (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.busy_qc, rowData.total_scans_called_qc),
      summaryFunction: summaryFunctions.percent(
        'busy_qc',
        'total_scans_called_qc'
      ),
    },
    {
      dataKey: 'percent_do_not_call_qc',
      title: 'Percent Do Not Call (Phone QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.do_not_call_qc, rowData.total_scans_called_qc),
    },
    {
      dataKey: 'avg_length_of_call',
      title: 'Avg length of call (in mins)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => {
        if (!rowData.total_calls) return 0
        return rowData.lengths_of_calls_seconds / rowData.total_calls
      },
      bodyCell: ({ rowData }) => {
        if (!rowData.avg_length_of_call) return '00:00:00'
        return moment
          .utc(rowData.avg_length_of_call * 1000)
          .format('HH:mm:ss.S')
      },
      csvCell: ({ avg_length_of_call }) => {
        if (!avg_length_of_call) return '00:00:00'
        return moment.utc(avg_length_of_call * 1000).format('HH:mm:ss.S')
      },
    },
  ],
}
