import {
  COMPLETED,
  IN_QC,
  READY_FOR_PHONE_VERIFICATION,
  VISUAL_QC_TAB,
  qcPacketFields,
} from 'constants/qualityControl'
import { createContext, useContext, useEffect, useState } from 'react'
import {
  isPacketCompleted,
  isPacketInPhoneVerification,
  isPacketInVizQc,
} from 'utils/qualityControl'
import { useCurrent } from 'contexts/index'
import { advanceShiftStatus } from 'requests/qualityControl'
import { isVisualReviewComplete } from 'qualityControl/contexts/contextUtils'
import { useQualityControl } from '../QualityControlContext'

export const InboxContext = createContext()

export const InboxProvider = ({ children }) => {
  const { currentUser, currentTurfPerformsExternalQC } = useCurrent()

  const {
    activePackets,
    inspectedUser,
    getInspectedUser,
    getPackets,
    receivePackets,
  } = useQualityControl()

  const [lastActiveInboxTab, setLastActiveInboxTab] = useState(VISUAL_QC_TAB)

  const [
    phoneVerificationPacketsEmptyMsg,
    setPhoneVerificationPacketsEmptyMsg,
  ] = useState('You have no packets that need phone verification')

  const [visualReviewPacketsEmptyMsg, setVisualReviewPacketsEmptyMsg] =
    useState('You have no packets that need visual review')

  const completedPackets = activePackets.filter(packet =>
    isPacketCompleted(packet)
  )
  const completedPacketsEmptyMsg = inspectedUser.id
    ? 'This staff member has no completed packets'
    : 'You have no completed packets'

  const completedVisualPackets = activePackets.filter(
    packet => isPacketInPhoneVerification(packet) || isPacketCompleted(packet)
  )

  const inboxUser = inspectedUser.id ? inspectedUser : currentUser

  const pendingPackets = activePackets.filter(
    packet => !isPacketCompleted(packet)
  )

  const isPendingPacketsEmpty = pendingPackets.length === 0

  const phoneVerificationPackets = activePackets.filter(packet =>
    isPacketInPhoneVerification(packet)
  )

  const visualReviewPackets = activePackets.filter(packet =>
    isPacketInVizQc(packet)
  )

  useEffect(() => {
    if (!isPendingPacketsEmpty) {
      setVisualReviewPacketsEmptyMsg('There are no matching packets')
      setPhoneVerificationPacketsEmptyMsg('There are no matching packets')
    } else if (inspectedUser.id) {
      setVisualReviewPacketsEmptyMsg(
        'This staff member has no packets that need visual review'
      )
      setPhoneVerificationPacketsEmptyMsg(
        'This staff member has no packets that need phone verification'
      )
    } else {
      setVisualReviewPacketsEmptyMsg(
        'You have no packets that need visual review'
      )
      setPhoneVerificationPacketsEmptyMsg(
        'You have no packets that need phone verification'
      )
    }
  }, [isPendingPacketsEmpty, inspectedUser.id])

  const advanceReviewedPackets = async staffId => {
    const pendingAdvancements = pendingPackets.reduce((requests, packet) => {
      const { forms, shift } = packet

      if (shift.status === IN_QC && isVisualReviewComplete(forms)) {
        requests.push(() =>
          advanceShiftStatus(shift.id, READY_FOR_PHONE_VERIFICATION)
        )
      }

      if (
        shift.status === READY_FOR_PHONE_VERIFICATION &&
        forms.every(form =>
          form.visual_reviews.some(
            review => review.response.implies_skips_phone_verification
          )
        )
      ) {
        requests.push(() => advanceShiftStatus(shift.id, COMPLETED, null))
      }

      return requests
    }, [])

    if (pendingAdvancements.length) {
      await Promise.all(pendingAdvancements)

      staffId
        ? await getInspectedUser({
            id: staffId,
            currentTurfPerformsExternalQC,
            receivePackets,
          })
        : await getPackets({ fields: qcPacketFields })
    }
  }

  const inboxContextProps = {
    lastActiveInboxTab,
    setLastActiveInboxTab,
    phoneVerificationPacketsEmptyMsg,
    visualReviewPacketsEmptyMsg,
    completedPackets,
    completedPacketsEmptyMsg,
    completedVisualPackets,
    inboxUser,
    isPendingPacketsEmpty,
    phoneVerificationPackets,
    visualReviewPackets,
    advanceReviewedPackets,
  }

  return (
    <InboxContext.Provider value={inboxContextProps}>
      {children}
    </InboxContext.Provider>
  )
}

export const useInbox = () => useContext(InboxContext)
