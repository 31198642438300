import { sortBy } from 'lodash'
import GalleryScan from '../GalleryScan/GalleryScan'
import styles from './GalleryScans.module.scss'

const GalleryScans = ({ cover, forms }) => (
  <div className={styles.scans}>
    <GalleryScan
      url="?scans[]=cover"
      cover={cover}
      removedAt={cover?.file_locator?.metadata?.redacted_at}
      width={450}
    />
    {sortBy(forms, ['scan_number']).map(f => (
      <GalleryScan
        url={`?scans[]=${f.scan_number}`}
        scan={f}
        removedAt={f?.file_locator?.metadata?.redacted_at}
        width={450}
      />
    ))}
  </div>
)

export default GalleryScans
