import { useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  adminSettingsPaths,
  SettingsNavigationRoutesMap as routes,
} from 'layout/routesConfig'
import { useNavigationPermission } from 'layout/NavigationPermissionProvider/NavigationPermissionProvider'
import { PageHeader, Tab } from '@politechdev/blocks-design-system'
import UserRoutes from 'users/UserRoutes/UserRoutes'
import Roles from 'roles/Roles/Roles'
import RoleContextProvider from 'roles/RoleContext/RoleContext'
import TurfRoutes from 'turfs/TurfRoutes/TurfRoutes'
import TurfContextProvider from 'turfs/TurfContext/TurfContext'
import CampaignRoutes from 'campaigns/CampaignRoutes/CampaignRoutes'
import {
  Permission,
  UseNavigationPermission,
} from 'layout/Navigation/Navigation'
import styles from './AdminSettings.module.scss'

type UseHistory = {
  push: (path: string) => void
  location: {
    pathname: string
  }
}

const AdminSettingRoutes = () => {
  const { t } = useTranslation()
  const history = useHistory() as UseHistory
  const path = history.location.pathname
  const pathOptions = Object.values(routes).map(route => route.path)
  const [currentTab, setCurrentTab] = useState<string | number | undefined>(
    pathOptions.find(pathOption => path.includes(pathOption))
  )
  const isRouteDisabled = (permissions: Array<Permission>) => {
    const { hasPermission } = useNavigationPermission({
      blocks: [],
      permissions,
      children: null,
    }) as UseNavigationPermission
    return !hasPermission
  }

  return (
    <Switch>
      <Route path={adminSettingsPaths}>
        <PageHeader collapsed title={t('Admin settings')} />
        <Tab.Container
          className={styles.tabs}
          selectedTabId={currentTab}
          onChange={({ tabId }: { tabId?: string | number }) => {
            if (typeof tabId === 'string') {
              history.push(tabId)
            }
            setCurrentTab(tabId)
          }}
        >
          <Tab
            id={routes.users.path}
            label={t(routes.users.label)}
            disabled={isRouteDisabled(routes.users.permissions)}
          >
            <Route path={routes.users.path} component={UserRoutes} />
          </Tab>
          <Tab
            id={routes.roles.path}
            label={t(routes.roles.label)}
            disabled={isRouteDisabled(routes.roles.permissions)}
          >
            <Route
              path={routes.roles.path}
              render={() => (
                <RoleContextProvider>
                  <Roles />
                </RoleContextProvider>
              )}
            />
          </Tab>
          <Tab
            id={routes.turfs.path}
            label={t(routes.turfs.label)}
            disabled={isRouteDisabled(routes.turfs.permissions)}
          >
            <Route
              path={routes.turfs.path}
              render={routeProps => (
                <TurfContextProvider>
                  <TurfRoutes match={routeProps.match} />
                </TurfContextProvider>
              )}
            />
          </Tab>
          <Tab
            id={routes.campaigns.path}
            label={t(routes.campaigns.label)}
            disabled={isRouteDisabled(routes.campaigns.permissions)}
          >
            <Route path={routes.campaigns.path} component={CampaignRoutes} />
          </Tab>
        </Tab.Container>
      </Route>
    </Switch>
  )
}

export default AdminSettingRoutes
