import { createRef } from 'react'
import classNames from 'classnames/bind'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { StaticList, LoadBar } from 'components'
import { useTranslation } from 'react-i18next'
import FormListItem from './FormListItem'
import IneligibleForms from './IneligibleForms'
import { useDeliveryActions, useDeliveryState } from '../DeliveryContext'
import styles from './FormViewer.module.scss'
import usePacketDetail from './hooks/usePacketDetail'
import { usesScanLookupCodes } from '../utils'

const PacketDetail = () => {
  const { t } = useTranslation()
  const scrollContainer = createRef()
  const {
    eligibleFormIds,
    currentDelivery,
    packetsLoading: formsLoading,
    currentPacket,
  } = useDeliveryState()
  const { id: deliveryId, excluded_forms: excludedForms } = currentDelivery
  const { updateDelivery: updateExcluded } = useDeliveryActions()
  const excludedFormIds = excludedForms.map(f => f.id)
  const {
    allFormsExcluded,
    eligibleForms,
    ineligibleForms,
    excludeForm,
    excludeAllForms,
    includeForm,
    includeAllForms,
  } = usePacketDetail(
    currentPacket,
    eligibleFormIds,
    excludedFormIds,
    updateExcluded,
    deliveryId
  )
  const cx = classNames.bind(styles)

  return (
    <div className="form-viewer__packet-content" ref={scrollContainer}>
      <div className="form-viewer__packet-content__header">
        <div className="form-viewer__packet-content__header__grid">
          <div
            className={cx(
              'form-viewer__packet-content__header__title-date-status-block'
            )}
          >
            <div className="scroll-list__item--primary">
              {currentPacket ? currentPacket.name : t('No packet selected')}
            </div>
          </div>
          {currentPacket && (
            <div className="scroll-list__item--primary uppercase">
              {formsLoading
                ? t('Fetching forms...')
                : `${eligibleForms.length} ${t('eligible forms')}`}
            </div>
          )}
          <ButtonBlock justify="right">
            {allFormsExcluded ? (
              <Button disabled={!currentPacket} onClick={includeAllForms}>
                {t('Include all')}
              </Button>
            ) : (
              <Button.Secondary
                disabled={!currentPacket}
                onClick={excludeAllForms}
              >
                {t('Exclude all')}
              </Button.Secondary>
            )}
          </ButtonBlock>
        </div>
        <LoadBar show={formsLoading} />
      </div>
      <StaticList
        itemData={eligibleForms}
        emptyMessage={t('No forms found')}
        loading={formsLoading}
        render={form => (
          <FormListItem
            key={form.id}
            form={form}
            deliveryId={deliveryId}
            isExcluded={excludedFormIds.includes(form.id)}
            handleExclude={excludeForm}
            handleInclude={includeForm}
          />
        )}
      />
      <IneligibleForms
        forms={ineligibleForms}
        deliveryId={deliveryId}
        requiresLookupCodes={usesScanLookupCodes(currentDelivery)}
      />
    </div>
  )
}

export default PacketDetail
