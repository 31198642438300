import { CardError, List, ListItem } from 'components'
import {
  Section,
  ProgressBar,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { useRequest } from 'hooks'
import {
  getFlagItemReadStatus,
  getFlagItemPrimaryText,
  getFlagItemPrimaryIcon,
  getFlagItemSecondaryText,
  doesFlagHaveUnreadComments,
} from './utils'
import { ListPager } from './ListPager'
import ViewFlagButton from './buttons/ViewFlagButton'
import {
  FLAG_TABS,
  defaultFlagFilters,
  useFlagsFilteredListContext,
} from './contexts/FlagsFilteredListContext'

const FlagsReviewList = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrent()

  const { filters, listPages, fetchGroupFlags, setListPage, setFlagFilters } =
    useFlagsFilteredListContext()
  const currentPage = listPages.group

  useEffect(
    () => () => {
      setListPage(FLAG_TABS.GROUP, 1)
      setFlagFilters(defaultFlagFilters)
    },
    []
  )

  const fetchGroupFlagsRequest = useRequest(fetchGroupFlags)

  const flags = fetchGroupFlagsRequest.response?.['quality_control/flags'] || []
  const meta = fetchGroupFlagsRequest.response?.meta
  const totalCount = meta?.total_count ?? 0
  const per = meta?.per ?? 0
  const totalPages =
    per === 0 || totalCount < per ? 1 : Math.ceil(totalCount / per)

  useEffect(() => {
    fetchGroupFlagsRequest.makeRequest(filters, currentPage)
  }, [currentPage, filters])

  return (
    <>
      <ProgressBar show={fetchGroupFlagsRequest.isLoading} />
      <CardError
        hide={!fetchGroupFlagsRequest.hasErrors}
        message={formatErrorMessage(fetchGroupFlagsRequest.errors)}
      />
      {fetchGroupFlagsRequest.isRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`flag-group-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                primaryIcon={getFlagItemPrimaryIcon(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                read={getFlagItemReadStatus(flag, currentUser.email)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={() => <ViewFlagButton flagId={flag.id} />}
              />
            ))}
          </List>
          <ListPager
            currentPage={currentPage}
            setPage={page => setListPage('group', page)}
            totalPages={totalPages}
          />
        </>
      )}
      {fetchGroupFlagsRequest.isRequestComplete && !flags.length && (
        <Section secondary collapsed>
          <TextBlock>{t('You have no flags to review')}</TextBlock>
        </Section>
      )}
    </>
  )
}

export default FlagsReviewList
