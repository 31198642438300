import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, Calendar } from 'components'
import { useRequest } from 'hooks'
import { fetchEvents, fetchMeetings } from 'requests/events'
import { formatErrorMessage } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import { ContentBlock } from '@politechdev/blocks-design-system'
import EventCalendarItem from '../EventCalendarItem/EventCalendarItem'
import EventCalendarLegend from '../EventCalendarLegend/EventCalendarLegend'
import {
  buildEventParams,
  buildMeetingParams,
  buildEvents,
  buildMeetings,
} from './utils'

const EventCalendar = () => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewEvents = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'view',
  })
  const canViewMeetings = doesCurrentUserHavePermission({
    resource: 'meeting',
    ability: 'view',
  })

  if (!canViewEvents && !canViewMeetings) {
    return (
      <CardError
        hide={canViewEvents}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const [items, setItems] = useState([])
  const [dateRange, setDateRange] = useState(null)

  const fetchEventsRequest = useRequest(fetchEvents)

  const fetchMeetingsRequest = useRequest(fetchMeetings)

  useEffect(() => {
    if (fetchEventsRequest.isLoading || fetchMeetingsRequest.isLoading) return
    if (fetchEventsRequest.hasErrors || fetchMeetingsRequest.hasErrors) return

    setItems([
      ...(fetchEventsRequest.response?.events || []).map(buildEvents),
      ...(fetchMeetingsRequest.response?.meetings || []).map(buildMeetings),
    ])
  }, [
    fetchEventsRequest.isLoading,
    fetchEventsRequest.hasErrors,
    fetchEventsRequest.response,
    fetchMeetingsRequest.isLoading,
    fetchMeetingsRequest.hasErrors,
    fetchMeetingsRequest.response,
  ])

  useEffect(() => {
    if (dateRange) {
      fetchEventsRequest.makeRequest(
        buildEventParams({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      )
      fetchMeetingsRequest.makeRequest(
        buildMeetingParams({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      )
    }
  }, [dateRange])

  const isLoading =
    fetchEventsRequest.isLoading || fetchMeetingsRequest.isLoading

  const hasErrors =
    fetchEventsRequest.hasErrors || fetchMeetingsRequest.hasErrors

  const errorMsg = formatErrorMessage(
    fetchEventsRequest.errors || fetchMeetingsRequest.errors
  )

  return (
    <div>
      <Calendar
        items={items}
        isLoading={isLoading}
        errorMsg={hasErrors && errorMsg}
        onRangeChange={setDateRange}
        renderItem={itemProps => <EventCalendarItem {...itemProps} />}
      />
      <ContentBlock>
        <EventCalendarLegend />
      </ContentBlock>
    </div>
  )
}

export default EventCalendar
