import phoneByDay from './reports/phoneByDay'
import qcPacketsByDay from './reports/qcPacketsByDay'
import qcPhoneCallsByUser from './reports/qcPhoneCallsByUser'
import qcSummaryByRole from './reports/qcSummaryByRole'
import qcSummaryByUser from './reports/qcSummaryByUser'
import vizByDay from './reports/vizByDay'

const reportConfigs = [
  qcSummaryByUser,
  qcSummaryByRole,
  qcPhoneCallsByUser,
  vizByDay,
  phoneByDay,
  qcPacketsByDay,
]

export default {
  reportCategory: 'users',
  categoryTitle: 'User Reports',
  reportConfigs,
}
