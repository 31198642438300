import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  PageHeader,
  Tab,
} from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import EventCalendar from 'events/EventCalendar/EventCalendar'
import EventTable from '../EventTable/EventTable'

const EventSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewEvents = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'view',
  })
  const canModifyEvents = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'modify',
  })

  if (!canViewEvents) {
    return (
      <CardError
        hide={canViewEvents}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Events')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyEvents && e.preventDefault()}
            to={`${location.pathname}/new`}
          >
            <Button.Accent disabled={!canModifyEvents}>
              {t('Add event')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer>
        <Tab.Container>
          <Tab label={t('Events')}>
            <EventTable />
          </Tab>
          <Tab label={t('Calendar')}>
            <EventCalendar />
          </Tab>
        </Tab.Container>
      </ViewContainer>
    </View>
  )
}

export default EventSelect
