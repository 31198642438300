import { useState } from 'react'
import { defaultTo } from 'lodash'
import { useAutoTable } from 'contexts'
import tableConfig from 'qualityControl/SortablePacketColumnConfig'
import {
  getFormattedPacketCreationDate,
  getFormattedPacketShiftStartDate,
  getStatusForTableReadyPackets,
  removeEmptyFilters,
} from 'utils/qualityControl'
import { fetchAssignablePackets } from 'requests/qualityControl'
import { useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'
import CheckboxColumn from './CheckboxColumn'
import AssignmentTableFilters from '../../filters/AssignmentTableFilters'
import AssignmentButtons from '../AssignmentButtons'
import { useAssignableCheckboxes } from '../CheckboxContextProvider'

const AssignablePacketsTable = ({ user, refreshAssignedPackets }) => {
  const { renderTable, refreshPage } = useAutoTable()

  const [assignablePackets, setAssignablePackets] = useState()
  const [totalPacketCount, setTotalPacketCount] = useState(0)

  const columns = [
    <CheckboxColumn key="select-column" />,
    ...tableConfig.columns,
  ]

  const { defaultSortOrder, defaultSortColumn } = tableConfig

  const { setCheckboxData } = useAssignableCheckboxes()

  const getAssignablePackets = useRequest(
    (params = {}) => {
      const extraRules = removeEmptyFilters(params.filterRules)
      return fetchAssignablePackets(
        {
          current_page: params.page,
          per: params.pageSize,
          sort_attr: defaultTo(params.sortColumn, 'id'),
          sort_dir: defaultTo(params.sortOrder, 'asc'),
          filters: {
            rules: [
              ...(extraRules.find(r => r.column === 'assignee')
                ? []
                : [
                    {
                      column: 'assignee',
                      operator: 'is',
                      param: user.id,
                      invert: true,
                    },
                  ]),
              {
                column: 'in_turf_tree',
                param: user.turf.id,
              },
              {
                column: 'qc_external',
                operator: 'is',
                param: user.turf.qc_config.performs_external_qc,
              },
              ...extraRules,
            ],
          },
          fields: [
            'id',
            'name',
            'created_at',
            'forms_count',
            'qc_deadline',
            'status',
            'shift_start',
            { creator: 'time_zone' },
            { assignee: ['name', 'extras'] },
            { turf: ['name', 'phone_verification_language'] },
            { location: ['state', 'timezone'] },
          ],
        },
        { strictNullHandling: true }
      )
    },
    {
      onSuccess: ({ shifts, meta }) => {
        setAssignablePackets(
          shifts.map(shift => ({
            id: shift.id,
            name: shift.name,
            forms_count: shift.forms_count,
            assignee: shift.assignee
              ? shift.assignee.name || shift.assignee.email
              : '',
            assigned_user: shift.assignee,
            shift: shift.shift,
            creator: shift.creator,
            location: shift.location,
            status: getStatusForTableReadyPackets(shift),
            turf: shift.turf,
            qc_office: shift.assignee ? shift.assignee.qc_office || '' : '',
            qc_deadline: shift.qc_deadline,
            language: shift.turf?.phone_verification_language,
            state: shift.location?.state,
            date_uploaded_at: getFormattedPacketCreationDate(shift),
            date_collected_at: getFormattedPacketShiftStartDate(shift),
          }))
        )

        setCheckboxData(shifts)

        setTotalPacketCount(meta.total_count)
      },
    }
  )

  return (
    <>
      <AssignmentButtons
        onAssignComplete={() => {
          refreshPage()
          refreshAssignedPackets()
        }}
        user={user}
      />
      <AssignmentTableFilters />
      {renderTable({
        data: assignablePackets,
        defaultSortOrder,
        defaultSortColumn,
        totalRecords: totalPacketCount,
        columns,
        fetchAction: getAssignablePackets.makeRequest,
        loading: getAssignablePackets.isLoading,
        error: formatErrorMessage(getAssignablePackets.errors),
      })}
    </>
  )
}

export default AssignablePacketsTable
