import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useReactRouter, useRequest } from 'hooks'
import { View, ViewContainer, CardError } from 'components'
import classNames from 'classnames/bind'
import {
  Sheet,
  ProgressBar,
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  Tab,
  Font,
  Section,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { fetchPerson } from 'requests/people'
import { useCurrent } from 'contexts/index'
import PersonDetails from '../PersonDetails/PersonDetails'
import PersonProfile from '../PersonProfile/PersonProfile'
import PersonActivism from '../PersonActivism/PersonActivism'
import PersonHistory from '../PersonHistory/PersonHistory'
import PersonDeleteModal from '../PersonDeleteModal/PersonDeleteModal'
import { personFields } from './utils'
import styles from './PersonSingle.module.scss'

const cx = classNames.bind(styles)

const PersonSingle = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [person, setPerson] = useState({})
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyPerson = doesCurrentUserHavePermission({
    resource: 'person',
    ability: 'modify',
  })

  const {
    makeRequest: personReq,
    hasErrors: personReqErrors,
    isRequestComplete: isPersonRequestComplete,
  } = useRequest(fetchPerson, {
    onSuccess: ({ person }) => setPerson(person),
  })

  useEffect(() => {
    personReq(match.params.id, personFields)
  }, [match.params.id])

  if (!isPersonRequestComplete) return <ProgressBar show />

  return (
    <View>
      <CardError
        hide={!personReqErrors}
        message={t(
          "An internal error occurred while fetching this person's data"
        )}
      />
      {person ? (
        <ViewContainer>
          <Sheet>
            <PageHeader
              title={[person.prefix, person.name, person.suffix_name].join(' ')}
              subtitle={!!person.pronouns && person.pronouns}
              subtitle2={!!person.position && person.position}
              className={cx('header', { 'header--deceased': person.deceased })}
            >
              <ButtonBlock justify="right">
                <Link
                  onClick={e => !canModifyPerson && e.preventDefault()}
                  to={`${match.url}/edit`}
                >
                  <Button disabled={!canModifyPerson} alt={t('Edit')}>
                    <Icon.Pencil />
                  </Button>
                </Link>
                <PersonDeleteModal person={person} />
              </ButtonBlock>
            </PageHeader>
            {person.deceased && (
              <Section collapsed className={cx('banner')}>
                <Font.Copy variant="medium" className={cx('banner__text')}>
                  Deceased
                </Font.Copy>
              </Section>
            )}
            <PersonDetails person={person} />
          </Sheet>
          <Sheet className="sheet--stacked">
            <Tab.Container>
              <Tab label={t('Profile')}>
                <PersonProfile />
              </Tab>
              <Tab label={t('Activism')}>
                <PersonActivism personId={match.params.id} />
              </Tab>
              <Tab label={t('History')}>
                <PersonHistory personId={match.params.id} />
              </Tab>
            </Tab.Container>
          </Sheet>
        </ViewContainer>
      ) : (
        <ProgressBar show />
      )}
    </View>
  )
}

export default PersonSingle
