import { useEffect, useRef, useState } from 'react'
import { useRequest } from 'hooks'
import { formatErrorMessage } from 'utils/formatting'

const INTERVAL_LENGTH = 5000

const useBackgroundRequest = ({
  key,
  requestId,
  requestData,
  attemptLimit = 13,
}) => {
  const getIdRequest = useRequest(requestId)
  const getDataRequest = useRequest(requestData)
  const [tick, setTick] = useState(0)

  const tracking = useRef({
    attempts: 0,
    interval: undefined,
  })

  const makeRequest = () => {
    setTick(0)
    clearInterval(tracking.current.interval)
    getIdRequest.clearRequest()
    getDataRequest.clearRequest()
    tracking.current.attempts = 0
    tracking.current.interval = setInterval(
      () => setTick(time => time + INTERVAL_LENGTH),
      INTERVAL_LENGTH
    )
    getIdRequest.makeRequest(key)
  }

  useEffect(
    () => () => {
      setTick(0)
      clearInterval(tracking.current.interval)
      getIdRequest.clearRequest()
      getDataRequest.clearRequest()
      tracking.current.attempts = 0
      tracking.current.interval = null
    },
    [key]
  )

  if (tracking.current.attempts > attemptLimit) {
    clearInterval(tracking.current.interval)
    return {
      makeRequest,
      errorMsg: 'Request timed out.',
      isLoading: false,
      isSuccessful: false,
    }
  }

  if (getIdRequest.hasErrors) {
    clearInterval(tracking.current.interval)
    return {
      makeRequest,
      errorMsg: formatErrorMessage(getIdRequest.errors),
      isLoading: false,
      isSuccessful: false,
    }
  }

  if (getDataRequest.hasErrors) {
    clearInterval(tracking.current.interval)
    return {
      makeRequest,
      errorMsg: formatErrorMessage(getDataRequest.errors),
      isLoading: false,
      isSuccessful: false,
    }
  }

  if (getIdRequest.isRequestComplete) {
    if (tick - tracking.current.attempts * INTERVAL_LENGTH >= INTERVAL_LENGTH) {
      if (!getDataRequest.response?.id) {
        tracking.current.attempts += 1
        getDataRequest.makeRequest(getIdRequest.response)
        return {
          makeRequest,
          isLoading: true,
          isSuccessful: false,
        }
      }
      clearInterval(tracking.current.interval)
      return {
        makeRequest,
        response: getDataRequest.response.response,
        isLoading: false,
        isSuccessful: true,
      }
    }
  }

  return {
    makeRequest,
    isLoading: true,
    isSuccessful: false,
  }
}

export default useBackgroundRequest
