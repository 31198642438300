import React from 'react'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { getPacketDaysLeft } from 'utils/qualityControl'
import { correctInQC } from 'shifts/utils'
import humanizeString from 'humanize-string'

export const PacketAssigneeNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.assignee}</TableGridCell>
)

export const PacketQcStatusCell = ({ rowData }) => (
  <TableGridCell>{humanizeString(correctInQC(rowData.status))}</TableGridCell>
)

export const PacketLanguageCell = ({ rowData }) => (
  <TableGridCell>{rowData.turf.phone_verification_language}</TableGridCell>
)

export const PacketLocationStateCell = ({ rowData }) => (
  <TableGridCell>{rowData.location.state}</TableGridCell>
)

export const PacketDaysLeftCell = ({ rowData }) => (
  <TableGridCell>{getPacketDaysLeft(rowData)}</TableGridCell>
)

export const PacketTurfNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.turf.name}</TableGridCell>
)

export const PacketDateUploadedCell = ({ rowData }) => (
  <TableGridCell>{rowData.date_uploaded_at}</TableGridCell>
)

export const PacketDateCollectedCell = ({ rowData }) => (
  <TableGridCell>{rowData.date_collected_at}</TableGridCell>
)

export const PacketQcOfficeCell = ({ rowData }) => (
  <TableGridCell>{rowData.qc_office}</TableGridCell>
)
