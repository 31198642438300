import { useTranslation } from 'react-i18next'
import { useDeliveryState } from '../DeliveryContext'
import styles from './DeliverySummary.module.scss'

const DeliverySummary = () => {
  const { t } = useTranslation()

  const {
    selectedFormIds,
    eligibleFormIds,
    filteredPackets,
    currentDelivery: { excluded_forms },
  } = useDeliveryState()

  const eligiblePacketCount = filteredPackets.length

  const includedFormsCount = selectedFormIds.length
  const eligibleFormsCount = eligibleFormIds.length

  const excludedFormsCount = excluded_forms.filter(form =>
    eligibleFormIds.includes(form.id)
  ).length

  const allFormsCount = filteredPackets.reduce(
    (a, packet) => a + packet.forms.length,
    0
  )

  const ineligibleFormsCount = Math.max(allFormsCount - eligibleFormsCount, 0)

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.value}>
          {eligiblePacketCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Packets')}</div>
      </div>
      <div>
        <div className={styles.value}>
          {includedFormsCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Included Forms')}</div>
      </div>
      <div>
        <div className={styles.value}>
          {excludedFormsCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Excluded Forms')}</div>
      </div>
      <div>
        <div className={styles.value}>
          {ineligibleFormsCount.toLocaleString()}
        </div>
        <div className={styles.label}>{t('Forms not eligible')}</div>
      </div>
    </div>
  )
}

export default DeliverySummary
