import React, { useCallback, useEffect, useState } from 'react'
import { View, ViewContainer, Paginator, Sticky } from 'components'
import {
  Button,
  ButtonBlock,
  Font,
  TextBlock,
  SelectField,
  useToast,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import useLocalForm from 'hooks/useLocalForm'
import { useRequest } from 'hooks/useRequest'
import { fetchShift } from 'requests/shifts'
import { fetchForm, putForm } from 'requests/registrationForms'
import CancelDataEntryModal from './CancelDataEntryModal'
import IneligibleForEntry from './IneligibleForEntry'
import {
  scrollToTop,
  generateFormConfig,
  checkSectionsNotEmpty,
  checkDoesVDRNeedAttention,
  checkHasRequiredFields,
  checkHasMissingFields,
  withCopiedFields,
  buildFormDataFromResponse,
  formatFormForUpdate,
} from './utils'
import FormRenderer from './FormRenderer'
import PotentialIssuesModal from './components/PotentialIssuesModal'
import styles from './ShiftDataEntry.module.scss'

const DataEntryEdit = () => {
  const { t } = useTranslation()
  const { shiftId: shiftIdString, formId: formIdString } = useParams()
  const history = useHistory()
  const { setToast } = useToast()

  const [formConfig, setFormConfig] = useState([])
  const [isNoFormConfig, setIsNoFormConfig] = useState(false)
  const [shift, setShift] = useState()

  const [formIds, setFormIds] = useState([])
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  const [isIssuesModalOpen, setIsIssuesModalOpen] = useState(false)
  const [potentialIssues, setPotentialIssues] = useState(null)

  const [hasChanges, setHasChanges] = useState(false)

  const formId = parseInt(formIdString)
  const shiftId = parseInt(shiftIdString)

  const localForm = useLocalForm({})
  const {
    formData,
    setFormData,
    areFieldsValid,
    setField: originalSetField,
    getField,
    mergeWithFieldErrors,
  } = localForm

  const setField = (...args) => {
    originalSetField(...args)
    setHasChanges(true)
  }

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: fetchShiftReq,
    isRequestComplete: isShiftRequestComplete,
  } = useRequest(fetchShift, {
    onSuccess: async ({ shift }) => {
      const loadedConfig = generateFormConfig(
        shift.turf.voter_registration_config.data_entry_sections
      )
      if (!loadedConfig.length) {
        setIsNoFormConfig(true)
      }
      setFormConfig(loadedConfig)

      setFormIds(shift.forms.map(({ id }) => id).sort())

      setShift(shift)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: getFormRequest,
    isLoading: isFormDataLoading,
    isRequestComplete: isFormRequestComplete,
  } = useRequest(fetchForm, {
    onSuccess: async response => {
      const config = generateFormConfig(
        response.registration_form.turf.voter_registration_config
          .data_entry_sections
      )

      setFormData(buildFormDataFromResponse(response.registration_form, config))

      setSubmitAttempted(false)
    },
  })

  useEffect(() => {
    fetchShiftReq(shiftId, {
      fields: [
        'status',
        { turf: ['voter_registration_config'] },
        { canvasser: ['id', 'full_name', 'vdrs'] },
        {
          forms: ['id'],
        },
      ],
    })
  }, [shiftId])

  useEffect(() => {
    getFormRequest(formId, {
      fields: [
        'hard_copy_collected',
        'registration_type',
        'us_citizen',
        'date_of_birth',
        'state_id',
        'social_security',
        'last_name',
        'first_name',
        'middle_name',
        'name_suffix',
        'voting_street_address_one',
        'voting_street_address_two',
        'voting_city',
        'voting_state',
        'voting_zipcode',
        'county',
        'mailing_street_address_one',
        'mailing_street_address_two',
        'mailing_city',
        'mailing_state',
        'mailing_zipcode',
        'gender',
        'phone_number',
        'ethnicity',
        'registration_date',
        'signature',
        'email_address',
        'eligible_voting_age',
        'extras',
        'pledge_card_metadata',
        'pledge_card_url',
        'redacted_fields',
        'file_url',
        { turf: ['voter_registration_config'] },
      ],
    })
  }, [formId])

  const goToPage = formId => {
    setFormData(null)
    scrollToTop()
    history.push(
      `/collect/voter_registration/shifts/${shiftId}/edit_form_data/${formId}`
    )
  }

  // eslint-disable-next-line blocks/missing-response-error
  const updateForm = useRequest(putForm, {
    onSuccess: () => {
      setToast({ message: t('Updated successfully'), variant: 'success' })
      setHasChanges(false)
    },
  })

  const handleSaveForm = async () => {
    const submittableFormData = formatFormForUpdate(
      formConfig,
      withCopiedFields(formData)
    )
    updateForm.makeRequest(formId, submittableFormData)
  }

  const validateAndContinue = async () => {
    setSubmitAttempted(true)
    if (!areFieldsValid) {
      return
    }

    const formDataWithCopiedFields = withCopiedFields(formData)

    const sectionEmptyCheck = checkSectionsNotEmpty(
      formConfig,
      formDataWithCopiedFields
    )
    sectionEmptyCheck.forEach(([record, hasData]) => {
      originalSetField(!hasData, `meta.${record}.isEmpty`)
    })

    if (sectionEmptyCheck.some(([, hasData]) => !hasData)) {
      return
    }

    const { hasRequiredFields, fieldErrors } = checkHasRequiredFields(
      formConfig,
      formDataWithCopiedFields
    )

    if (!hasRequiredFields) {
      mergeWithFieldErrors(fieldErrors)
      return
    }

    const { hasMissingFields, missingFieldsForDisplay } = checkHasMissingFields(
      formConfig,
      formDataWithCopiedFields
    )

    const { hasIssues: hasVDRIssues, issues: vdrIssues } =
      checkDoesVDRNeedAttention(formConfig, formDataWithCopiedFields, shift)

    if (hasMissingFields || hasVDRIssues) {
      setPotentialIssues({
        vdrIssues,
        missingFieldsForDisplay,
        hasMissingFields,
        hasVDRIssues,
      })
      setIsIssuesModalOpen(true)
      return
    }

    handleSaveForm()
  }

  const handleLeaveDataEntry = async () => {
    history.push(`/collect/voter_registration/shifts`)
  }

  const isChecksLoading = !isShiftRequestComplete

  const PaginatorDisplay = useCallback(
    () => (
      <TextBlock className={styles.paginator__container}>
        <span>
          <Trans>Page</Trans>
        </span>
        <SelectField
          className={styles.paginator__select}
          options={formIds.map((page, index) => ({
            value: page,
            label: index + 1,
          }))}
          value={formId}
          onSelect={goToPage}
        />
        <span>
          <Trans>of</Trans> {formIds.length}
        </span>
      </TextBlock>
    ),
    [formIds, formId]
  )

  const pageCount = formIds.length
  const currentPageIndex = formIds.findIndex(id => id === formId)
  const formDoesNotExist = currentPageIndex === -1

  if (!isChecksLoading && (isNoFormConfig || formDoesNotExist)) {
    return (
      <IneligibleForEntry
        isFormNotConfigured={isNoFormConfig}
        formDoesNotExist={formDoesNotExist}
      />
    )
  }

  return (
    <View>
      <PageHeader title={t('Data entry')} />
      <Sticky className={styles.sticky}>
        <div className={styles.sticky__actions}>
          <Paginator
            currentPage={currentPageIndex + 1}
            totalPages={pageCount}
            onNext={() => goToPage(formIds[currentPageIndex + 1])}
            onPrevious={() => goToPage(formIds[currentPageIndex - 1])}
            CustomDisplay={PaginatorDisplay}
          />
          {!areFieldsValid && submitAttempted && (
            <div className={styles['button-hint']}>
              <Font.Copy
                Element="p"
                variant="hint"
                className={styles['error-text']}
              >
                {t('Please check for invalid fields.')}
              </Font.Copy>
            </div>
          )}
          <ButtonBlock justify="right">
            <Button.Accent
              onClick={validateAndContinue}
              disabled={!hasChanges || (!areFieldsValid && submitAttempted)}
            >
              {t('Save changes')}
            </Button.Accent>
            <Button.Secondary onClick={() => setIsCancelModalOpen(true)}>
              {t('Cancel')}
            </Button.Secondary>
          </ButtonBlock>
        </div>
        {hasChanges && (
          <div className={styles['data-edit__banner']}>
            <Font.Copy variant="highlight" Element="p" className={styles.text}>
              {t('This form has unsaved changes.')}
            </Font.Copy>
          </div>
        )}
      </Sticky>

      <ViewContainer
        loading={isChecksLoading || isFormDataLoading || updateForm.isLoading}
      >
        {shift && isFormRequestComplete ? (
          <FormRenderer
            formConfig={formConfig}
            localForm={localForm}
            submitAttempted={submitAttempted}
            setField={setField}
            isEditing
          />
        ) : null}
      </ViewContainer>
      <CancelDataEntryModal
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        onConfirm={handleLeaveDataEntry}
      />
      {shift ? (
        <PotentialIssuesModal
          isOpen={isIssuesModalOpen}
          setIsOpen={setIsIssuesModalOpen}
          canvasser={shift.canvasser}
          county={getField('county')}
          potentialIssues={potentialIssues}
          onConfirm={options => {
            handleSaveForm(formId + 1, options)
          }}
        />
      ) : null}
    </View>
  )
}

export default DataEntryEdit
