import { createRequestFilters } from 'qualityControl/flags/contexts/utils'
import { getQcFlagFilter } from 'utils/flags'
import { fetchJSON, stringifyParams } from 'utils/req'

export const defaultFlagParams = {
  fields: [
    'id',
    'status',
    'action_plan',
    'created_at',
    {
      viewed_by_users: ['email'],
    },
    {
      comments: [
        'id',
        'body',
        'created_at',
        { viewed_by_users: ['email'] },
        { author: ['id', 'name', 'email'] },
      ],
    },
    {
      trigger: [
        'id',
        'name',
        'implies_canvasser_issue',
        'needs_reupload',
        'resource_type',
      ],
    },
    { canvasser: ['id', 'full_name'] },
    { turf: ['id', 'name'] },
    {
      triggered_by_forms: [
        'id',
        'scan_number',
        'qc_history',
        { shift: ['id', 'name', 'forms_count'] },
      ],
    },
    {
      shift: ['id', 'status', 'name', 'forms_count'],
    },
  ],
}

export const fetchFlags = params =>
  fetchJSON(
    `/api/v1/quality_control/flags?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchFlag = (id, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const updateFlag = (id, data, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${id}?${stringifyParams(params)}`,
    'PUT',
    data,
    {
      useJwt: true,
    }
  )

export const deleteFlag = id => {
  const path = `/api/v1/quality_control/flags/${id}`
  return fetchJSON(path, 'DELETE', null, {
    useJwt: true,
  })
}

export const advanceFlagStatus = async (flagId, flagAbility) => {
  switch (flagAbility) {
    case 'start':
    case 'review':
    case 'complete':
    case 'reopen': {
      await fetchJSON(
        `/api/v1/quality_control/flags/${flagId}/${flagAbility}`,
        'PUT',
        null,
        {
          useJwt: true,
        }
      )
      break
    }

    default: {
      throw new Error('Invalid permissions for flag advancement')
    }
  }

  return flagAbility
}

export const getNextReviewableFlagId = async (
  filters,
  currentFlagId,
  type,
  currentTurfPerformsExternalQC
) => {
  const preparedFilters = createRequestFilters(filters)

  if (['review', 'complete'].includes(type)) {
    preparedFilters.push(
      getQcFlagFilter({
        only360: currentTurfPerformsExternalQC,
      })
    )
  } else {
    preparedFilters.push(
      getQcFlagFilter({
        only360: currentTurfPerformsExternalQC,
        exclude360: !currentTurfPerformsExternalQC,
      })
    )
  }

  const typeMap = {
    start: 'pending',
    review: 'ready',
    complete: 'reviewed',
    reopen: 'completed',
  }

  const param = typeMap[type]

  if (!param) throw new Error('Invalid type for fetching flags')

  const response = await fetchFlags({
    fields: ['id'],
    filters: {
      rules: [
        {
          column: 'status',
          operator: 'is',
          param,
        },
        ...preparedFilters,
      ],
    },
    per: 2,
  })

  const flags = response['quality_control/flags'] || []

  const nextFlag = flags.find(flag => flag.id !== currentFlagId)

  return nextFlag ? nextFlag.id : null
}

const requestFlagsWithUnreadCommentsByStatus = (
  status,
  currentTurfPerformsExternalQC
) => {
  const filter_rules = [
    {
      column: 'status',
      operator: 'is',
      param: status,
    },
    getQcFlagFilter({
      only360: currentTurfPerformsExternalQC,
    }),
  ]

  status === 'ready' &&
    filter_rules.push({
      column: 'trigger_needs_reupload',
      operator: 'is',
      param: true,
    })

  return fetchFlags({
    fields: [{ comments: [{ viewed_by_users: ['email'] }] }],
    filters: { rules: filter_rules },
    per: 1,
  })
}

export const fetchUnreadStatusForFlagGroups = async (
  userEmail,
  currentTurfPerformsExternalQC
) => {
  const statuses = ['pending', 'ready', 'reviewed', 'completed']
  const getRequest = async status =>
    requestFlagsWithUnreadCommentsByStatus(
      status,
      currentTurfPerformsExternalQC
    )
  const [tableReview, awaitingGroupQc, awaitingResolution, resolved] =
    await Promise.all(statuses.map(getRequest))

  const filterFlagsWithUnreadComments = flags =>
    flags.filter(
      flag =>
        flag.comments.filter(
          comment =>
            !comment.viewed_by_users.some(user => user.email === userEmail)
        ).length > 0
    )

  return {
    table:
      filterFlagsWithUnreadComments(tableReview['quality_control/flags'])
        .length > 0,
    group:
      filterFlagsWithUnreadComments(awaitingGroupQc['quality_control/flags'])
        .length > 0,
    resolution:
      filterFlagsWithUnreadComments(awaitingResolution['quality_control/flags'])
        .length > 0,
    resolved:
      filterFlagsWithUnreadComments(resolved['quality_control/flags']).length >
      0,
  }
}

export const createFlagComment = (flagId, commentBody, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments?${stringifyParams(
      params
    )}`,
    'POST',
    { comment: { body: commentBody } },
    {
      useJwt: true,
    }
  )

export const updateFlagComment = (
  flagId,
  commentId,
  commentBody,
  params = {}
) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments/${commentId}?${stringifyParams(
      params
    )}`,
    'PUT',
    { comment: { body: commentBody } },
    {
      useJwt: true,
    }
  )

export const deleteFlagComment = (flagId, commentId) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments/${commentId}`,
    'DELETE',
    null,
    {
      useJwt: true,
    }
  ).then(() => commentId)

export const fetchFlagTriggers = queryParams => {
  const url = queryParams
    ? `/api/v1/quality_control/flag_triggers?${stringifyParams(queryParams)}`
    : `/api/v1/quality_control/flag_triggers`
  return fetchJSON(url, 'GET', null, {
    useJwt: true,
  })
}

export const fetchFlagComments = (flagId, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments/?${stringifyParams(
      params
    )}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchFlagTriggersWithCounts = async params => {
  const triggerParams = {
    fields: [
      'id',
      'name',
      'resource_type',
      'default_action_plan',
      'needs_reupload',
      'implies_canvasser_issue',
    ],
    per: Number.MAX_SAFE_INTEGER,
  }

  const flagTriggersRequest = fetchFlagTriggers(triggerParams)

  const flagsRequest = fetchFlags(params)
  const [flagTriggersResponse, flagsResponse] = await Promise.all([
    flagTriggersRequest,
    flagsRequest,
  ])

  const triggers = flagTriggersResponse['quality_control/flag_triggers']

  const countsByTrigger = flagsResponse.meta.counts_by_trigger.reduce(
    (countMap, { count, trigger_id }) => {
      countMap[trigger_id] = count
      return countMap
    },
    {}
  )

  const triggersWithCounts = triggers.map(trigger => ({
    ...trigger,
    count: countsByTrigger[trigger.id] ?? 0,
  }))

  return triggersWithCounts
}
