import { TableGridCell } from 'components/DataTable/TableGrid/Cells'
import { FormattedData } from 'components'

export const ReviewPacketNameCell = ({ rowData }) => (
  <TableGridCell>{rowData.shift.name}</TableGridCell>
)

export const ReviewRegistrantCell = ({ rowData }) => (
  <TableGridCell>{rowData.form?.display_name}</TableGridCell>
)

export const ReviewCollectedCell = ({ rowData }) => (
  <TableGridCell>
    <FormattedData value={rowData.shift.shift_start} type="date" />
  </TableGridCell>
)

export const ReviewUploadedCell = ({ rowData }) => (
  <TableGridCell>
    <FormattedData value={rowData.created_at} type="date" />
  </TableGridCell>
)
