import { FormWithShiftInfo } from './types'

export const ScanCsvCell = ({ rowData }: { rowData: FormWithShiftInfo }) =>
  rowData.scan_number

export const PacketCsvCell = ({ rowData }: { rowData: FormWithShiftInfo }) =>
  rowData.shift.name

export const RegistrantCsvCell = ({
  rowData,
}: {
  rowData: FormWithShiftInfo
}) => rowData.display_name

export const CountyCsvCell = ({ rowData }: { rowData: FormWithShiftInfo }) =>
  rowData.county

export const CollectedCsvCell = ({ rowData }: { rowData: FormWithShiftInfo }) =>
  rowData.shift.shift_start

export const UploadedCsvCell = ({ rowData }: { rowData: FormWithShiftInfo }) =>
  rowData.created_at
