import {
  Button,
  ButtonBlock,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import { CountySelectField } from 'components'
import { useTranslation } from 'react-i18next'
import { FormWithShiftInfo, UpdateFormRequest } from './types'
import { isEligibleScanForDelivery } from '../utils'
import { useDeliveryState } from '../DeliveryContext'

export const WrongCountyAction = ({
  form,
  handleUpdateForm,
  assemble,
}: {
  form: FormWithShiftInfo
  handleUpdateForm: UpdateFormRequest
  assemble: () => Promise<void>
}) => {
  const { currentDelivery } = useDeliveryState()

  const handleUpdate = async (county: string) => {
    const response = await handleUpdateForm(form.id, { county })
    if (response?.registration_form) {
      if (
        isEligibleScanForDelivery(currentDelivery)(response.registration_form)
      ) {
        void assemble()
      }
    }
  }

  return (
    <FieldBlock>
      <CountySelectField
        onSelect={handleUpdate}
        state={form.shift.location.state}
        county={form.county}
      />
    </FieldBlock>
  )
}

export const AssembleAnywayAction = ({
  assemble,
}: {
  assemble: (openModal: boolean) => Promise<void>
}) => {
  const { t } = useTranslation()

  const handleAssemble = () => {
    void assemble(false)
  }

  return (
    <ButtonBlock justify="right">
      <Button onClick={handleAssemble}>{t('Assemble anyway')}</Button>
    </ButtonBlock>
  )
}
