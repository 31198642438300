import canvasserQuality from './reports/canvasserQuality'
import canvasserQualityParty from './reports/canvasserQualityParty'
import canvasserQualityTablet from './reports/canvasserQualityTablet'
import districtByCanvasser from './reports/districtByCanvasser'
import partisanScoreByCanvasser from './reports/partisanScoreByCanvasser'
import partyByCanvasser from './reports/partyByCanvasser'
import partyLeanByCanvasser from './reports/partyLeanByCanvasser'
import qcByCanvasser from './reports/qcByCanvasser'

const reportConfigs = [
  canvasserQuality,
  canvasserQualityTablet,
  canvasserQualityParty,
  qcByCanvasser,
  partyByCanvasser,
  partyLeanByCanvasser,
  districtByCanvasser,
  partisanScoreByCanvasser,
]

export default {
  reportCategory: 'canvassers',
  categoryTitle: 'Canvasser Reports',
  reportConfigs,
}
