import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Step, TextField, SearchSelect } from 'components'
import { FieldBlock } from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchListFolders } from 'requests/lists'
import { useDebounce } from 'use-debounce'
import { sortListFolders } from 'utils/lists'

const ListCreateForm = ({ ...props }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { setField, formData } = useForm()
  const [folderOptionSearch, setFolderOptionsSearch] = useState(undefined)
  const [debouncedFolderOptionsSearch] = useDebounce(folderOptionSearch, 500)
  const [folderOptions, setFolderOptions] = useState([])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeFolderRequest, isLoading } = useRequest(
    fetchListFolders,
    {
      onSuccess: ({ list_folders }) => {
        list_folders.length &&
          setFolderOptions(
            list_folders
              .sort(sortListFolders)
              .map(folder => ({ value: folder.id, label: folder.name }))
          )
      },
    }
  )

  useEffect(() => {
    makeFolderRequest({
      fields: ['id', 'name'],
      filters: debouncedFolderOptionsSearch
        ? {
            rules: [
              {
                column: 'name',
                operator: 'containing',
                param: debouncedFolderOptionsSearch,
              },
            ],
          }
        : undefined,
    })
  }, [debouncedFolderOptionsSearch])

  const cancelCreate = () => {
    history.push(`/organize/lists`)
  }

  return (
    <Step
      label={t('Basic information')}
      onPrevious={cancelCreate}
      disableNext={!formData.name}
      {...props}
    >
      <FieldBlock>
        <TextField
          id="name"
          label={t('List name')}
          value={formData.name || ''}
          onChange={val => setField(val, 'name')}
          required
        />
      </FieldBlock>
      {folderOptions.length > 0 && (
        <FieldBlock>
          <SearchSelect
            id="folder"
            label={t('List folder')}
            loading={isLoading}
            options={folderOptions}
            value={formData.folder || ''}
            onChange={val => setField(val, 'folder')}
            onInputChange={val => setFolderOptionsSearch(val)}
          />
        </FieldBlock>
      )}
    </Step>
  )
}

export default ListCreateForm
