import { Section, DetailItem, FormattedData } from 'components'
import { useTranslation } from 'react-i18next'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'

const PacketDetails = () => {
  const [shift] = useCurrentPacket()
  const { t } = useTranslation()

  return (
    <Section secondary>
      <DetailItem label={t('Canvasser')}>
        <p>{`${shift.canvasser.first_name} ${shift.canvasser.last_name}`}</p>
      </DetailItem>
      <DetailItem label={t('Shift Date')}>
        <FormattedData type="date" value={shift.shift_start} />
      </DetailItem>
      <DetailItem label={t('Location')}>
        <p>{shift.location.name}</p>
      </DetailItem>
      <DetailItem label={t('Location Category')}>
        <p>{shift.location.category}</p>
      </DetailItem>
      <DetailItem label={t('Turf')} hide={!shift.turf}>
        <p>{shift.turf.name}</p>
      </DetailItem>
      <DetailItem label={t('Shift notes')} hide={!shift.notes?.length}>
        <p>{shift.notes}</p>
      </DetailItem>
    </Section>
  )
}

export default PacketDetails
