import {
  EventNameCell,
  EventTypeCell,
  EventDateCell,
} from 'events/EventTable/EventTableCells'

export default {
  label: 'team-events',
  defaultSortOrder: 'desc',
  defaultSortColumn: 'start_time',
  columns: [
    {
      dataKey: 'name',
      title: 'Event name',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: true,
      bodyCell: EventNameCell,
    },
    {
      dataKey: 'start_time',
      title: 'Date',
      type: 'date',
      autoResize: true,
      resizable: true,
      sortable: true,
      bodyCell: EventDateCell,
    },
    {
      dataKey: 'type',
      title: 'Type',
      type: 'string',
      autoResize: true,
      resizable: true,
      sortable: true,
      bodyCell: EventTypeCell,
    },
  ],
}
