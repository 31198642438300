import dailyQc from './reports/dailyQc'
import packet from './reports/packet'
import qcByResponse from './reports/qcByResponse'
import qcByVisualResponse from './reports/qcByVisualResponse'
import qcFlagsByDate from './reports/qcFlagsByDate'
import qcFlagStatus from './reports/qcFlagStatus'
import qcNotesByScan from './reports/qcNotesByScan'
import qcPhoneCallsStatus from './reports/qcPhoneCallsStatus'
import qcScansByDay from './reports/qcScansByDay'

const reportConfigs = [
  packet,
  qcPhoneCallsStatus,
  qcFlagStatus,
  qcByVisualResponse,
  qcFlagsByDate,
  qcScansByDay,
  qcNotesByScan,
  qcByResponse,
  dailyQc,
]

export default {
  reportCategory: 'resources',
  categoryTitle: 'Resources',
  reportConfigs,
}
