import duplicateApplicantsByCanvasser from './reports/duplicateApplicantsByCanvasser'
import validatedFormsByCanvasser from './reports/validatedFormsByCanvasser'
import validatedFormsByTurf from './reports/validatedFormsByTurf'

const reportConfigs = [
  validatedFormsByTurf,
  validatedFormsByCanvasser,
  duplicateApplicantsByCanvasser,
]

export default {
  reportCategory: 'verification',
  categoryTitle: 'Verification Reports',
  reportConfigs,
}
