import classNames from 'classnames/bind'
import { sortBy } from 'lodash'
import { Font, Icon } from '@politechdev/blocks-design-system'
import { useContext, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TurfSelectField } from 'components'
import { TurfContext } from 'turfs/TurfContext/TurfContext'
import styles from './TurfsStickySection.module.scss'

const cx = classNames.bind(styles)

const TurfsStickySection = ({ children, observerRef }) => {
  const { t } = useTranslation()

  const [scrolledTurfs, setScrolledTurfs] = useState([])

  const { getTurf, hasChildren } = useContext(TurfContext)
  const { turfs } = useContext(TurfContext)

  const turfOptions = turfs.map(turf => ({
    ...turf,
    value: turf.id,
    label: turf.name,
  }))

  const handleIntersection = entries => {
    entries.forEach(entry => {
      const observerBounds = entry.rootBounds
      const targetBounds = entry.boundingClientRect

      const isNotHidden = !!observerBounds.top
      let isAboveViewport = false
      if (isNotHidden && observerBounds.top >= targetBounds.top) {
        isAboveViewport = true
      }

      if (isAboveViewport) {
        const targetId = entry.target.getAttribute('data-turf-id')
        const targetLft = entry.target.getAttribute('data-turf-lft')
        if (entry.isIntersecting) {
          setScrolledTurfs(current =>
            current.filter(({ lft }) => lft < +targetLft)
          )
        } else {
          setScrolledTurfs(current =>
            sortBy(
              [
                ...current,
                {
                  id: +targetId,
                  lft: +targetLft,
                },
              ],
              'lft'
            )
          )
        }
      }
    })
  }

  useLayoutEffect(() => {
    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: document,
      rootMargin: '-240px 0px 0px 0px',
      threshold: [0.1, 0.9],
    })
  }, [])

  const scrollTurfIntoFocus = turfId => {
    const node = document.querySelector(`[data-turf-id="${turfId}"]`)
    node.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setTimeout(() => node.focus({ preventScroll: true }), 350)
  }

  const getTurfHierarchy = turf => {
    const turfs = []

    let currentTurf = getTurf(turf.id)
    let parentId = currentTurf.parent_id

    if (hasChildren(turf.id)) {
      turfs.push(currentTurf)
    }

    while (parentId) {
      currentTurf = getTurf(parentId)
      turfs.push(currentTurf)
      parentId = currentTurf.parent_id
    }

    return turfs.reverse()
  }

  const displayableTurfs = scrolledTurfs.at(-1)
    ? getTurfHierarchy(scrolledTurfs.at(-1))
    : []

  const isActive = displayableTurfs.length

  return (
    <div
      className={cx('sticky--section__container', {
        'sticky--section__container--active': isActive,
      })}
    >
      <div className={cx('sticky--section__actions')}>
        <TurfSelectField
          label={t('Search')}
          value={null}
          onSelect={scrollTurfIntoFocus}
          turfs={turfOptions}
          clearable
        />
        {children}
      </div>
      <div
        className={cx('breadcrumb__container', {
          'breadcrumb__container--active': isActive,
        })}
      >
        {scrolledTurfs.at(-1) &&
          getTurfHierarchy(scrolledTurfs.at(-1)).flatMap(
            ({ name, id }, index) => [
              index === 0 ? null : (
                <Font.Action>
                  <Icon.ChevronRight />
                </Font.Action>
              ),
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link to="#" onClick={() => scrollTurfIntoFocus(id)}>
                <Font.Action>{name}</Font.Action>
              </Link>,
            ]
          )}
      </div>
    </div>
  )
}

export default TurfsStickySection
