import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { fetchPledgeCardGalleryPacket } from 'requests/qualityControl'
import { packetFields } from 'requests/packets'
import { useRequest, useRoutePathParams } from 'hooks'
import { useQueryParams } from 'contexts'
import { sortBy, uniqBy } from 'lodash'

export const PledgeCardGalleryStateContext = createContext()
export const PledgeCardGalleryActionContext = createContext()

export const ViewerType = {
  Select: 'Select',
  Form: 'Form',
  Pledge: 'Pledge',
}

const PledgeCardGalleryContextProvider = ({ children }) => {
  const [packet, setPacket] = useState()
  const [packetScans, setPacketScans] = useState([])
  const [firstScan, setFirstScan] = useState({})
  const [secondScan, setSecondScan] = useState({})
  const {
    queryParams: { type, firstScanId, secondScanId },
    setQueryParams,
  } = useQueryParams()
  const [viewerType, setViewerType] = useState(
    type === ViewerType.Select ||
      type === ViewerType.Form ||
      type === ViewerType.Pledge
      ? type
      : ViewerType.Select
  )
  const [{ packetId }] = useRoutePathParams()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest(fetchPledgeCardGalleryPacket, {
    onSuccess: ({ shift: incomingPacket }) => {
      incomingPacket.forms.forEach(form => {
        const formattedScan = {
          id: form.id,
          fileUrl: form.file_url,
          fileLocator: form.file_locator,
          pledgeUrl: form.pledge_card_url,
        }
        setPacketScans(prevForms =>
          sortBy(
            uniqBy(prevForms.concat([formattedScan]), form => form.id),
            ['id']
          )
        )
        if (formattedScan.id === +firstScanId) {
          setFirstScan(formattedScan)
        }
        if (formattedScan.id === +secondScanId) {
          setSecondScan(formattedScan)
        }
      })

      setPacket({
        id: incomingPacket.id,
        scansCount: incomingPacket.forms_count,
        originalFilename: incomingPacket.name,
        scanName: incomingPacket.name,
        shift: {
          shift_start: incomingPacket.shift_start,
        },
        canvasser: {
          full_name: `${incomingPacket.canvasser.first_name} ${incomingPacket.canvasser.last_name}`,
        },
        coverSheet: incomingPacket.cover_sheet,
        fileLocator: incomingPacket.file_locator,
      })
    },
  })

  useEffect(() => {
    makeRequest(packetId, { fields: packetFields })
  }, [packetId])

  const pledgeCardGalleryStateValue = useMemo(
    () => ({
      packet,
      packetScans,
      firstScan,
      secondScan,
      viewerType,
    }),
    [packet, packetScans, firstScan, secondScan, viewerType]
  )

  const pledgeCardGalleryActionValue = useMemo(
    () => ({
      setFirstScan,
      setSecondScan,
      setViewerType,
      setQueryParams,
    }),
    []
  )

  return (
    <PledgeCardGalleryStateContext.Provider value={pledgeCardGalleryStateValue}>
      <PledgeCardGalleryActionContext.Provider
        value={pledgeCardGalleryActionValue}
      >
        {children}
      </PledgeCardGalleryActionContext.Provider>
    </PledgeCardGalleryStateContext.Provider>
  )
}

export const usePledgeCardGalleryState = () =>
  useContext(PledgeCardGalleryStateContext)
export const usePledgeCardGalleryActions = () =>
  useContext(PledgeCardGalleryActionContext)

export default PledgeCardGalleryContextProvider
