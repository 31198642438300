import { combineName } from 'utils/reporting'
import {
  basePartyColumns,
  partyDictionaryUrl,
} from 'registrationReports/reportsConfig/columns/basePartyColumns'

export default {
  id: 'party_by_canvasser',
  name: 'Party Preference',
  permissionId: 'party_report',
  description:
    'This report provides information on the party preferences of registrants. It summarizes this information by the canvasser who collected the forms.',
  dataDictionaryUrl: partyDictionaryUrl,
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    ...basePartyColumns,
  ],
}
