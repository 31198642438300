import PropTypes from 'prop-types'
import { TextBlock, SectionLabel } from 'components'
import { useTranslation } from 'react-i18next'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import styles from './ScanViewHeader.module.scss'
import GalleryButtonBlock from './GalleryButtonBlock'

const ScanViewerHeader = ({
  scansCount,
  currentScanIndex,
  scanNumber,
  customTitle,
}) => {
  const { t } = useTranslation()
  const [currentPacket] = useCurrentPacket()
  const scanCountText = `${t('Scan')} ${Math.max(currentScanIndex, 0) + 1} ${t(
    'of'
  )} ${scansCount}`

  return (
    <div className={styles.header}>
      {customTitle || (
        <TextBlock>
          <SectionLabel>{scanCountText}</SectionLabel>
        </TextBlock>
      )}
      <GalleryButtonBlock shift={currentPacket} scanNumber={scanNumber} />
    </div>
  )
}

ScanViewerHeader.propTypes = {
  scansCount: PropTypes.number.isRequired,
  currentScanIndex: PropTypes.number.isRequired,
  scanNumber: PropTypes.number.isRequired,
}

export default ScanViewerHeader
