import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { Font } from '@politechdev/blocks-design-system'
import { useNavigation } from 'layout/NavigationProvider/NavigationProvider'
import { useNavigationPermission } from 'layout/NavigationPermissionProvider/NavigationPermissionProvider'
import { Route, UseNavigationPermission, UseNavigationHook } from './Navigation'
import styles from './Navigation.module.scss'

type NavigationBlockProps = {
  IconComponent: React.FC
  label: string
  routes: Route
  active: boolean
  onSelect: () => void
}

const cx = classNames.bind(styles)

const NavigationBlock = ({
  IconComponent,
  label,
  routes,
  active,
  onSelect,
}: NavigationBlockProps) => {
  const { t } = useTranslation()
  const { isCollapsed } = useNavigation() as UseNavigationHook

  const { hasPermission } = useNavigationPermission({
    blocks: routes?._meta?.blocks,
    permissions: routes?._meta?.permissions,
    children: null,
  }) as UseNavigationPermission
  const { hasSomePermissions } = useNavigationPermission({
    blocks: routes?._meta?.blocks,
    permissions: Object.values(routes)
      .map(route => route?.permissions)
      .filter(permission => !!permission),
    children: null,
  }) as UseNavigationPermission

  if (!hasPermission || !hasSomePermissions) return null

  return (
    <li
      key={label}
      className={cx('navigation__block', {
        'navigation__block--collapsed': isCollapsed,
      })}
    >
      <div
        onClick={() => onSelect()}
        onMouseEnter={() => {
          onSelect()
        }}
        className={cx('navigation__block__badge', {
          'navigation__block__badge--active': active,
        })}
      >
        <Font.Display
          variant="small"
          className={styles.navigation__block__badge__icon}
        >
          <IconComponent />
        </Font.Display>
        <Font.Copy
          variant="medium"
          className={styles.navigation__block__badge__label}
        >
          {t(label)}
        </Font.Copy>
      </div>
    </li>
  )
}

export default NavigationBlock
