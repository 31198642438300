import moment from 'moment-timezone'
import {
  ENGLISH,
  notContactedChoices,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { mean } from 'lodash/math'
import {
  buildDefaultFilters,
  combineName,
  formatDuration,
  formatDurationCell,
} from 'utils/reporting'

export default {
  id: 'qc_phone_calls_status',
  name: 'Phone Calls Status',
  permissionId: 'qc_staff',
  description:
    'This report provides information on all phone verification calls.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=2128352725',
  columns: [
    {
      dataKey: 'caller_name',
      title: 'Caller name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.caller_first_name, rowData.caller_last_name),
    },
    {
      dataKey: 'call_id',
      title: 'Call ID',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'created_at',
      title: 'Date',
      type: 'date',
      bodyCell: ({ cellData }) => {
        if (cellData === '') return cellData
        return moment(cellData).format('MMM D, YYYY')
      },
      csvCell: ({ created_at }) => {
        if (created_at === '') return created_at
        return moment(created_at).format('MMM D, YYYY')
      },
      resizable: true,
    },
    {
      dataKey: 'call_time',
      derivedFrom: 'created_at',
      title: 'Time',
      type: 'date',
      bodyCell: ({ cellData }) => {
        if (cellData === '') return cellData
        return moment(cellData).format('h:mm A')
      },
      csvCell: ({ call_time }) => {
        if (call_time === '') return call_time
        return moment(call_time).format('h:mm A')
      },
      resizable: true,
    },
    {
      dataKey: 'registrant_name',
      title: 'Registrant name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(
          rowData.registrant_first_name,
          rowData.registrant_last_name
        ),
    },
    {
      dataKey: 'packet_name',
      title: 'Packet name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'scan_number',
      title: 'Scan number',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'phone_number',
      title: 'Phone number',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'duration',
      title: 'Duration',
      type: 'number',
      bodyCell: formatDurationCell,
      resizable: true,
      summaryFunction: ({ data }) => {
        if (!data[0]) return '-'
        const allValues = Object.values(data).map(value =>
          parseFloat(value.duration)
        )
        const realNumberValues = allValues.filter(value => !Number.isNaN(value))
        const meanValue = mean(realNumberValues)
        return meanValue ? formatDuration(meanValue) : '-'
      },
    },
    {
      dataKey: 'contacted',
      title: 'Contacted',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        notContactedChoices[ENGLISH].some(
          choice => choice.value === rowData.response
        )
          ? 'N'
          : 'Y',
    },
    {
      dataKey: 'verified',
      title: 'Verified',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) => {
        switch (rowData.verified) {
          case true:
            return 'Y'
          case false:
          default:
            return 'N'
        }
      },
    },
    {
      dataKey: 'notes',
      title: 'Phone Verification Notes',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'not_contacted_reason',
      title: 'Not contacted reason',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        notContactedChoices[ENGLISH].find(
          choice => choice.value === rowData.response
        )?.label ?? '',
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.label, [
      {
        title: 'Default Filter',
        hiddenColumns: ['call_id'],
      },
    ])
  },
}
