import { combineName } from 'utils/reporting'
import {
  basePartisanScoreColumns,
  partisanScoreDataDictionaryUrl,
} from 'registrationReports/reportsConfig/columns/basePartisanScoreColumns'

export default {
  id: 'partisan_score_by_canvasser',
  name: 'Partisan Score',
  permissionId: 'partisan_score_report',
  description:
    'This report provides information on the partisan score values of registrants. It summarizes this information by the canvasser who collected the forms. Partisan scores come from third party voter-file vendors. They are not created by Blocks.',
  dataDictionaryUrl: partisanScoreDataDictionaryUrl,
  columns: [
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    ...basePartisanScoreColumns,
  ],
}
