import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { buildOption } from './utils'

const EventTypeSelectField = ({
  onSelect,
  eventType,
  eventTypes = [],
  isMulti,
  ...props
}) => {
  const { t } = useTranslation()
  const {
    tenantOptions: { event_types: tenantEventTypes },
  } = useCurrent()
  const [options, setOptions] = useState()
  const [value, setValue] = useState(eventType)
  const [multiValue, setMultiValue] = useState(eventTypes)

  useEffect(() => {
    setValue(eventType)
  }, [eventType])

  useEffect(() => {
    setMultiValue(eventTypes)
  }, [JSON.stringify(eventTypes)])

  useEffect(() => {
    setOptions(tenantEventTypes.sort().map(buildOption))
  }, [])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections)
  }

  if (isMulti) {
    return (
      <MultiSelectField
        id="event-type-select"
        name="event-type-select"
        label={t('Event type')}
        value={multiValue}
        options={options}
        onSelect={handleMultiChange}
        {...props}
      />
    )
  }

  return (
    <SelectField
      id="event-type-select"
      name="event-type-select"
      label={t('Event type')}
      value={value}
      options={options}
      onSelect={handleChange}
      {...props}
    />
  )
}

export default EventTypeSelectField
