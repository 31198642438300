import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts/index'
import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { useRequest } from 'hooks/useRequest'
import { useReactRouter } from 'hooks/router'
import { useState } from 'react'
import { DELIVERY_STATUSES } from 'constants/deliveries'
import { deleteDelivery, fetchDeliveries } from '../DeliverySingle/utils'
import tableConfig from './tableConfig'

const DeliveryTable = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()

  const [deliveries, setDeliveries] = useState([])
  const [totalDeliveries, setTotalDeliveries] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')

  const canModifyDelivery = doesCurrentUserHavePermission({
    resource: 'delivery',
    ability: 'modify',
  })

  const canDeleteDelivery = doesCurrentUserHavePermission({
    resource: 'canvasser',
    ability: 'remove',
  })

  const { renderTable, refreshPage } = useAutoTable()

  const { makeRequest: deleteRequest, isLoading: deleteLoading } = useRequest(
    deleteDelivery,
    {
      onSuccess: () => {
        setErrorMsg('')
        refreshPage()
      },
      onError: () => {
        setErrorMsg(t('Failed to delete delivery'))
      },
    }
  )

  const { makeRequest: fetchRequest, isLoading: fetchLoading } = useRequest(
    fetchDeliveries,
    {
      onSuccess: ({
        deliveries: incomingDeliveries,
        meta: { total_count },
      }) => {
        setErrorMsg('')
        setDeliveries(incomingDeliveries)
        setTotalDeliveries(total_count)
      },
      onError: () => {
        setErrorMsg(t('Failed to fetch deliveries'))
      },
    }
  )

  const getDeliveries = async tableProps => {
    await fetchRequest({
      current_page: tableProps.page,
      per: tableProps.pageSize,
      sort_attr: tableProps.sortColumn || 'id',
      sort_dir: tableProps.sortOrder || 'asc',
      filters: {
        rules: tableProps.filterRules,
        conjunction: tableProps.filterConjunction,
      },
    })
  }

  const handleEdit = rowData => {
    history.push(`${match.url}/${rowData.id}`)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyDelivery),
        {
          ...createRowAction(t('Delete'), handleDelete, canDeleteDelivery),
          show: rowData => rowData.status === DELIVERY_STATUSES.SELECTION,
        },
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: deliveries,
    loading: fetchLoading || deleteLoading,
    error: errorMsg,
    totalRecords: totalDeliveries,
    columns,
    fetchAction: getDeliveries,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <DeliveryTable tableConfig={tableConfig} />
  </AutoTableProvider>
)
