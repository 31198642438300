export const TENANT_OFFSETS = {
  1: 'svga',
  2: 'svnv',
  3: 'svnc',
  4: 'svco',
  5: 'onearizona',
  6: 'svfl',
  10: 'ooc',
  12: 'svoh',
  14: 'sv',
  16: 'svmi',
  17: 'svmn',
  18: 'svwi',
  19: 'svpa',
  21: 'nvm',
  100: 'qc',
}

export const SHIFT_TYPE = {
  PETITION: 'petitions',
  REGISTRATION: 'voter_registration',
}
export const QC_STATUS_TYPES = [
  { label: 'Ready for scans', value: 'ready_for_scans' },
  { label: 'Ready for separation', value: 'ready_for_separation' },
  { label: 'Ready for visual qc', value: 'ready_for_qc' },
  { label: 'In visual qc', value: 'in_qc' },
  {
    label: 'Ready for phone verification',
    value: 'ready_for_phone_verification',
  },
  { label: 'In phone verification', value: 'in_phone_verification' },
  { label: 'Completed', value: 'completed' },
]

export const PETITION_STATUS_TYPES = [
  { label: 'Ready for scans', value: 'ready_for_scans' },
  { label: 'Ready for visual qc', value: 'ready_for_qc' },
  { label: 'In visual qc', value: 'in_qc' },
  { label: 'Completed', value: 'completed' },
]

export const QC_STATUS_MAP = QC_STATUS_TYPES.reduce((map, { label, value }) => {
  map[value] = label
  return map
}, {})
