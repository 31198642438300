import React, { useEffect, useState } from 'react'
import { fetchJSON, stringifyParams } from 'utils/req'
import { useTranslation } from 'react-i18next'
import { Button, Icon, useToast } from '@politechdev/blocks-design-system'
import { Uploader } from 'components'
import { useRequest } from 'hooks/useRequest'
import { useDeliveryState, useDeliveryActions } from './DeliveryContext'

const ReceiptUploader = () => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const { setCurrentDelivery } = useDeliveryActions()
  const [receiptUrl, setReceiptUrl] = useState(null)
  const { setToast } = useToast()

  const deliverDeliveryRequest = (deliveryId, receiptData) =>
    fetchJSON(
      `/api/v1/deliveries/${deliveryId}/deliver?${stringifyParams({ fields: ['status', 'runner_receipt_url'] })}`,
      'PUT',
      {
        event_args: [receiptData],
      },
      { useJwt: true }
    )

  const { makeRequest: deliverDelivery } = useRequest(deliverDeliveryRequest, {
    onSuccess: ({ delivery: { status, runner_receipt_url } }) => {
      setCurrentDelivery(currentDelivery => ({
        ...currentDelivery,
        status,
        runner_receipt_url,
      }))
    },
    onError: () => {
      setToast({
        message: t(
          'There was a problem completing your delivery. Please try again.'
        ),
        variant: 'error',
      })
    },
  })

  const handleUpload = fileData => deliverDelivery(currentDelivery.id, fileData)

  useEffect(() => {
    setReceiptUrl(currentDelivery.runner_receipt_url)
  }, [currentDelivery.runner_receipt_url])

  if (currentDelivery.status === 'completed') {
    if (receiptUrl) {
      return (
        <a href={receiptUrl} target="_blank" rel="noreferrer">
          <Button>
            <span>{t('Form Delivery Receipt')}</span>{' '}
            <Icon.CheckCircle alt="" />
          </Button>
        </a>
      )
    }
  }

  return (
    <Uploader
      label={t('Form Delivery Receipt')}
      dialog
      endpoint="/system/files/upload?url=true"
      onUpload={handleUpload}
      fileTypes={['.pdf']}
      stringifyData={false}
    />
  )
}

export default ReceiptUploader
