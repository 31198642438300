import {
  calculatePercent,
  calculateRate,
  summaryFunctions,
} from 'utils/reporting'

export default {
  id: 'location_catalist',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'location',
      title: 'Location',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'street_address',
      title: 'Street Address',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'county',
      title: 'County',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_shifts',
      title: 'Total Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'days_since_last_canvass',
      title: 'Days Since Last Canvass',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_forms_cover',
      title: 'Total Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_field_hours_cover',
      title: 'Total Field Hours (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'forms_per_field_hour_cover',
      title: 'Forms per Field Hour (Cover)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_forms_cover,
          rowData.total_field_hours_cover
        ),
    },
    {
      dataKey: 'forms_with_catalist_race',
      title: 'Forms with Race Data (Catalist)',
      type: 'number',
      resizeable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'percent_poc_catalist',
      title: 'Percent People of Color (Catalist)',
      type: 'number',
      resizeable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.forms_with_catalist_poc,
          rowData.forms_with_catalist_race
        ),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'forms_with_catalist_poc',
          'forms_with_catalist_race',
        ]),
    },
  ],
}
