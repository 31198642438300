import { useTranslation } from 'react-i18next'
import { useReactRouter, useRoutePathParams } from 'hooks'
import {
  MenuButton,
  ButtonBlock,
  Button,
} from '@politechdev/blocks-design-system'
import { READY_FOR_QC, IN_QC } from 'constants/qualityControl'
import { useCurrent } from 'contexts/index'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import styles from './CallMenu.module.scss'

const CallMenu = () => {
  const { t } = useTranslation()
  const [currentPacket] = useCurrentPacket()
  const {
    history: { push },
    location: { search },
  } = useReactRouter()
  const [{ type, flagId, originalType, packetId }] = useRoutePathParams()
  const { doesCurrentUserHavePermission, currentUser } = useCurrent()
  const { inspectedUser } = useQualityControl()
  const canCreateNewCalls = doesCurrentUserHavePermission({
    resource: 'quality_control/phone_verification_call',
    ability: 'modify',
  })
  const goToSession = mode => {
    const baseRoute = `/quality_control/inbox/${currentPacket.id}/phone_verification_session/${mode}`
    let queryParameters = ''
    if (search) {
      queryParameters = `${search}`
    }
    if (type && flagId && originalType && packetId) {
      const sourceId = JSON.stringify([type, flagId, originalType, packetId])
      queryParameters += queryParameters
        ? `&source=flags&sourceId=${sourceId}`
        : `?source=flags&sourceId=${sourceId}`
    }
    const fullRoute = `${baseRoute}${queryParameters}`
    if (inspectedUser.id) {
      push(`${fullRoute}&inspectedUserId=${inspectedUser.id}`)
    } else {
      push(fullRoute)
    }
  }

  const callSessionDisabled =
    currentPacket.status === READY_FOR_QC || currentPacket.status === IN_QC

  return (
    <ButtonBlock justify="center">
      <div className={styles.hintContainer}>
        <BeginCallSessionButton
          methods={currentUser.turf.options.phone_methods}
          disabled={callSessionDisabled || !canCreateNewCalls}
          onSelect={goToSession}
        />
        {callSessionDisabled && (
          <div className={styles.hint}>
            {t(
              'Visual QC must be completed on this packet before beginning phone verification'
            )}
          </div>
        )}
      </div>
    </ButtonBlock>
  )
}

const BeginCallSessionButton = ({ methods, disabled, onSelect }) => {
  const { t } = useTranslation()
  const beginCallSessionLabel = t('Begin Call Session')

  const callMenuOptions = Object.entries({
    voip: {
      label: t('Call in the browser'),
      value: 'voip',
    },
    manual: {
      label: t('Call using your own phone'),
      value: 'external',
    },
  })
    .filter(([method]) => methods.includes(method))
    .map(([, option]) => option)

  switch (callMenuOptions.length) {
    case 0:
      return <Button disabled>{beginCallSessionLabel}</Button>

    case 1:
      return (
        <Button
          disabled={disabled}
          onClick={() => onSelect(callMenuOptions[0].value)}
        >
          {beginCallSessionLabel}
        </Button>
      )

    default:
      return (
        <MenuButton label={beginCallSessionLabel} disabled={disabled}>
          <MenuButton.Select onSelect={onSelect} options={callMenuOptions} />
        </MenuButton>
      )
  }
}

export default CallMenu
