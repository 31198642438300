import React from 'react'
import classNames from 'classnames/bind'
import { useNavigation } from 'layout/NavigationProvider/NavigationProvider'
import Header from '../Header/Header'
import ImpersonationBar from '../ImpersonationBar/ImpersonationBar'
import styles from './ApplicationFrame.module.scss'
import Navigation from '../Navigation/Navigation'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import { NavigationPermissionProvider } from '../NavigationPermissionProvider/NavigationPermissionProvider'
import MaintenanceBanner from '../MaintenanceBanner/MaintenanceBanner'

const cx = classNames.bind(styles)

const ApplicationFrame = ({ children }) => {
  const { isCollapsed } = useNavigation()

  return (
    <NavigationPermissionProvider>
      <div className={styles.frame}>
        <div className={cx('sidebar', { 'sidebar--collapsed': isCollapsed })}>
          <Header />
          <Navigation />
        </div>
        <div className={styles.panel}>
          <MaintenanceBanner />
          <BreadCrumbs />
          <div className={styles.content}>
            <div className={styles.banners}>
              <ImpersonationBar />
            </div>
            {children}
          </div>
        </div>
      </div>
    </NavigationPermissionProvider>
  )
}

export default ApplicationFrame
