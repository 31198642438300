import { LoadBar, CardError } from 'components'
import { VisualApprovePacketViewer } from 'qualityControl/scanReview/PacketViewer'
import { formatErrorMessage } from 'utils/formatting'
import { QualityControlProvider } from 'qualityControl/QualityControlContext'
import {
  PacketConsumer,
  PacketProvider,
} from 'qualityControl/contexts/PacketContext'

const ShiftSinglePacketViewer = ({ shiftId }) => (
  <PacketProvider packetId={shiftId}>
    <PacketConsumer>
      {({ packetRequest: { isLoading, errors, hasErrors } }) => (
        <>
          <LoadBar show={isLoading} />
          <QualityControlProvider>
            <VisualApprovePacketViewer
              isOpen
              showDetail={false}
              showCountyLookup={false}
            />
          </QualityControlProvider>
          <CardError
            hide={!hasErrors}
            message={formatErrorMessage(errors)}
            hideSupportLink
          />
        </>
      )}
    </PacketConsumer>
  </PacketProvider>
)

export default ShiftSinglePacketViewer
