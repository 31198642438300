import { ErrorBoundary as AppsignalBoundary } from '@appsignal/react'
import { ErrorBoundary as BasicBoundary } from 'react-error-boundary'
import { appsignal } from './appsignal'
import GenericErrorPage from './auth/GenericErrorPage/GenericErrorPage'

export const ErrorBoundary = ({ children }) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <BasicBoundary FallbackComponent={GenericErrorPage}>
        {children}
      </BasicBoundary>
    )
  }

  return (
    <AppsignalBoundary instance={appsignal} fallback={GenericErrorPage}>
      {children}
    </AppsignalBoundary>
  )
}
