import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Font,
  Icon,
  List,
  ProgressBar,
  SectionLabel,
  Sheet,
  TextBlock,
} from '@politechdev/blocks-design-system'
import {
  FormWithShiftInfo,
  IneligibleFormsData,
  UpdateFormRequest,
  ResolvedModalData,
} from './types'
import { getIneligibilityData } from './utils'
import { useDeliveryState } from '../DeliveryContext'
import ResolveIneligibleModal from './Modals/ResolveIneligibleModal'
import ScanInspect from './ScanInspect'
import styles from './IneligibleList.module.scss'

const LoadingItem = ({ lookupCode }: { lookupCode: string }) => {
  const { t } = useTranslation()
  return (
    <List.Item>
      <ContentBlock>
        <Font.Label variant="detail">
          {t('Searching for lookup code {{ lookupCode }}', { lookupCode })}
        </Font.Label>
        <ProgressBar show />
      </ContentBlock>
    </List.Item>
  )
}

const UnknownFormItem = ({ lookupCode }: { lookupCode: string }) => {
  const { t } = useTranslation()
  return (
    <List.Item>
      <ContentBlock>
        <Font.Copy>{t('Unknown form')}</Font.Copy>
        <Font.Copy variant="hint">
          {t('Lookup code {{ lookupCode }}', { lookupCode })}
        </Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Font.Copy>{t('Not found')}</Font.Copy>
      </ContentBlock>
    </List.Item>
  )
}

const KnownFormItem = ({
  form,
  setInspectedFormKey,
}: {
  form: FormWithShiftInfo
  setInspectedFormKey: (key: string | null) => void
}) => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const ineligibilityData = getIneligibilityData(currentDelivery, form)

  return (
    <List.Item>
      <ContentBlock>
        <Font.Copy>
          <span>{`Scan ${form.scan_number} - ${form.display_name}`}</span>
        </Font.Copy>
        <Font.Copy variant="hint">{form.shift.name}</Font.Copy>
      </ContentBlock>
      <ContentBlock>
        <Font.Copy>{t(ineligibilityData.type)}</Font.Copy>
        <Font.Copy variant="hint">
          {t(ineligibilityData.reason, { deliveryId: currentDelivery.id })}
        </Font.Copy>
      </ContentBlock>
      <ButtonBlock justify="right">
        <Button.Secondary
          onClick={() => setInspectedFormKey(form.lookup_code!)}
          className={styles['expand-button']}
        >
          <span>{t('View form')}</span>
          <Icon.ArrowRight />
        </Button.Secondary>
      </ButtonBlock>
    </List.Item>
  )
}

const IneligibleItem = ({
  lookupCode,
  form,
  isLoading,
  setInspectedFormKey,
}: {
  lookupCode: string
  form?: FormWithShiftInfo
  isLoading: boolean
  setInspectedFormKey: (key: string | null) => void
}) => {
  if (isLoading) return <LoadingItem lookupCode={lookupCode} />

  if (!form) return <UnknownFormItem lookupCode={lookupCode} />

  return <KnownFormItem form={form} setInspectedFormKey={setInspectedFormKey} />
}

const BASE_LIST_COUNT = 4

const IneligibleList = ({
  ineligible,
  assembleIneligible,
  handleUpdateForm,
  ineligibleFormsData,
  isLoading,
  formsLoading,
}: {
  ineligible: string[]
  assembleIneligible: (form: FormWithShiftInfo) => Promise<void>
  handleUpdateForm: UpdateFormRequest
  ineligibleFormsData: IneligibleFormsData
  isLoading: boolean
  formsLoading: string[]
}) => {
  const { t } = useTranslation()
  const [count, setCount] = useState(BASE_LIST_COUNT)

  const [modalData, setModalData] = useState<ResolvedModalData>({
    isOpen: false,
  })

  const [inspectedFormKey, setInspectedFormKey] = useState<null | string>(null)

  const shownItems = ineligible.slice(0, count).map(lookupCode => ({
    lookupCode,
    form: ineligibleFormsData[lookupCode],
  }))

  useEffect(() => {
    if (inspectedFormKey && !ineligible.includes(inspectedFormKey)) {
      setInspectedFormKey(null)
    }
  }, [inspectedFormKey, ineligible])

  const showMoreItems = () => {
    setCount(current => current + BASE_LIST_COUNT)
  }

  const handleAssemble = async (form: FormWithShiftInfo, openModal = true) => {
    setModalData(
      openModal
        ? {
            isOpen: true,
            scanNumber: form.scan_number,
            packetName: form.shift.name,
          }
        : { isOpen: false }
    )
    await assembleIneligible(form)
  }

  const isShowMoreButtonActive =
    ineligible.length > 4 && ineligible.length > count

  return (
    <>
      <TextBlock>
        <SectionLabel>
          <span className={styles.title}>{t('Ineligible forms')}</span>
        </SectionLabel>
      </TextBlock>

      <ContentBlock>
        {!inspectedFormKey && (
          <Sheet>
            {isLoading && (
              <ContentBlock>
                <Font.Label variant="detail">{t('Loading')}&hellip;</Font.Label>
                <ProgressBar show />
              </ContentBlock>
            )}
            <List
              itemData={shownItems}
              emptyMessage={t(
                'Scanned forms that are ineligible will be shown here'
              )}
              loading={false}
              render={({ lookupCode, form }) => (
                <IneligibleItem
                  lookupCode={lookupCode as string}
                  form={form as FormWithShiftInfo}
                  isLoading={formsLoading.includes(lookupCode as string)}
                  setInspectedFormKey={setInspectedFormKey}
                />
              )}
            />
            <ButtonBlock justify="center">
              {!!isShowMoreButtonActive && (
                <Button.Secondary onClick={showMoreItems}>
                  <span>{t('Show more')}</span>
                  <Icon.ChevronDown />
                </Button.Secondary>
              )}
            </ButtonBlock>
          </Sheet>
        )}
        {!!inspectedFormKey && (
          <ScanInspect
            form={ineligibleFormsData[inspectedFormKey]}
            handleUpdateForm={handleUpdateForm}
            handleAssemble={handleAssemble}
            setInspectedFormKey={setInspectedFormKey}
          />
        )}
        <ResolveIneligibleModal
          modalData={modalData}
          setModalData={setModalData}
          setInspectedFormKey={setInspectedFormKey}
        />
      </ContentBlock>
    </>
  )
}

export default IneligibleList
