import {
  CollectedCell,
  CountyCell,
  PacketCell,
  RegistrantCell,
  ScanCell,
  UploadedCell,
} from './AssembledTableCells'
import {
  CollectedCsvCell,
  CountyCsvCell,
  PacketCsvCell,
  RegistrantCsvCell,
  ScanCsvCell,
  UploadedCsvCell,
} from './AssembledTableCsvCells'

export default {
  label: 'Assembled',
  columns: [
    {
      dataKey: 'scan_number',
      title: 'Scan',
      type: 'number',
      autoResize: true,
      resizable: true,
      bodyCell: ScanCell,
      csvCell: ScanCsvCell,
    },
    {
      dataKey: 'packet.original_filename',
      title: 'Packet',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: PacketCell,
      csvCell: PacketCsvCell,
    },
    {
      dataKey: 'form.display_name',
      title: 'Registrant',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: RegistrantCell,
      csvCell: RegistrantCsvCell,
    },
    {
      dataKey: 'county',
      title: 'County',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: CountyCell,
      csvCell: CountyCsvCell,
    },
    {
      dataKey: 'shift.shift_start',
      title: 'Collected',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: CollectedCell,
      csvCell: CollectedCsvCell,
    },
    {
      dataKey: 'created_at',
      title: 'Uploaded',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: UploadedCell,
      csvCell: UploadedCsvCell,
    },
  ],
}
