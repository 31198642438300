import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  READY,
  CONNECTING,
  ACTIVE,
  REVIEW,
  COMPLETE,
} from 'constants/phoneBankSessions'
import {
  fetchAllIncomingPhoneNumbers,
  createPhoneNumberWithAreaCode,
} from 'requests/voip'
import { findAreaCode } from 'utils/parsing'
import { CardError } from 'components'
import {
  useTwilioCall,
  useRouteQueryParams,
  useReactRouter,
  useRequest,
} from 'hooks'
import { useCurrent } from 'contexts/index'
import { CallPanelControls, CallPanelHeader } from 'components/CallPanel'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import SessionFooterCallButtonPortal from '../../SessionFooterCallButtonPortal/SessionFooterCallButtonPortal'
import PhoneNumberInput from '../../PhoneNumberInput/PhoneNumberInput'
import styles from './VoipCallPanel.module.scss'

const VALID_PHONE_LENGTH = 10

const findOrCreateMatchedPhoneNumber = async areaCode => {
  try {
    const numbersWithMatchingAreaCode =
      await fetchAllIncomingPhoneNumbers(areaCode)

    if (numbersWithMatchingAreaCode.length) {
      return numbersWithMatchingAreaCode[0]
    }

    const { number } = await createPhoneNumberWithAreaCode(areaCode)

    return number
  } catch (e) {
    return null
  }
}

const VoipCallPanel = ({
  packetId,
  createCallRecord,
  endCallRecord,
  isPortalAvailable,
  isEditing,
  phoneNumber,
  setPhoneNumber,
  recordingEnabled,
  currentCallId,
  callTimer,
  submitPhoneVerification,
}) => {
  const { callStep, setCallStep } = useCallSessionContext()
  const showTimer = [ACTIVE, REVIEW, COMPLETE].includes(callStep)
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [queryParams] = useRouteQueryParams()
  const scanId = +queryParams.scanId
  const [callError, setCallError] = useState(false)

  const {
    currentTenant: { subdomain: currentTenantSubdomain },
  } = useCurrent()

  const isPhoneNumberValid = phoneNumber.length === VALID_PHONE_LENGTH

  const { restartTimer, stopTimer, resetTimer, secondsElapsed } = callTimer

  const handleConnectionError = error => {
    // eslint-disable-next-line no-console
    console.log(error.code ?? error)
    setCallError(true)
  }

  const onConnect = () => {
    setCallStep(ACTIVE)
  }

  const onDisconnect = () => {
    endCallRecord()
    stopTimer()
    setCallStep(REVIEW)
  }

  const onError = error => {
    error.type === 'SET_UP' ? setCallError(true) : handleConnectionError(error)
  }

  const resetCall = () => {
    submitPhoneVerification()
    setCallError(false)
    setCallStep(READY)
    resetTimer()
  }

  const { startCall: startTwilioCall, endCall: endTwilioCall } = useTwilioCall({
    onConnect,
    onDisconnect,
    onError,
  })

  const onEndCall = () => {
    endTwilioCall()
    onDisconnect()
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: startCallReq, isLoading: isStartCallLoading } =
    useRequest(
      async phoneNumber => {
        setCallError(false)
        const matchedPhoneNumber = await findOrCreateMatchedPhoneNumber(
          findAreaCode(phoneNumber)
        )

        return [phoneNumber, matchedPhoneNumber]
      },
      {
        onSuccess: async ([phoneNumber, fromPhoneNumber]) => {
          setCallStep(CONNECTING)

          const {
            'quality_control/phone_verification_call': { twilio_call_id },
          } = await createCallRecord.makeRequest(
            {
              voter_registration_form_id: scanId,
              number: phoneNumber,
              external: false,
            },
            {
              fields: ['id', 'twilio_call_id'],
            }
          )

          const twilioOptions = {
            to: `+1${phoneNumber}`,
            twilio_call_id,
            prefix: currentTenantSubdomain,
            record: recordingEnabled,
          }

          if (fromPhoneNumber) {
            twilioOptions.from = fromPhoneNumber
          }

          await startTwilioCall(twilioOptions)

          restartTimer()
        },
        onError: () => {
          setCallStep(READY)
          setCallError(true)
        },
      }
    )

  const endSession = () => {
    if (callStep === CONNECTING || callStep === ACTIVE) {
      endTwilioCall()
    }
    history.push(
      `/quality_control/inbox/${packetId}/phone_verification?scanId=${scanId}`
    )
  }

  const getIsControlDisabled = () => {
    if (createCallRecord.isLoading || isStartCallLoading) {
      return true
    }

    if (callStep === READY) {
      return !isPhoneNumberValid || isEditing
    }

    if (callStep === CONNECTING || callStep === ACTIVE) {
      return !currentCallId
    }

    return false
  }

  const hasErrors = callError

  return (
    <div className={styles.callPanel}>
      <CallPanelHeader
        callStep={callStep}
        showTimer={showTimer}
        secondsElapsed={secondsElapsed}
      />
      {callStep === COMPLETE && !phoneNumber.length ? (
        'No phone number'
      ) : (
        <PhoneNumberInput
          disabled={callStep !== READY}
          phoneNumber={phoneNumber}
          hasError={!isPhoneNumberValid}
          onChange={setPhoneNumber}
          onEnterKeyPress={() => startCallReq(phoneNumber)}
        />
      )}
      <CardError
        hide={!hasErrors}
        message={t(
          'An error occured with this call, Please try again or contact support.'
        )}
        hideSupportLink
      />
      <SessionFooterCallButtonPortal isPortalAvailable={isPortalAvailable}>
        <CallPanelControls
          callStep={callStep}
          disabled={getIsControlDisabled()}
          startCall={() => startCallReq(phoneNumber)}
          endCall={onEndCall}
          endSession={endSession}
          resetCall={resetCall}
        />
      </SessionFooterCallButtonPortal>
    </div>
  )
}

export default VoipCallPanel
