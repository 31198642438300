import { combineName, filterJsonPairs } from 'utils/reporting'

export default {
  id: 'qc_notes_by_scan',
  name: 'QC Notes by Scan',
  permissionId: 'qc_workflow',
  description:
    'This report provides an overview of notes collected on each scan during the visual and phone QC process.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=2078476371',
  columns: [
    {
      dataKey: 'filename',
      title: 'Packet name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'voter_registration_scan_number',
      title: ' Scan number',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    {
      dataKey: 'canvasser_flag',
      title: 'Canvasser has QC flag',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'packet_flag',
      title: 'Packet has QC flag',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'visual_notes',
      title: 'Visual Review Notes',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'visual_reviewer_name',
      title: 'Visual Reviewer',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(
          rowData.visual_reviewer_first_name,
          rowData.visual_reviewer_last_name
        ),
    },
    {
      dataKey: 'phone_notes',
      title: 'Phone Verification Notes',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'feedback_on_canvasser',
      title: 'Feedback on Canvasser',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.phone_questions_responses
          ? filterJsonPairs(
              rowData.phone_questions_responses,
              'question',
              ['Feedback on canvasser'],
              'response',
              ['positive_feedback', 'negative_feedback', 'no_feedback']
            )
              .map(object =>
                object.response
                  .split(/[_]/)
                  .map(word => word.replace(word[0], word[0].toUpperCase()))
                  .join(' ')
              )
              .join(' | ')
          : '',
    },
    {
      dataKey: 'phone_verifier_name',
      title: 'Phone Verification Caller',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(
          rowData.phone_verifier_first_name,
          rowData.phone_verifier_last_name
        ),
    },
  ],
}
