import { AutoTableProvider, useAutoTable } from 'contexts'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import { useRequest, useRoutePathParams, UseRequestActions } from 'hooks'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchEvents } from 'requests/events'
import tableConfig from './tableConfig'

type Events = Array<{
  id: number
  name: string
  type: string
  start_time: string
  end_time: string
}>

type FetchEvents = {
  events: Events
  meta: {
    total_count: number
  }
}

type Params = {
  page: string
  pageSize: string
  sortColumn: string
  sortOrder: string
}

const OrganizationEventsTable = () => {
  const [{ id: organizationId }] = useRoutePathParams()
  const { t } = useTranslation()

  const { renderTable } = useAutoTable() as {
    renderTable: (props: {
      data: Events
      columns: typeof tableConfig.columns
      isLoading: boolean
      totalRecords: number
      fetchAction: UseRequestActions<FetchEvents>['makeRequest']
      emptyMessage: string
      error: boolean
    }) => ReactNode
  }

  const fetchEventsRequest = useRequest(
    (params: Params) =>
      fetchEvents({
        current_page: params.page,
        per: params.pageSize,
        sort_attr: params.sortColumn,
        sort_dir: params.sortOrder,
        filters: {
          rules: [
            {
              column: 'organization',
              operator: 'is',
              param: organizationId,
            },
          ],
        },
        fields: ['id', 'name', 'type', 'start_time', 'end_time'],
      }) as Promise<FetchEvents>
  )

  return (
    <>
      <TextBlock>
        <Font.Label variant="section">{t('Events')}</Font.Label>
      </TextBlock>
      {renderTable({
        fetchAction: fetchEventsRequest.makeRequest,
        isLoading: fetchEventsRequest.isLoading,
        totalRecords: fetchEventsRequest.response?.meta?.total_count || 0,
        data: fetchEventsRequest.response?.events || [],
        error: fetchEventsRequest?.hasErrors,
        columns: tableConfig.columns,
        emptyMessage: t('No events found for this organization.'),
      })}
    </>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    enableSort
  >
    <OrganizationEventsTable />
  </AutoTableProvider>
)
