import { baseMatchProcessColumns } from '../columns/baseMatchProcessColumns'

export default {
  id: 'match_process_by_county',
  name: 'County',
  columns: [
    {
      dataKey: 'county',
      title: 'Collection County',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'state',
      title: 'Collection State',
      type: 'string',
      resizable: true,
    },
    ...baseMatchProcessColumns,
  ],
}
