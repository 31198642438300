import { fetchJSON, stringifyParams } from 'utils/req'

const currentUserParams = {
  fields: [
    'id',
    'first_name',
    'last_name',
    'name',
    'email',
    'time_zone',
    'locale',
    'extras',
    'datatable_settings',
    'qc_office',
    'two_factor_phone_last_four',
    'created_at',
    {
      turf: [
        'id',
        'name',
        'states',
        'ethnicities',
        'genders',
        'voter_registration_config',
        'qc_config',
        'options',
        {
          activism_options_config: [
            'id',
            'issues',
            'skills',
            'languages',
            'campaigns',
          ],
        },
      ],
    },
    {
      role: ['id', 'admin', 'permissions', { dashboard_layout: ['content'] }],
    },
  ],
  indexed: true,
}

export const fetchCurrentTenant = useJwt => {
  const params = {
    fields: ['id', 'name', 'options', 'subdomain', 'blocks', 'states'],
    indexed: true,
  }

  return fetchJSON(
    `/api/v1/current/tenant?${stringifyParams(params)}`,
    'GET',
    null,
    { useJwt }
  )
}

export const fetchCurrentUser = () =>
  fetchJSON(
    `/api/v1/current/user?${stringifyParams(currentUserParams)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const putCurrentUser = (data = {}, isUpload = false) =>
  fetchJSON(
    `/api/v1/current/user?${stringifyParams(currentUserParams)}`,
    'PUT',
    data,
    { useJwt: true },
    { isUpload }
  )

export const fetchCurrentRoles = (params = {}) => {
  const defaultParams = {
    fields: ['id'],
  }

  const queryString = stringifyParams({
    ...defaultParams,
    ...params,
    per: Number.MAX_SAFE_INTEGER,
    indexed: true,
  })

  return fetchJSON(`/api/v1/roles?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}
