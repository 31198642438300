import {
  basePartisanScoreColumns,
  partisanScoreDataDictionaryUrl,
} from 'registrationReports/reportsConfig/columns/basePartisanScoreColumns'

export default {
  id: 'partisan_score_by_location',
  name: 'Partisan Score',
  permissionId: 'partisan_score_report',
  description:
    'This report provides information on the partisan score values of registrants. It summarizes this information by the canvassing location. Partisan scores come from third party voter-file vendors. They are not created by Blocks.',
  dataDictionaryUrl: partisanScoreDataDictionaryUrl,
  columns: [
    {
      dataKey: 'location_name',
      title: 'Location Name',
      type: 'string',
      resizable: true,
    },
    ...basePartisanScoreColumns,
  ],
}
