import {
  SelectField,
  FieldBlock,
  Icon,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import styles from 'registrationReports/ReportPicker.module.scss'

const ChartPicker = ({
  categoryId,
  chartType,
  chartTypes,
  chartCategories,
  changeChartType,
  changeChartCategory,
}) => {
  const { t } = useTranslation()
  const { doesCurrentUserHavePermission } = useCurrent()

  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="chart-category"
          label={t('Metric')}
          className={styles.select}
          options={chartCategories
            .filter(category =>
              doesCurrentUserHavePermission({
                resource: category.permissionId,
                ability: 'view',
              })
            )
            .map(category => ({
              value: category.id,
              label: t(category.name),
            }))}
          value={categoryId}
          onSelect={changeChartCategory}
        />
        {chartType ? (
          <>
            <Icon.ChevronRight key="chevron" className={styles.chevron} />
            <SelectField
              className={styles.select}
              key="chart-type"
              id="chart-type"
              label={t('Group')}
              options={chartCategories
                .find(chartCategory => chartCategory.id === categoryId)
                .chartTypes.map(type => {
                  const chartType = chartTypes.find(
                    chartType => chartType.id === type.id
                  )
                  return {
                    value: type.id,
                    label: type.label,
                    disabled: chartType.isSelectable === false,
                  }
                })}
              value={chartType}
              onSelect={changeChartType}
            />
          </>
        ) : null}
      </FieldBlock>
    </Sheet>
  )
}

export default ChartPicker
