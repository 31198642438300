import React from 'react'
import { Link } from 'react-router-dom'
import { TableGridCell } from 'components/DataTable/TableGrid/Cells'

export const EventLocationNameCell = ({ rowData }) => (
  <TableGridCell>
    <Link to={`/organize/event_locations/${rowData.id}`}>{rowData.name}</Link>
  </TableGridCell>
)

export const LocationAddressCell = ({ rowData }) => (
  <TableGridCell>
    {[
      rowData.street_address,
      rowData.city,
      rowData.state,
      rowData.zipcode,
    ].join(' ')}
  </TableGridCell>
)

export const LocationHostedStatus = ({ rowData }) => (
  <TableGridCell>{rowData.has_hosted_event ? 'yes' : 'no'}</TableGridCell>
)

export const LocationMaxSize = ({ rowData }) => (
  <TableGridCell>{rowData.maximum_occupancy}</TableGridCell>
)

export const LocationPublicTransportation = ({ rowData }) => (
  <TableGridCell>
    {rowData.has_public_transportation ? 'yes' : 'no'}
  </TableGridCell>
)
