const tableDisplayNames = {
  canvassers: 'Canvassers',
  deliveries: 'Deliveries',
  shifts: 'Shifts',
  locations: 'Locations',
  turfs: 'Turfs',
  phone_verification_responses: 'Phone Verifications',
  registrant_matches: 'Registrant Matches',
  registration_forms: 'Registration Forms',
  visual_reviews: 'Voter Reg. QC',
  scans_qc_overview: 'Combined Registration Data',
}

export default tableDisplayNames
