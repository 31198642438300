import {
  calculatePercent,
  combineName,
  formatDeliveryStatus,
  formatQcStatus,
  secondsToHours,
  combineJsonPairs,
  summaryFunctions,
} from 'utils/reporting'
import { customPercent } from 'registrationReports/reportsConfig/columnTotals'

export default {
  id: 'daily_qc',
  name: 'Daily QC',
  permissionId: 'qc_staff',
  description:
    'This report summarizes information for QC staff at a packet level. The "Packet Information" tab provides information from the cover sheet and information related to the shift. The "Visual QC" tab provides information on the QC staffer and the date QC was conducted, as well as the individual flags from visual QC. The "Phone Verification" tab provides information on calls related to that packet, when the phone QC was conducted and by which QC staffer, as well as the individual flags from phone QC. as well as the QC staffer and QC date, as well as the individual phone verification flags. The final tab provides notes from the Visual and Phone Verification QC and the tagged delivery location (where available).',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=26650809',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'packet_name',
      title: 'Packet Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_scans_cover',
      title: 'Total Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans',
      title: 'Total Scans',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_scans_qc',
      title: 'Total Forms Through QC',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'status',
      title: 'QC Status',
      type: 'string',
      deriveFunction: ({ rowData }) => formatQcStatus(rowData.status),
      resizable: true,
    },
    {
      dataKey: 'delivered',
      title: 'Delivery Status',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) => formatDeliveryStatus(rowData.delivered),
    },
    {
      dataKey: 'upload_date',
      title: 'Upload Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    {
      dataKey: 'collection_date',
      title: 'Collection Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'days_since_collection',
      title: 'Days Since Collection',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'shift_update_date',
      title: 'Last Updated Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'visual_qc_staffers',
      title: 'Visual QC Staffers',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineJsonPairs(
          rowData.visual_qc_staffers,
          'first_name',
          'last_name',
          ' ',
          ', '
        ),
    },
    {
      dataKey: 'visual_qc_staffer_offices',
      title: 'Visual QC Offices',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.visual_qc_staffer_offices
          ? rowData.visual_qc_staffer_offices.join(', ')
          : null,
    },
    {
      dataKey: 'visual_qc_date',
      title: 'Visual QC Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'approved_qc',
      title: 'Approved (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'missing_phone_qc',
      title: 'Missing Phone (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.total_scans_qc - rowData.scans_with_phones_qc,
    },
    {
      dataKey: 'reupload_qc',
      title: 'Needs Reupload (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'handwriting_qc',
      title: 'Handwriting (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_form_qc',
      title: 'Not a Card (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_assistance_qc',
      title: 'Canvasser Assistance (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'other_qc',
      title: 'Other (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_qc_staffers',
      title: 'Phone QC Staffers',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineJsonPairs(
          rowData.phone_qc_staffers,
          'first_name',
          'last_name',
          ' ',
          ', '
        ),
    },
    {
      dataKey: 'phone_qc_staffer_offices',
      title: 'Phone QC Offices',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.phone_qc_staffer_offices
          ? rowData.phone_qc_staffer_offices.join(', ')
          : null,
    },
    {
      dataKey: 'phone_qc_date',
      title: 'Phone QC Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'scans_with_phones_qc',
      title: 'Forms with Phones (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contacted_qc',
      title: 'Contacted (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_needs_attention_qc',
      title: 'Phone Needs Attention (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'confirmed_registered_qc',
      title: 'Confirmed Registration on Phone (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'did_not_register_qc',
      title: 'Did Not Register (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_address_qc',
      title: 'Correct Address (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_address_qc',
      title: 'Incorrect Address (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'correct_dob_qc',
      title: 'Correct DOB (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'incorrect_dob_qc',
      title: 'Incorrect DOB (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'received_feedback_qc',
      title: 'Received Canvasser Feedback (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'duplicate_scans_qc',
      title: 'Duplicate Form (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'not_home_qc',
      title: 'Not Home (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'disconnected_qc',
      title: 'Disconnected (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'call_back_qc',
      title: 'Call Back (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'language_barrier_qc',
      title: 'Language Barrier (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'refused_qc',
      title: 'Refused (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_number_qc',
      title: 'Wrong Number (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'wrong_voicemail_qc',
      title: 'Wrong Voicemail (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'busy_qc',
      title: 'Busy (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'do_not_call_qc',
      title: 'Do Not Call (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'visual_qc_notes',
      title: 'Visual QC Notes',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineJsonPairs(
          rowData.visual_qc_notes,
          'notes',
          'scan_number',
          ' #',
          '; '
        ),
    },
    {
      dataKey: 'phone_qc_notes',
      title: 'Phone QC Notes',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineJsonPairs(
          rowData.phone_qc_notes,
          'notes',
          'scan_number',
          ' #',
          '; '
        ),
    },
    {
      dataKey: 'hours_between_qc',
      title: 'Hours Between QC',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        secondsToHours(rowData.seconds_between_qc),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'percent_called_qc',
      title: 'Percent Called (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.contacted_qc, rowData.total_scans_qc),
      summaryFunction: ({ data, acc }) =>
        customPercent(data, acc, ['contacted_qc', 'total_scans_qc']),
    },
  ],
}
