import {
  Button,
  ButtonBlock,
  Font,
  Modal,
  Section,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { ResolvedModalData, SetRevolvedModalData } from '../types'

const ResolveIneligibleModal = ({
  modalData,
  setModalData,
  setInspectedFormKey,
}: {
  modalData: ResolvedModalData
  setModalData: SetRevolvedModalData
  setInspectedFormKey: (arg: null) => void
}) => {
  const { t } = useTranslation()

  const closeModal = () => {
    setModalData({
      isOpen: false,
    })
    setInspectedFormKey(null)
  }

  if (!modalData.isOpen) return null

  return (
    <Modal title={t('Issues resolved')} isOpen={modalData.isOpen}>
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {t(
                'Scan {{ scanNumber }} from Packet {{ packetName }} is now eligible for delivery',
                {
                  scanNumber: modalData.scanNumber,
                  packetName: modalData.packetName,
                }
              )}
            </Font.Copy>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Accent onClick={closeModal}>{t('Continue')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ResolveIneligibleModal
