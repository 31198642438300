import { useTranslation } from 'react-i18next'
import { Grid, Section, DetailItem, SocialLinksBlock } from 'components'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import { isEmpty } from 'lodash'
import {
  PHONE_TYPES,
  EMAIL_TYPES,
  PRIMARY_PHONE_CONTACT_TYPE,
} from 'constants/people'
import { PhoneNumberDetail } from './PhoneNumberDetail'

const PersonDetails = ({ person }) => {
  const { t } = useTranslation()

  const address = person.residential_address || {}
  const getLink = type =>
    person.social_links.find(acct => acct.type === type)?.url

  const hasContent = contact => !!contact.content

  const allPhones = [
    {
      contact_type: PRIMARY_PHONE_CONTACT_TYPE,
      content: person.primary_phone_number,
    },
    ...person.contact_methods,
  ].filter(c => hasContent(c) && c.contact_type.includes('phone'))

  const allEmails = [
    {
      contact_type: 'primary_email_address',
      content: person.primary_email_address,
    },
    ...person.contact_methods,
  ].filter(c => hasContent(c) && c.contact_type.includes('email'))

  return (
    <Grid>
      <div>
        <Section
          label={t('Phone Numbers')}
          empty={!allPhones.length}
          emptyMessage={t('No phone numbers')}
        >
          {allPhones.map((phone, i) => (
            <DetailItem
              label={t(PHONE_TYPES[phone.contact_type])}
              hide={phone.content === null}
              key={i}
            >
              <PhoneNumberDetail phone={phone} />
            </DetailItem>
          ))}
          <DetailItem label={t('Receives SMS')}>
            <ButtonBlock>
              <span>{person.receives_sms ? t('ON') : t('OFF')}</span>
              <span>
                {person.receives_sms ? <Icon.Bell /> : <Icon.BellSlash />}
              </span>
            </ButtonBlock>
          </DetailItem>
        </Section>
        <Section
          label={t('Email')}
          empty={!allEmails.length}
          emptyMessage={t('No emails')}
        >
          {allEmails.map((email, i) => (
            <DetailItem
              label={t(EMAIL_TYPES[email.contact_type])}
              hide={!email.content}
              key={i}
            >
              <a
                href={`mailto:${email.content}`}
                rel="noreferrer"
                target="_blank"
              >
                <Button.Secondary className="lowercase">
                  <Icon.Envelope />
                  <span>{email.content}</span>
                </Button.Secondary>
              </a>
            </DetailItem>
          ))}
        </Section>
        <SocialLinksBlock
          facebook={getLink('facebook')}
          linkedIn={getLink('linkedIn')}
          twitter={getLink('twitter')}
        />
      </div>
      <Section
        label={t('Residence')}
        empty={isEmpty(address) || Object.values(address).every(val => !val)}
        emptyMessage={t('No address')}
      >
        <DetailItem label={t('Street address')} hide={!address.line_one}>
          <div>
            {address.line_one} {address.line_two}
          </div>
        </DetailItem>
        <DetailItem label={t('City')} hide={!address.city}>
          {address.city}
        </DetailItem>
        <DetailItem label={t('Zipcode')} hide={!address.zipcode}>
          {address.zipcode}
        </DetailItem>
        <DetailItem label={t('State')} hide={!address.state}>
          {address.state}
        </DetailItem>
        <DetailItem label={t('County')} hide={!address.county}>
          {address.county}
        </DetailItem>
        <DetailItem
          label={t('Congressional district')}
          hide={!address.district_config?.congressional}
        >
          {address.district_config?.congressional}
        </DetailItem>
        <DetailItem
          label={t('State legislative upper district')}
          hide={!address.district_config?.state_legislative_upper}
        >
          {address.district_config?.state_legislative_upper}
        </DetailItem>
        <DetailItem
          label={t('State legislative lower district')}
          hide={!address.district_config?.state_legislative_lower}
        >
          {address.district_config?.state_legislative_lower}
        </DetailItem>
      </Section>
      <Section
        label={t('External')}
        empty={!person.external_id}
        emptyMessage={t('No external identification')}
      >
        <DetailItem label={t('External ID')} hide={!person.external_id}>
          {person.external_id}
        </DetailItem>
      </Section>
    </Grid>
  )
}

export default PersonDetails
