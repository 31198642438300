import { useState, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import {
  View,
  ViewContainer,
  ReportDescription,
  TurfSelectField,
} from 'components'
import {
  PageHeader,
  DateField,
  Sheet,
  FieldBlock,
  ButtonBlock,
  Button,
} from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useAutoTable,
  useQueryParams,
  useFilters,
  ReportModalProvider,
  useSort,
  useCurrent,
  useTurfs,
} from 'contexts'
import { useRouteQueryParams, useRoutePathParams } from 'hooks'
import { titleize } from 'utils/formatting'
import { prepareReports } from './reportsConfig/utils'
import ReportPicker from './ReportPicker'
import StandardReportTable from './StandardReportTable'

const defaultStartDate = moment().subtract(7, 'days').toISOString()
const defaultEndDate = moment().toISOString()

const RegistrationReports = () => {
  const { t } = useTranslation()
  const { setTableId } = useAutoTable()

  const {
    defaultFilters,
    clearFiltersOnTableIdChange,
    activeFilterId,
    setActiveFilter,
  } = useFilters()

  const { setSort } = useSort()
  const { queryParams, setQueryParams } = useQueryParams()

  const [{ reportCategory }] = useRoutePathParams()

  const config = prepareReports(reportCategory)

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
      role: currentUserRole,
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const { reportTypes } = useMemo(() => {
    const reportTypes = config.reportTypes.filter(reportType => {
      if (typeof reportType.isViewable === 'boolean') {
        return reportType.isViewable
      }
      if (typeof reportType.isViewable === 'function') {
        return reportType.isViewable(currentUserRole)
      }
      return true
    })

    return { reportTypes }
  }, [currentUserRole.id, reportCategory])

  const getDefaultType = () => {
    const validTypes = reportTypes
      .filter(reportType =>
        doesCurrentUserHavePermission({
          resource: reportType.permissionId,
          ability: 'view',
        })
      )
      .flatMap(reportType => reportType.id)

    if (
      reportTypes.find(({ id }) => id === queryParams.reportType) &&
      validTypes.includes(queryParams.reportType)
    ) {
      return queryParams.reportType
    }

    return validTypes[0]
  }

  const getDefaultDateRange = () => ({
    start: moment(queryParams.startDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.startDate, 'YYYYMMDD').toISOString()
      : defaultStartDate,
    end: moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.endDate, 'YYYYMMDD').toISOString()
      : defaultEndDate,
  })

  const [reportType, setReportType] = useState(getDefaultType())
  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [dateRange, setDateRange] = useState(getDefaultDateRange())

  const selectedReport = reportTypes.find(({ id }) => id === reportType)

  useEffect(() => {
    const defaultParams = {}
    if (
      !queryParams.startDate ||
      !moment(queryParams.startDate, 'YYYYMMDD', true).isValid() ||
      !queryParams.endDate ||
      !moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
    ) {
      defaultParams.startDate = moment(defaultStartDate).format('YYYYMMDD')
      defaultParams.endDate = moment(defaultEndDate).format('YYYYMMDD')
    }
    if (!queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    if (
      !queryParams.reportType ||
      !reportTypes.find(({ id }) => id === queryParams.reportType)
    ) {
      defaultParams.reportType = config.defaultReportType
    }
    if (!queryParams.sortColumn) {
      defaultParams.sortColumn = ''
    }
    if (!queryParams.sortOrder) {
      defaultParams.sortOrder = 'asc'
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  useEffect(() => {
    refreshCurrentTurfs({
      associations: [''],
      fields: ['id', 'name', 'parent_id', 'archived'],
      indexed: true,
    })

    if (!queryParams.reportType) {
      setTableId(getDefaultType())
    } else {
      setTableId(queryParams.reportType)
    }
  }, [])

  useEffect(() => {
    setSort({
      sortColumn: queryParams.sortColumn,
      sortOrder: queryParams.sortOrder,
    })
  }, [queryParams.sortColumn, queryParams.sortOrder])

  useEffect(() => {
    setQueryParams({ activeFilterId })
  }, [activeFilterId])

  useEffect(() => {
    if (!queryParams.filterRules && !queryParams.hiddenColumns) {
      setActiveFilter('default-0')
    }
  }, [defaultFilters])

  const changeReportType = reportType => {
    setReportType(reportType)
    setTableId(reportType)
    clearFiltersOnTableIdChange()
    setQueryParams({ reportType, sortColumn: '', sortOrder: 'asc' })
  }

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (start, end) => {
    if (!start || !end) return
    setDateRange({
      start,
      end,
    })
    setQueryParams({
      startDate: moment.tz(start, 'UTC').format('YYYYMMDD'),
      endDate: moment.tz(end, 'UTC').format('YYYYMMDD'),
    })
  }

  useEffect(() => {
    changeReportType(getDefaultType())
  }, [reportCategory])

  if (!selectedReport) return null

  return (
    <View>
      <PageHeader title={t(config.categoryTitle)}>
        <ButtonBlock justify="right">
          <a
            href={
              reportType === 'canvasser_quality_party' &&
              selectedReport.partisanDataDictionaryUrl
                ? selectedReport.partisanDataDictionaryUrl
                : selectedReport.dataDictionaryUrl
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button.Accent>{t('Data dictionary')}</Button.Accent>
          </a>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer loading={turfRefreshIsLoading}>
        <Sheet>
          <FieldBlock variant="medium">
            {reportType !== 'qc_packets_by_day' && (
              <DateField
                type="range"
                label={t('Date range')}
                onChange={changeDateRange}
                startDate={dateRange.start}
                endDate={dateRange.end}
              />
            )}
            <TurfSelectField
              label={t('Turf')}
              value={turfId}
              onSelect={changeTurf}
              isExpanded
              showArchived
            />
          </FieldBlock>
        </Sheet>
        <ReportPicker
          reportType={reportType}
          reportTypes={reportTypes}
          changeReportType={changeReportType}
        />
        <ReportDescription
          currentReport={selectedReport}
          categoryName={titleize(reportCategory)}
        />
        <ReportModalProvider>
          <Sheet>
            <StandardReportTable
              reportType={selectedReport}
              dateRange={dateRange}
              turfId={turfId}
            />
          </Sheet>
        </ReportModalProvider>
      </ViewContainer>
    </View>
  )
}

export default () => {
  const [{ reportCategory }] = useRoutePathParams()
  const [{ reportType }] = useRouteQueryParams()

  const defaultFilters = useMemo(() => {
    const config = prepareReports(reportCategory)
    const selectedReport = config.reportTypes.find(
      ({ id }) => id === reportType
    )
    return selectedReport?.defaultFilters || []
  }, [reportCategory, reportType])

  return (
    <AutoTableProvider
      persistSelectedFilter
      isFrontend
      enableQueryParams
      enableFilters
      enableSort
      showDownloadButton
      showFilterEditor
      showQuickSearch
      showTotals
      freezeLeftColumns={1}
      defaultFilters={defaultFilters}
    >
      <RegistrationReports />
    </AutoTableProvider>
  )
}
