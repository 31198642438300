import { useTranslation } from 'react-i18next'
import { PersonSelectField } from 'components'
import { Button, ButtonBlock, Font } from '@politechdev/blocks-design-system'
import styles from './AddGuestModal.module.scss'

const AddGuestModalControls = ({
  toggleForm,
  addGuest,
  inviter,
  setInviter,
  alreadySelectedGuests,
  alreadyAttendingGuests,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles['invited-by']}>
        <PersonSelectField
          id="invitedByPerson"
          label={t('Invited by (optional)')}
          onSelect={setInviter}
          person={inviter}
          clearable
        />
      </div>
      <div className={styles.toolbar}>
        <div className={styles['full-width-block']}>
          <PersonSelectField
            id="searchPeople"
            label={t('Search people to invite')}
            onSelect={addGuest}
            keepEmpty
            fields={[
              'id',
              'name',
              'primary_email_address',
              'primary_phone_number',
            ]}
            disabledPersonIds={[
              ...alreadySelectedGuests,
              ...alreadyAttendingGuests,
            ]}
            disabledPersonReason={t('already invited')}
            excludeDeceased
          />
        </div>
        <Font.Copy variant="secondary">{t('or')}</Font.Copy>
        <ButtonBlock>
          <Button.Secondary onClick={toggleForm}>
            {t('Create person')}
          </Button.Secondary>
        </ButtonBlock>
      </div>
    </>
  )
}

export default AddGuestModalControls
