import { DetailItem, FormattedData } from 'components'
import { useTranslation } from 'react-i18next'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import {
  getContactedScansCount,
  getScansWithPhoneNumbersVerifiedPercent,
} from 'qualityControl/contexts/contextSelectors'
import { usePhoneVerificationQuestions } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import styles from './PacketDetails.module.scss'

const PacketDetails = () => {
  const { t } = useTranslation()
  const [shift] = useCurrentPacket()
  const [questions] = usePhoneVerificationQuestions()

  const { name: packetName, location, turf } = shift
  const canvasserName = `${shift.canvasser?.first_name} ${shift.canvasser?.last_name}`
  const shiftDate = shift.shift_start
  const office = location.name
  const turfName = turf.name
  const contactedInPacket = getContactedScansCount(shift)
  const percentContacted = getScansWithPhoneNumbersVerifiedPercent(
    shift,
    questions
  )

  return (
    <div className={styles.details}>
      <DetailItem label={t('packet name')}>{packetName}</DetailItem>
      <DetailItem label={t('canvasser')}>{canvasserName}</DetailItem>
      <DetailItem label={t('shift date')}>
        <FormattedData type="date" value={shiftDate} />
      </DetailItem>
      <DetailItem label={t('Location')}>{office}</DetailItem>
      <DetailItem label={t('Location Category')}>
        {location.category}
      </DetailItem>
      <DetailItem label={t('turf')}>{turfName}</DetailItem>
      <DetailItem label={t('percent contacted')}>{percentContacted}</DetailItem>
      <DetailItem label={t('contacted in packet')}>
        {contactedInPacket}
      </DetailItem>
    </div>
  )
}

export default PacketDetails
