import { isPacketCompleted } from 'utils/qualityControl'
import { isToday } from 'utils/dateTime'

const sumScans = (totalScanCount, packet) => totalScanCount + packet.forms_count

const packetsToCount = shift =>
  !isPacketCompleted(shift) ||
  (isPacketCompleted(shift) && isToday(shift.completed_at))

export const calculateScanCount = assignedPackets =>
  assignedPackets.filter(packetsToCount).reduce(sumScans, 0)

export const filterAssignedPackets = (
  assignedPackets,
  currentTurfPerformsExternalQC
) =>
  assignedPackets
    .filter(p => (currentTurfPerformsExternalQC ? p.shift.qc_external : true))
    .filter(p => !p.unresolved_flags.some(f => f.trigger.stops_qc))

export const fetchSchedulesOnDateParams = {
  fields: [
    'id',
    'minutes',
    'date',
    {
      user: [
        'id',
        'name',
        'extras',
        'training_status',
        'trained_in_states',
        'time_zone',
        'email',
        'spoken_language_locales',
        {
          assigned_shifts: [
            'forms_count',
            'status',
            'completed_at',
            'qc_external',
            { unresolved_flags: [{ trigger: ['stops_qc'] }] },
          ],
        },
        { role: ['id', 'name', 'admin', 'permissions'] },
        { turf: ['qc_config'] },
      ],
    },
  ],
}
