import {
  LOCATION_TYPES,
  REGISTRATION_LOCATION_TYPES,
} from 'constants/locations'
import { formatDecimalCell } from 'utils/reporting'
import {
  LocationAddressCell,
  LocationHostedStatus,
  LocationMaxSize,
  LocationPublicTransportation,
  EventLocationNameCell,
} from './LocationTableCells'
import {
  locationAddressCsvCell,
  locationHostedStatusCsvCell,
  locationMaxSizeCsvCell,
  locationPublicTransportationCsvCell,
} from './locationCsvCells'

const enumOptions = [
  { value: true, label: 'yes' },
  { value: false, label: 'no' },
]

const enumFilterConstructor = rule => {
  if (!rule.param) {
    return { ...rule, param: true, invert: 'true' }
  }
  return rule
}

const baseCollectTableColumns = [
  {
    dataKey: 'name',
    title: 'Name',
    type: 'string',
    autoResize: true,
    resizable: true,
  },
  {
    dataKey: 'id',
    title: 'Location ID',
    type: 'number',
  },
  {
    dataKey: 'street_address',
    title: 'Address',
    type: 'string',
    autoResize: true,
    resizable: true,
    bodyCell: LocationAddressCell,
    csvCell: locationAddressCsvCell,
    filterConstructor: rule => ({
      rules: ['street_address', 'city', 'state', 'zipcode'].map(column => ({
        ...rule,
        column,
      })),
      conjunction: 'inclusive',
    }),
  },
  {
    dataKey: 'location_type',
    title: 'Location Type',
    type: 'enum',
    options: REGISTRATION_LOCATION_TYPES.map(type => ({
      label: type.label,
      value: type.key,
    })),
  },
  {
    dataKey: 'category',
    title: 'Category',
    type: 'string',
    autoResize: true,
    resizable: true,
  },
]

const collectTableConfig = (label, additionalColumns) => ({
  label,
  defaultSortColumn: 'name',
  defaultSortOrder: 'asc',
  tableFilters: {
    rules: REGISTRATION_LOCATION_TYPES.map(type => ({
      column: 'location_type',
      operator: 'is',
      param: type.key,
    })),
    conjunction: 'inclusive',
  },
  columns: [...baseCollectTableColumns, ...additionalColumns],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['id', 'category'] },
      },
    ]
  },
})

const eventTableConfig = {
  label: 'location-events',
  defaultSortColumn: 'name',
  defaultSortOrder: 'asc',
  tableFilters: {
    rules: [
      {
        column: 'location_type',
        operator: 'is',
        param: LOCATION_TYPES.venue.key,
      },
    ],
    conjunction: 'inclusive',
  },
  columns: [
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: EventLocationNameCell,
    },
    {
      dataKey: 'id',
      title: 'Location ID',
      type: 'number',
    },
    {
      dataKey: 'street_address',
      title: 'Address',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: LocationAddressCell,
      csvCell: locationAddressCsvCell,
      filterConstructor: rule => ({
        rules: ['street_address', 'city', 'state', 'zipcode'].map(column => ({
          ...rule,
          column,
        })),
        conjunction: 'inclusive',
      }),
    },
    {
      dataKey: 'has_hosted_event',
      title: 'Hosted',
      type: 'enum',
      options: enumOptions,
      filterConstructor: enumFilterConstructor,
      autoResize: true,
      resizable: true,
      bodyCell: LocationHostedStatus,
      csvCell: locationHostedStatusCsvCell,
    },
    {
      dataKey: 'maximum_occupancy',
      title: 'Size',
      type: 'number',
      autoResize: true,
      resizable: true,
      bodyCell: LocationMaxSize,
      csvCell: locationMaxSizeCsvCell,
    },
    {
      dataKey: 'has_public_transportation',
      title: 'Public Transportation',
      type: 'enum',
      options: enumOptions,
      filterConstructor: enumFilterConstructor,
      autoResize: true,
      resizable: true,
      bodyCell: LocationPublicTransportation,
      csvCell: locationPublicTransportationCsvCell,
    },
  ],
  get defaultFilters() {
    return [
      {
        title: 'Default Filter',
        name: 'default filter',
        metadata: { hiddenColumns: ['id'] },
      },
    ]
  },
}

const registrationTableConfig = collectTableConfig('locations-registration', [
  {
    dataKey: 'forms_per_hour',
    title: 'Forms Per Field Hour (Cover)',
    type: 'number',
    bodyCell: formatDecimalCell,
  },
])

const petitionsTableConfig = collectTableConfig('locations-petitions', [
  {
    dataKey: 'signatures_per_hour',
    title: 'Signatures Per Field Hour',
    type: 'number',
    bodyCell: formatDecimalCell,
  },
])

const tableConfig = {
  event_locations: eventTableConfig,
  voter_registration: registrationTableConfig,
  petitions: petitionsTableConfig,
}

export default tableConfig
