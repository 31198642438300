import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Section, FormattedData } from 'components'
import { Button } from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks/router'
import { REVIEW, COMPLETE } from 'constants/phoneBankSessions'
import { useCurrent } from 'contexts/index'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { hasPhoneMethod } from 'contexts/CurrentProvider/utils'
import styles from './CallPanel.module.scss'

const NoVoipPanel = ({ fetchNextCall }) => {
  const {
    selectedPhoneNumber: phoneNumber,
    currentCall,
    currentSession,
    callStep,
    setCallStep,
    updateCallStatus,
  } = useCallSessionContext()
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  const {
    currentUser,
    tenantOptions: { voip_conferences_enabled: voipConferencesEnabled },
    currentTenant: { subdomain },
  } = useCurrent()

  const hasVoip = hasPhoneMethod(currentUser, 'voip')

  const { phoneBankId, id: currentSessionId } = match.params

  useEffect(() => {
    if (currentCall.id && currentSession.phone_bank.id) {
      updateCallStatus('connect', {}, { voipConferencesEnabled, subdomain })
      setCallStep(REVIEW)
    }
  }, [currentCall.id, currentSession.phone_bank.id])

  const goToNext = () => {
    const { mode } = match.params
    fetchNextCall(phoneBankId, mode, hasVoip).then(() => setCallStep(REVIEW))
  }

  const endSession = () => {
    history.push(
      `/organize/phone_banks/${phoneBankId}/call_sessions/${currentSessionId}/end`
    )
  }

  return (
    <div className="call-panel">
      <Section>
        <span className="pb-label">{t('Number to call')}</span>
        <h2>
          <FormattedData value={phoneNumber} type="phone" />
        </h2>
      </Section>
      <div className={styles.buttonBlock}>
        <Button.Accent onClick={goToNext} disabled={callStep !== COMPLETE}>
          {t('Next call')}
        </Button.Accent>
        <Button disabled={callStep !== COMPLETE} onClick={endSession}>
          {t('End session')}
        </Button>
      </div>
      {callStep !== COMPLETE && (
        <div className={styles.buttonBlock__hint}>
          {t('Please include a response below to continue')}
        </div>
      )}
    </div>
  )
}

export default NoVoipPanel
