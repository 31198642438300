import { useState, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, TurfSelectField } from 'components'
import {
  PageHeader,
  DateField,
  Sheet,
  FieldBlock,
  ButtonBlock,
  Button,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useAutoTable,
  useQueryParams,
  useFilters,
  ReportModalProvider,
  useSort,
  useCurrent,
  useTurfs,
} from 'contexts'
import { useRouteQueryParams } from 'hooks'
import StandardReportTable from 'registrationReports/StandardReportTable'
import config from './reportsConfig'
import ReportPicker from './ReportPicker'
import CatalistPlot from './CatalistPlot/CatalistPlot'
import styles from './CatalistReports.module.scss'
import ChartPicker from './ChartPicker'

const defaultStartDate = moment().subtract(7, 'days').toISOString()
const defaultEndDate = moment().toISOString()

const CatalistReports = () => {
  const { t } = useTranslation()
  const { setTableId } = useAutoTable()

  const {
    defaultFilters,
    clearFiltersOnTableIdChange,
    activeFilterId,
    setActiveFilter,
  } = useFilters()

  const { setSort } = useSort()
  const { queryParams, setQueryParams } = useQueryParams()

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
      role: currentUserRole,
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const { reportTypes, reportCategories } = useMemo(() => {
    const reportTypes = config.reportTypes.filter(reportType => {
      if (typeof reportType.isViewable === 'boolean') {
        return reportType.isViewable
      }
      if (typeof reportType.isViewable === 'function') {
        return reportType.isViewable(currentUserRole)
      }
      return true
    })

    // eslint-disable-next-line import/no-named-as-default-member
    const reportCategories = config.reportCategories
      .map(reportCategory => {
        const types = reportCategory.reportTypes.filter(reportType =>
          reportTypes.find(
            availableReportType => availableReportType.id === reportType
          )
        )

        if (!reportTypes.length) return null
        return {
          ...reportCategory,
          reportTypes: types,
          defaultType: types[0],
        }
      })
      .filter(Boolean)

    return { reportTypes, reportCategories }
  }, [currentUserRole.id])

  const { chartTypes, chartCategories } = useMemo(() => {
    const chartTypes = config.chartTypes.filter(chartType => {
      if (typeof chartType.isViewable === 'boolean') {
        return chartType.isViewable
      }
      if (typeof chartType.isViewable === 'function') {
        return chartType.isViewable(currentUserRole)
      }
      return true
    })

    // eslint-disable-next-line import/no-named-as-default-member
    const chartCategories = config.chartCategories
      .map(chartCategory => {
        const types = chartCategory.chartTypes.filter(chartType =>
          chartTypes.find(
            availablechartType => availablechartType.id === chartType.id
          )
        )

        if (!chartTypes.length) return null
        return {
          ...chartCategory,
          chartTypes: types,
          defaultType: types[0].id,
        }
      })
      .filter(Boolean)

    return { chartTypes, chartCategories }
  }, [currentUserRole.id])

  const getDefaultType = () => {
    const validTypes = reportCategories
      .filter(reportCategory =>
        doesCurrentUserHavePermission({
          resource: reportCategory.permissionId,
          ability: 'view',
        })
      )
      .flatMap(reportCategory => reportCategory.reportTypes)

    if (
      reportTypes.find(({ id }) => id === queryParams.reportType) &&
      validTypes.includes(queryParams.reportType)
    ) {
      return queryParams.reportType
    }

    return validTypes[0]
  }

  const getDefaultChartType = () => {
    const validTypes = chartCategories
      .filter(chartCategory =>
        doesCurrentUserHavePermission({
          resource: chartCategory.permissionId,
          ability: 'view',
        })
      )
      .flatMap(chartCategory => chartCategory.chartTypes)
      .flatMap(chartType => chartType.id)

    return validTypes[0]
  }

  const [chartType, setChartType] = useState(getDefaultChartType())
  const [reportType, setReportType] = useState(getDefaultType())
  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [dateRange, setDateRange] = useState({
    start: moment(queryParams.startDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.startDate, 'YYYYMMDD').toISOString()
      : defaultStartDate,
    end: moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.endDate, 'YYYYMMDD').toISOString()
      : defaultEndDate,
  })
  const [reportCategoryId, setReportCategoryId] = useState(
    reportCategories.find(reportCategory =>
      reportCategory.reportTypes.includes(reportType)
    )?.id
  )

  const selectedReport = reportTypes.find(({ id }) => id === reportType)

  const [chartCategoryId, setChartCategoryId] = useState(
    chartCategories.find(category =>
      category.chartTypes.map(type => type.id).includes(chartType)
    )?.id
  )

  const selectedChartCategory = chartCategories.find(
    category => category.id === chartCategoryId
  )

  useEffect(() => {
    const defaultParams = {}
    if (
      !queryParams.startDate ||
      !moment(queryParams.startDate, 'YYYYMMDD', true).isValid() ||
      !queryParams.endDate ||
      !moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
    ) {
      defaultParams.startDate = moment(defaultStartDate).format('YYYYMMDD')
      defaultParams.endDate = moment(defaultEndDate).format('YYYYMMDD')
    }
    if (!queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    if (!queryParams.sortColumn) {
      defaultParams.sortColumn = ''
    }
    if (!queryParams.sortOrder) {
      defaultParams.sortOrder = 'asc'
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  useEffect(() => {
    refreshCurrentTurfs({
      associations: [''],
      fields: ['id', 'name', 'parent_id', 'archived'],
      indexed: true,
    })

    if (!queryParams.reportType) {
      setTableId(getDefaultType())
    } else {
      setTableId(queryParams.reportType)
    }
  }, [])

  useEffect(() => {
    setSort({
      sortColumn: queryParams.sortColumn,
      sortOrder: queryParams.sortOrder,
    })
  }, [queryParams.sortColumn, queryParams.sortOrder])

  useEffect(() => {
    setQueryParams({ activeFilterId })
  }, [activeFilterId])

  useEffect(() => {
    if (
      !queryParams.activeFilterId &&
      !queryParams.filterRules &&
      !queryParams.hiddenColumns
    ) {
      setActiveFilter('default-0')
    }
  }, [defaultFilters])

  const changeReportType = reportType => {
    setReportType(reportType)
    setTableId(reportType)
    clearFiltersOnTableIdChange()
    setQueryParams({ reportType, sortColumn: '', sortOrder: 'asc' })
  }

  const changeChartType = chartType => {
    setChartType(chartType)
  }

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (start, end) => {
    if (!start || !end) return
    setDateRange({
      start,
      end,
    })
    setQueryParams({
      startDate: moment.tz(start, 'UTC').format('YYYYMMDD'),
      endDate: moment.tz(end, 'UTC').format('YYYYMMDD'),
    })
  }

  const changeReportCategory = reportCategoryId => {
    const defaultReportType = reportCategories.find(
      category => category.id === reportCategoryId
    ).defaultType

    changeReportType(defaultReportType)
    setReportCategoryId(reportCategoryId)
  }

  const changeChartCategory = chartCategoryId => {
    setChartCategoryId(chartCategoryId)
    changeChartType(selectedChartCategory.defaultType)
  }

  if (!selectedReport) return null

  return (
    <View>
      <PageHeader title={t('Catalist reports')}>
        <ButtonBlock justify="right">
          <a
            href={selectedReport.dataDictionaryUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Button.Accent>{t('Data dictionary')}</Button.Accent>
          </a>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer loading={turfRefreshIsLoading}>
        <Sheet>
          <FieldBlock variant="medium">
            <DateField
              type="range"
              label={t('Date range')}
              onChange={changeDateRange}
              startDate={dateRange.start}
              endDate={dateRange.end}
            />
            <TurfSelectField
              label={t('Turf')}
              value={turfId}
              onSelect={changeTurf}
              isExpanded
              showArchived
            />
          </FieldBlock>
        </Sheet>
        <Sheet>
          <ChartPicker
            categoryId={chartCategoryId}
            chartType={chartType}
            chartTypes={chartTypes}
            chartCategories={chartCategories}
            changeChartType={changeChartType}
            changeChartCategory={changeChartCategory}
          />

          <div className={styles['chart-section-container']}>
            <div className={styles['chart-container']}>
              <ContentBlock />
              <CatalistPlot
                chartType={chartType}
                dateRange={dateRange}
                turfId={turfId}
              />
            </div>
          </div>
        </Sheet>
        <ReportModalProvider>
          <Sheet>
            <ReportPicker
              categoryId={reportCategoryId}
              reportType={reportType}
              reportTypes={reportTypes}
              reportCategories={reportCategories}
              changeReportType={changeReportType}
              changeReportCategory={changeReportCategory}
            />
            <StandardReportTable
              reportType={selectedReport}
              dateRange={dateRange}
              turfId={turfId}
            />
          </Sheet>
        </ReportModalProvider>
      </ViewContainer>
    </View>
  )
}

export default () => {
  const [{ reportType }] = useRouteQueryParams()
  const selectedReport = config.reportTypes.find(({ id }) => id === reportType)

  return (
    <AutoTableProvider
      persistSelectedFilter
      isFrontend
      enableQueryParams
      enableFilters
      enableSort
      showDownloadButton
      showFilterEditor
      showTotals
      freezeLeftColumns={1}
      defaultFilters={selectedReport?.defaultFilters || []}
    >
      <CatalistReports />
    </AutoTableProvider>
  )
}
