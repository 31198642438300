import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  TextBlock,
  Modal,
  ButtonBlock,
  ContentBlock,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchListFolders, moveLists } from 'requests/lists'
import { sortListFolders } from 'utils/lists'
import { useListCheckboxes } from '../ListCheckboxProvider/ListCheckboxProvider'
import styles from './ListMoveModal.module.scss'

const ListMoveModal = ({ folderId, isOpen, closeModal }) => {
  const { t } = useTranslation()
  const [selectedFolder, setSelectedFolder] = useState(null)
  const [folderOptionsSearch, setFolderOptionsSearch] = useState(undefined)
  const [debouncedFolderOptionsSearch] = useDebounce(folderOptionsSearch, 500)

  const { getCheckedCheckboxValues, uncheckAll } = useListCheckboxes()
  const selectedCount = getCheckedCheckboxValues().length

  const { makeRequest, isLoading, hasErrors } = useRequest(moveLists, {
    onSuccess: () => {
      closeModal()
      uncheckAll()
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const {
    makeRequest: makeFolderRequest,
    isLoading: foldersLoading,
    isRequestComplete: foldersLoaded,
    response: listFolderResponse,
  } = useRequest(fetchListFolders)

  const moveSelectedLists = () => {
    if (!selectedFolder) return
    makeRequest(getCheckedCheckboxValues(), selectedFolder)
  }

  useEffect(() => {
    makeFolderRequest({
      fields: ['id', 'name'],
      filters: debouncedFolderOptionsSearch
        ? {
            rules: [
              {
                column: 'name',
                operator: 'containing',
                param: debouncedFolderOptionsSearch,
              },
            ],
          }
        : undefined,
    })
  }, [debouncedFolderOptionsSearch])

  const folderOptions = foldersLoaded
    ? listFolderResponse.list_folders
        .sort(sortListFolders)
        .map(folder => ({ value: folder.id, label: folder.name }))
    : []

  return (
    <Modal
      id="move-lists"
      title={t(`Move ${selectedCount === 1 ? 'list' : 'lists'}`)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!hasErrors}
            message={t(
              `There was an error moving your selected ${
                selectedCount === 1 ? 'list' : 'lists'
              }`
            )}
            hideSupportLink
          />
          <TextBlock className={styles.text}>
            {t('Move')} <strong>{selectedCount}</strong>{' '}
            {t(`${selectedCount === 1 ? 'list' : 'lists'} to another folder`)}
          </TextBlock>
          <div className={styles.field}>
            <SelectField
              id="folder_id"
              label={t('Destination folder')}
              options={folderOptions.filter(
                folder => folder.value !== folderId
              )}
              loading={foldersLoading}
              value={selectedFolder || ''}
              onInputChange={setFolderOptionsSearch}
              onSelect={setSelectedFolder}
              required
            />
          </div>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={moveSelectedLists} disabled={!selectedFolder}>
            {t('Move')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ListMoveModal
