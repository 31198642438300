import { formatDecimalCell } from 'utils/reporting'
import { columnSummaryFunctions } from 'registrationReports/reportsConfig/columnTotals'
import locationCatalistConfig from './reports/locationCatalist'
import matchProcessByTurfConfig from './reports/matchProcessByTurf'
import matchProcessByCanvasserConfig from './reports/matchProcessByCanvasser'
import matchProcessByCountyConfig from './reports/matchProcessByCounty'

export const reportCategories = [
  {
    id: 'locations',
    name: 'Locations',
    reportTypes: ['location_catalist'],
    permissionId: 'catalist_reports',
  },
  {
    id: 'match_process',
    name: 'Match Process',
    reportTypes: [
      'match_process_by_turf',
      'match_process_by_county',
      'match_process_by_canvasser',
    ],
    permissionId: 'catalist_reports',
  },
]

export const chartCategories = [
  {
    id: 'poc',
    name: 'Percent People of Color',
    chartTypes: [
      { id: 'poc_by_location', label: 'Location' },
      { id: 'poc_by_turf', label: 'Turf' },
      { id: 'poc_by_county', label: 'County' },
    ],
    permissionId: 'catalist_reports',
  },
  {
    id: 'matched',
    name: 'Percent Matched',
    chartTypes: [
      { id: 'matched_by_turf', label: 'Turf' },
      { id: 'matched_by_county', label: 'County' },
      { id: 'matched_by_canvasser', label: 'Canvasser' },
    ],
    permissionId: 'catalist_reports',
  },
]

const prepareReportTypes = () => {
  const reportConfigs = [
    locationCatalistConfig,
    matchProcessByTurfConfig,
    matchProcessByCanvasserConfig,
    matchProcessByCountyConfig,
  ]

  return reportConfigs.map(reportConfig => ({
    ...reportConfig,
    columns: reportConfig.columns.map(column => ({
      ...column,
      bodyCell:
        column.type === 'number' && !column.bodyCell
          ? formatDecimalCell
          : column.bodyCell,
      summaryFunction:
        column.summaryFunction || columnSummaryFunctions[column.dataKey],
    })),
  }))
}

const chartTypes = chartCategories.map(category => category.chartTypes).flat(1)

export default {
  reportTypes: prepareReportTypes(),
  reportCategories,
  chartCategories,
  chartTypes,
}
