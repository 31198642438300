import canvasserQualityByGroup from './reports/canvasserQualityByGroup'
import canvasserQualityByGroupParty from './reports/canvasserQualityByGroupParty'
import canvasserQualityByGroupTablet from './reports/canvasserQualityByGroupTablet'
import districtByTurf from './reports/districtByTurf'
import partisanScoreByTurf from './reports/partisanScoreByTurf'
import partyByTurf from './reports/partyByTurf'
import partyLeanByTurf from './reports/partyLeanByTurf'
import qcByGroup from './reports/qcByGroup'
import qcStatus from './reports/qcStatus'

const reportConfigs = [
  canvasserQualityByGroup,
  canvasserQualityByGroupTablet,
  canvasserQualityByGroupParty,
  qcByGroup,
  qcStatus,
  partyByTurf,
  partyLeanByTurf,
  districtByTurf,
  partisanScoreByTurf,
]

export default {
  reportCategory: 'turfs',
  categoryTitle: 'Turf Reports',
  reportConfigs,
}
