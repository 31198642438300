import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import countiesByState from 'utils/counties.json'
import {
  View,
  ViewContainer,
  Sheet,
  Section,
  DetailItem,
  FormattedData,
  ScrollToTop,
  LoadBar,
} from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { Link, useLocation } from 'react-router-dom'
import { useRoutePathParams, useReactRouter } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { useCurrent } from 'contexts/index'
import RegistrationFormTable from './RegistrationFormTable'
import { fetchShift } from '../requests/shifts'
import ShiftSinglePacketViewer from './ShiftSinglePacketViewer'

const RegistrationPacketSingle = () => {
  const { history } = useReactRouter()
  const [{ shiftType, id: shiftId }] = useRoutePathParams()
  const [countyOptions, setCountyOptions] = useState([])
  const {
    currentTenant: { states },
  } = useCurrent()
  const location = useLocation()

  const {
    makeRequest,
    isRequestComplete: shiftLoaded,
    hasErrors,
    response,
  } = useRequest(fetchShift, {
    onError: e => {
      if (e.status === 404) {
        history.push(`/not_found?from=${location.pathname}`)
      }
    },
  })

  const currentShift = response?.shift
  const { t } = useTranslation()

  useEffect(() => {
    makeRequest(shiftId, {
      fields: [
        'id',
        { turf: ['states'] },
        { canvasser: ['full_name'] },
        'shift_start',
        'name',
        {
          forms: ['display_name', 'delivery_id', 'id', 'county', 'scan_number'],
        },
      ],
    })
  }, [shiftId])

  useEffect(() => {
    if (states) {
      const countyNames = Array.from(
        new Set(states.flatMap(state => countiesByState[state]))
      )

      setCountyOptions(
        countyNames.map(countyName => ({
          label: countyName,
          value: countyName,
        }))
      )
    }
  }, [currentShift])

  return (
    <View>
      <ScrollToTop />
      <ViewContainer>
        {!shiftLoaded || hasErrors ? (
          <LoadBar show />
        ) : (
          <>
            <Sheet
              title={`Packet${
                currentShift.name ? ` ${currentShift.name}` : ''
              }`}
              key="detail-sheet"
            >
              <div className="packet__details">
                <Section>
                  <DetailItem label={t('Canvasser')}>
                    {currentShift.canvasser.full_name}
                  </DetailItem>
                  <DetailItem label={t('Shift date')}>
                    <FormattedData
                      type="date"
                      value={currentShift.shift_start}
                    />
                  </DetailItem>
                </Section>
                <Section>
                  <DetailItem label={t('Forms assigned to delivery')}>
                    {currentShift.forms.filter(s => !!s.delivery_id).length}
                  </DetailItem>
                  <DetailItem label={t('Total forms')}>
                    {currentShift.forms.length}
                  </DetailItem>
                </Section>
              </div>
              <ButtonBlock justify="right">
                <Link to={`/collect/${shiftType}/shifts/${shiftId}/edit`}>
                  <Button>{t('View shift')}</Button>
                </Link>
              </ButtonBlock>
            </Sheet>
            <RegistrationFormTable
              forms={currentShift.forms}
              countyOptions={countyOptions}
              loading={!shiftLoaded}
              key="reg-forms"
            />
            <Sheet>
              <ShiftSinglePacketViewer shiftId={currentShift.id} />
            </Sheet>
          </>
        )}
      </ViewContainer>
    </View>
  )
}

export default RegistrationPacketSingle
