import {
  Button,
  ButtonBlock,
  Checkbox,
  FieldBlock,
  Font,
  Modal,
  Section,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const PacketIncompleteQCModal = ({
  isOpen,
  setIsOpen,
  handleIgnore,
  handleContinue,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  handleIgnore: () => void
  handleContinue: () => void
}) => {
  const [shouldIgnore, setShouldIgnore] = useState(false)

  const { t } = useTranslation()

  const onContinue = () => {
    if (shouldIgnore) {
      handleIgnore()
      setShouldIgnore(false)
    }
    handleContinue()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      title={t('Deliver a packet that has not completed QC?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {t('This packet has not completed QC.')}
            </Font.Copy>
            <Font.Copy Element="p">
              {t(
                'It is typically not recommended to deliver packets before QC is completed. Are you sure you want to continue?'
              )}
            </Font.Copy>
          </TextBlock>
          <FieldBlock>
            <Checkbox
              label={t(
                'Don’t show me this again for other scans in this packet'
              )}
              onChange={() => setShouldIgnore(current => !current)}
              checked={shouldIgnore}
            />
          </FieldBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onContinue}>
            {t('Deliver anyway')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default PacketIncompleteQCModal
