import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ContentBlock,
  TextField,
  Section,
  SectionLabel,
  ButtonBlock,
  FieldBlock,
  Modal,
  useToast,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest, useEvent } from 'hooks'
import { redirectToInbox } from 'qualityControl/inbox/utils'
import { ScanPdfViewer } from 'qualityControl/scanReview/PdfViewer'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import { qcPacketFields } from 'constants/qualityControl'
import {
  useCurrentScan,
  useScanActions,
} from 'qualityControl/contexts/ScanContext'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import { getSortedScanIds } from 'qualityControl/contexts/contextSelectors'
import { fetchUnresolvedFlagTriggers } from 'requests/packets'
import { useLocation } from 'react-router-dom'
import styles from './IssueModal.module.scss'
import IssueOptionsList from '../IssueOptionsList/IssueOptionsList'

const POLL_INTERVAL = 1000

const IssueModal = ({ isOpen, closeModal, presetIssueIds = [] }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [currentPacket] = useCurrentPacket()
  const [currentScan, setCurrentScanId] = useCurrentScan()
  const { disapproveScan } = useScanActions()
  const scanId = currentScan?.id

  const sortedScanIds = getSortedScanIds(currentPacket)
  const { setToast } = useToast()
  const currentScanIndex = sortedScanIds.indexOf(scanId)

  const { inboxUser: user } = useInbox()
  const { getPackets, inspectedUser, getInspectedUser } = useQualityControl()
  const staffId = user.id

  const goToNextScan = () => {
    setCurrentScanId(sortedScanIds[currentScanIndex + 1])
  }

  const [notes, setNotes] = useState(() => currentScan.notes)
  const [issueReasons, setIssueReasons] = useState([])

  const { history } = useReactRouter()
  const onDisapproveScanSuccess = () => {
    closeModal()
    inspectedUser.id
      ? getInspectedUser(inspectedUser.id)
      : getPackets({
          fields: qcPacketFields,
        })

    goToNextScan()
  }
  // eslint-disable-next-line blocks/missing-response-error
  const fetchUnresolvedFlagTriggersRequest = useRequest(
    fetchUnresolvedFlagTriggers
  )

  const pollTimer = useRef()

  useEffect(() => {
    clearTimeout(pollTimer.current)
    pollTimer.current = setInterval(() => {
      fetchUnresolvedFlagTriggersRequest.makeRequest(currentPacket.id)
    }, POLL_INTERVAL)
  }, [currentPacket.id])

  useEffect(() => () => clearInterval(pollTimer.current), [])

  if (fetchUnresolvedFlagTriggersRequest.response) {
    const doesStopQc =
      fetchUnresolvedFlagTriggersRequest.response.shift.forms.some(scan =>
        scan.unresolved_flag_triggers.some(
          unresolved_trigger =>
            unresolved_trigger.needs_reupload || unresolved_trigger.stops_qc
        )
      )

    if (doesStopQc) {
      clearInterval(pollTimer.current)
      setToast({
        message: t(
          `Quality control for ${currentPacket.name} was automatically ended as a result of one of the responses submitted. Please continue with other packets.`
        ),
        duration: 5000,
      })
      redirectToInbox(staffId, history, inspectedUser.id)
    }
  }

  if (!pathname.endsWith('/visual_qc')) {
    clearInterval(pollTimer.current)
  }

  const { isLoading, hasErrors, makeRequest } = useRequest(disapproveScan, {
    onSuccess: onDisapproveScanSuccess,
  })

  const handleDisapproveScan = useEvent(async () => {
    await makeRequest(scanId, notes, issueReasons)
  })

  useEffect(() => {
    setNotes('')
    setIssueReasons(presetIssueIds)
  }, [isOpen])

  const addIssueReason = reason => {
    setIssueReasons(oldReasons => oldReasons.concat([reason]))
  }

  const removeIssueReason = reason => {
    setIssueReasons(oldReasons =>
      oldReasons.filter(oldReason => oldReason !== reason)
    )
  }

  return (
    <Modal
      id="visual-qc-issue"
      title={t(`Attention needed`)}
      isOpen={isOpen}
      showLoading={isLoading}
      className={styles.modal}
    >
      <Modal.Body>
        <CardError hide={!hasErrors} />
        <div className={styles.grid}>
          <div>
            <ContentBlock>
              <SectionLabel primary>{`${t('Scan')} ${
                Math.max(currentScanIndex, 0) + 1
              } ${t('of')} ${currentPacket.forms_count}`}</SectionLabel>
            </ContentBlock>
            <ContentBlock>
              <ScanPdfViewer
                scanUrl={currentScan.file_url}
                removedAt={currentScan.file_locator?.metadata?.redacted_at}
                isInteractive
              />
            </ContentBlock>
          </div>
          <div>
            <ContentBlock>
              <SectionLabel>{t('Issues Found')}</SectionLabel>
            </ContentBlock>
            <ContentBlock>
              <IssueOptionsList
                issueReasons={issueReasons}
                addIssueReason={addIssueReason}
                removeIssueReason={removeIssueReason}
              />
            </ContentBlock>
            <Section label={t('Notes')}>
              <FieldBlock variant="large">
                <TextField
                  aria-label={t('Notes')}
                  id="notes"
                  value={notes || ''}
                  onChange={setNotes}
                />
              </FieldBlock>
            </Section>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary disabled={isLoading} onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent
            disabled={issueReasons.length === 0 || isLoading}
            onClick={handleDisapproveScan}
          >
            {t(isLoading ? 'Submitting' : 'Submit')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default IssueModal
