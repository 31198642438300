import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import countiesByState from 'utils/counties.json'
import { useReactRouter, useRequest } from 'hooks'
import {
  IN_QC,
  qcPacketFields,
  READY_FOR_PHONE_VERIFICATION,
} from 'constants/qualityControl'
import { redirectToInbox } from 'qualityControl/inbox/utils'
import { startCase, toLower } from 'lodash'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import {
  useCurrentPacket,
  usePacketActions,
} from 'qualityControl/contexts/PacketContext'
import { getCurrentPacketVisualQcComplete } from 'qualityControl/contexts/contextSelectors'
import { LookupCodeInput } from 'components/LookupCodeInput/LookupCodeInput'
import ApprovalConfirmationPanel from '../ApprovalConfirmationPanel'
import IssuePanel from '../IssuePanel/IssuePanel'
import CountyLookup from '../CountyLookup/CountyLookup'
import ReviewSelectionPanel from '../ReviewSelectionPanel'
import IssueModal from '../IssueModal/IssueModal'
import ProofOfResidence from '../ProofOfResidence/ProofOfResidence'
import styles from './QcReviewPanel.module.scss'

const QcReviewPanel = ({ currentScan, goToNext, showCountyLookup }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [countyValue, setCountyValue] = useState(
    startCase(toLower(currentScan.county)) || ''
  )
  const { inboxUser: user } = useInbox()
  const { getPackets, getInspectedUser, inspectedUser } = useQualityControl()
  const [currentPacket] = useCurrentPacket()
  const { advanceCurrentPacket } = usePacketActions()
  const advanceCurrentPacketToReadyForPhoneVerification = () =>
    advanceCurrentPacket(READY_FOR_PHONE_VERIFICATION)
  const showPor = currentPacket.turf.voter_registration_config.show_pors
  const currentPacketLocationState = currentPacket.location?.state

  const staffId = user.id
  const { id } = currentScan
  const [canReview, enableReview] = useState(false)
  const [issueModalOpen, setIssueModalOpen] = useState(false)
  const isApproved =
    currentScan.visual_reviews &&
    currentScan.visual_reviews.some(
      visualReview => visualReview.response.response === 'approved'
    )

  const getReviewStatus = () => {
    if (!currentScan.visual_reviews.length) {
      return 'unreviewed'
    }
    if (isApproved) {
      return 'approved'
    }
    return 'issuesRaised'
  }

  const validCounties = currentPacketLocationState
    ? countiesByState[currentPacketLocationState].concat(['cannot_determine'])
    : []

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: requestPackets } = useRequest(getPackets, {
    onSuccess: () => redirectToInbox(staffId, history, inspectedUser.id),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: requestStaffPackets } = useRequest(getInspectedUser, {
    onSuccess: () => redirectToInbox(staffId, history, inspectedUser.id),
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: moveToPhoneVerification } = useRequest(
    advanceCurrentPacketToReadyForPhoneVerification,
    {
      onSuccess: () => {
        inspectedUser.id
          ? requestStaffPackets(staffId)
          : requestPackets({
              fields: qcPacketFields,
            })
      },
    }
  )

  const isVizQcComplete = getCurrentPacketVisualQcComplete(currentPacket)
  useEffect(() => {
    if (isVizQcComplete && currentPacket?.status === IN_QC) {
      moveToPhoneVerification()
    }
  }, [isVizQcComplete, currentPacket?.status])

  useEffect(() => {
    setIssueModalOpen(false)
  }, [currentScan])

  useEffect(() => {
    enableReview(validCounties.includes(countyValue))
  }, [countyValue])

  const formId = currentScan.form?.id

  return (
    <>
      {showPor && !!formId && <ProofOfResidence formId={formId} />}
      {showCountyLookup && (
        <CountyLookup
          enableReview={enableReview}
          countyValue={countyValue}
          setCountyValue={setCountyValue}
          scanId={id}
        />
      )}
      <LookupCodeInput
        currentScan={currentScan}
        hidden={
          !currentPacket?.turf?.voter_registration_config
            ?.uses_scan_lookup_codes
        }
      />
      <div className={styles.label}>
        <h3>{t('Visual Quality Control')}</h3>
      </div>
      {getReviewStatus() === 'unreviewed' && (
        <ReviewSelectionPanel
          canReview={canReview || !showCountyLookup}
          toggleIssuePanel={() => {
            setIssueModalOpen(!issueModalOpen)
          }}
        />
      )}
      {getReviewStatus() === 'approved' && (
        <ApprovalConfirmationPanel
          currentScan={currentScan}
          currentReviews={currentScan.visual_reviews}
        />
      )}
      {getReviewStatus() === 'issuesRaised' && (
        <IssuePanel
          currentScan={currentScan}
          currentReviews={currentScan.visual_reviews}
          goToNext={goToNext}
        />
      )}
      <IssueModal
        isOpen={issueModalOpen}
        closeModal={() => setIssueModalOpen(false)}
      />
    </>
  )
}

export default QcReviewPanel
