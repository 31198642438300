import { ALL_ORGANIZATION_TYPES } from 'constants/organizations'
import { OrganizationNameCell } from 'organizations/OrganizationTable/OrganizationTableCells'

export default {
  label: 'team-organizations',
  columns: [
    {
      dataKey: 'name',
      title: 'Name',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: OrganizationNameCell,
    },
    {
      dataKey: 'organization_type',
      title: 'Organization type',
      type: 'enum',
      options: ALL_ORGANIZATION_TYPES,
      autoResize: true,
      resizable: true,
    },
    {
      dataKey: 'members_count',
      title: 'Member count',
      type: 'number',
      autoResize: true,
      resizable: true,
    },
  ],
}
