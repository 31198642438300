import {
  Button,
  ButtonBlock,
  ContentBlock,
  FieldBlock,
  Font,
  Icon,
  List,
  Sheet,
} from '@politechdev/blocks-design-system'
import { CountySelectField, PdfViewer } from 'components'
import { useTranslation } from 'react-i18next'
import { useDeliveryState } from '../DeliveryContext'
import { getIneligibilityData } from './utils'
import { isEligibleScanForDelivery } from '../utils'
import { FormWithShiftInfo, UpdateFormRequest } from './types'
import { AssembleAnywayAction } from './ResolutionComponents'

type BaseProps = {
  form: FormWithShiftInfo
  setInspectedFormKey:
    | ((key: string | null) => void)
    | ((key: number | null) => void)
}

type ScanInspectProps =
  | (BaseProps & {
      isAssembledForm?: false
      handleUpdateForm: UpdateFormRequest
      handleAssemble: (
        form: FormWithShiftInfo,
        openModal?: boolean
      ) => Promise<void>
    })
  | (BaseProps & {
      isAssembledForm: true
      handleUpdateForm?: unknown
      handleAssemble?: unknown
    })

const ScanInspect = ({
  form,
  handleUpdateForm,
  handleAssemble,
  setInspectedFormKey,
  isAssembledForm,
}: ScanInspectProps) => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const ineligibilityData = getIneligibilityData(currentDelivery, form)

  const handleUpdate = async (county: string) => {
    if (isAssembledForm) return

    const response = await handleUpdateForm(form.id, { county })
    if (response?.registration_form) {
      if (
        isEligibleScanForDelivery(currentDelivery)(response.registration_form)
      ) {
        void handleAssemble(form)
      }
    }
  }

  return (
    <Sheet>
      <ButtonBlock>
        <Button.Secondary onClick={() => setInspectedFormKey(null)}>
          <Icon.ArrowLeft />
          <span>{t('Back')}</span>
        </Button.Secondary>
      </ButtonBlock>
      <List.Item>
        <ContentBlock>
          <Font.Copy>
            {`Scan ${form.scan_number} - ${form.display_name}`}
          </Font.Copy>
          <Font.Copy variant="hint">{form.shift.name}</Font.Copy>
        </ContentBlock>
        {!isAssembledForm && (
          <ContentBlock>
            <Font.Copy>{t(ineligibilityData.type)}</Font.Copy>
            <Font.Copy variant="hint">
              {t(ineligibilityData.reason, { deliveryId: currentDelivery.id })}
            </Font.Copy>
          </ContentBlock>
        )}
        {!isAssembledForm &&
          ineligibilityData.resolutionType === 'assemble' && (
            <AssembleAnywayAction
              assemble={async (openModal: boolean) => {
                await handleAssemble(form, openModal)
                setInspectedFormKey(null)
              }}
            />
          )}
      </List.Item>
      <List.Item>
        <FieldBlock>
          <CountySelectField
            disabled={isAssembledForm}
            onSelect={handleUpdate}
            state={isAssembledForm ? undefined : form.shift.location.state}
            county={form.county}
          />
        </FieldBlock>
      </List.Item>
      <PdfViewer
        file={form.file_url}
        documentLoadingMessage={t('Form loading...')}
      />
    </Sheet>
  )
}

export default ScanInspect
