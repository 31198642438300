export const personFields = {
  fields: [
    'id',
    'prefix',
    'name',
    'suffix_name',
    'position',
    'pronouns',
    'social_links',
    'primary_email_address',
    'deceased',
    'primary_phone_number',
    'receives_sms',
    'external_id',
    {
      residential_address: [
        'line_one',
        'line_two',
        'city',
        'zipcode',
        'state',
        'county',
        'district_config',
      ],
    },
    {
      contact_methods: [
        'id',
        'contact_type',
        'content',
        'extension',
        'invalid',
      ],
    },
  ],
}
