/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import useEvent from 'hooks/useEvent'
import { useReactRouter, useRoutePathParams } from 'hooks/router'
import {
  ViewContainer,
  Uploader,
  Sheet,
  DetailItem,
  CardError,
  View,
  CountySelectFieldDEPRECATED,
} from 'components'
import {
  Font,
  ContentBlock,
  TextBlock,
  FieldBlock,
  Section,
  useToast,
} from '@politechdev/blocks-design-system'
import { dateFormat } from 'utils/constants'
import {
  fetchShift,
  postPetitionPacket,
  postDigitalBatch,
} from 'requests/shifts'
import { defaultTo } from 'lodash'
import styles from './ScanUpload.module.scss'
import { getBatchForAutoSubmission } from './ShiftDataEntry/utils'
import { DataEntryUploadStorage, DataEntryFormStorage } from './storageUtils'
import { SHIFT_TYPE } from './constants'

const ScanUpload = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { setToast } = useToast()

  const [{ id: shiftId, shiftType }] = useRoutePathParams()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: fetchShiftDetails, response: shiftResponse } =
    useRequest(fetchShift, {
      onSuccess: ({ shift: { turf } }) => {
        setHasDataEntryConfig(
          !!turf.voter_registration_config.data_entry_sections?.length
        )
      },
    })

  const currentShift = defaultTo(shiftResponse?.shift, {})

  const [county, setCounty] = useState(null)
  const [hasDataEntryConfig, setHasDataEntryConfig] = useState(false)

  useEffect(() => {
    shiftId &&
      fetchShiftDetails(shiftId, {
        fields: [
          'id',
          'shift_start',
          { canvasser: 'full_name' },
          { turf: ['name', 'voter_registration_config'] },
          { location: 'state' },
        ],
      })
  }, [shiftId])

  const {
    makeRequest: createPetitionPacket,
    hasErrors,
    isLoading,
    errors,
  } = useRequest(
    fileData =>
      postPetitionPacket({
        shift_id: currentShift.id,
        file_locator: fileData,
        county,
      }),
    {
      onSuccess: async () => history.push(`/collect/petitions/shifts`),
    }
  )

  const { makeRequest: submitDigitalBatchRequest } = useRequest(
    postDigitalBatch,
    {
      onSuccess: async () => {
        await DataEntryFormStorage.removeFromStorage(shiftId)
        await DataEntryUploadStorage.removeFromStorage(shiftId)
        history.push(`/collect/voter_registration/shifts`)
      },
      onError: () => {
        setToast({
          message: t(
            'There was a problem submitting the batch. Please try again. If the problem persists, contact support.'
          ),
          variant: 'error',
        })
      },
    }
  )

  const handleSuccesfulUpload = useEvent(async files => {
    if (shiftType === SHIFT_TYPE.REGISTRATION) {
      for (const [i, file] of files.entries()) {
        // eslint-disable-next-line no-await-in-loop
        await DataEntryUploadStorage.addRecordToStorage(currentShift.id, i, {
          ...file.response.body.data,
          url: file.response.body.url,
        })
      }

      if (hasDataEntryConfig) {
        history.push(
          `/collect/voter_registration/shifts/${shiftId}/data_entry/1`
        )
      } else {
        submitDigitalBatchRequest(shiftId, getBatchForAutoSubmission(shiftId))
      }
    } else {
      createPetitionPacket(files[0].response.body.data)
    }
  })

  const errorMessages = Object.entries(errors).map(([errorKey, message]) => {
    if (errorKey === 'file_hash' && message === 'has already been taken') {
      return 'This packet has already been uploaded to Blocks'
    }

    return message
  })

  const { turf, canvasser, shift_start, location } = currentShift
  const isToEarlyForUpload = moment()
    .startOf('day')
    .isBefore(moment(shift_start).startOf('day'))

  const endpoint =
    shiftType === SHIFT_TYPE.REGISTRATION
      ? '/system/files/upload?url=true&metadata_generator=voter_registration_form'
      : '/system/files/upload?metadata_generator=petitions'

  const requiresCounty = shiftType === SHIFT_TYPE.PETITION && !county

  return (
    JSON.stringify(currentShift) !== '{}' && (
      <View loading={false}>
        <div>
          <ViewContainer>
            <Sheet title={t('Upload scans')}>
              <ContentBlock>
                <CardError
                  hide={!hasErrors}
                  hideSupportText={errorMessages.length}
                  message={errorMessages.map(t).join(', ')}
                />
              </ContentBlock>
              <div className="shifts__upload-scans">
                <div className={styles.uploader}>
                  <Uploader
                    label={t('Scan')}
                    onComplete={handleSuccesfulUpload}
                    autoUpload
                    error={hasErrors}
                    fileTypes={['.pdf']}
                    endpoint={endpoint}
                    disabled={isToEarlyForUpload || isLoading || requiresCounty}
                    stringifyData={false}
                    fileValidator={file => {
                      // eslint-disable-next-line no-useless-escape
                      const invalidChars = file.name.match(/[^\w\.]/)

                      if (invalidChars) {
                        return `Filename contains invalid characters: ${invalidChars.join(
                          ', '
                        )}`
                      }
                    }}
                    splitPDFs={shiftType === SHIFT_TYPE.REGISTRATION}
                  />
                  {isToEarlyForUpload ? (
                    <TextBlock className={styles.text}>
                      <Font.Copy muted>
                        {t(
                          'Cannot upload packet because shift date/time has not occurred yet.'
                        )}
                      </Font.Copy>
                    </TextBlock>
                  ) : null}
                </div>
                <div className="shifts__upload-scans__meta">
                  <Section label={t('Shift info')}>
                    <DetailItem label={t('Office of origin')}>
                      {turf?.name}
                    </DetailItem>
                    <DetailItem label={t('Canvasser')}>
                      {canvasser?.full_name}
                    </DetailItem>
                    <DetailItem label={t('Shift date')}>
                      {moment(shift_start).format(dateFormat)}
                    </DetailItem>
                  </Section>
                  {shiftType === SHIFT_TYPE.PETITION ? (
                    <FieldBlock>
                      <CountySelectFieldDEPRECATED
                        required
                        error={requiresCounty}
                        label={t('County')}
                        county={county}
                        onSelect={setCounty}
                        state={location?.state}
                      />
                    </FieldBlock>
                  ) : null}
                </div>
              </div>
            </Sheet>
          </ViewContainer>
        </div>
      </View>
    )
  )
}

export default ScanUpload
