import edgePieceLeft from 'assets/images/decorative/edge-piece--left.svg'
import edgePieceRight from 'assets/images/decorative/edge-piece--right.svg'
import NotFoundImage from 'assets/images/404.jpg'
import classNames from 'classnames/bind'
import {
  Button,
  ButtonBlock,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useRouteQueryParams } from 'hooks'
import styles from './NotFound.module.scss'

const NotFound = () => {
  const { t } = useTranslation()
  const cx = classNames.bind(styles)
  const [{ from }] = useRouteQueryParams()
  return (
    <>
      <img
        className={cx('edge-piece', 'edge-piece--left')}
        src={edgePieceLeft}
        alt=""
      />
      <img
        className={cx('edge-piece', 'edge-piece--right')}
        src={edgePieceRight}
        alt=""
      />
      <div className={styles.container}>
        <img
          className={styles['nf-image']}
          src={NotFoundImage}
          alt="404 page not found."
        />
        <TextBlock>
          <Font.Copy>
            {t('This page was not found in Blocks')}
            {typeof from === 'string' ? `: ${from}` : ''}
          </Font.Copy>
        </TextBlock>
        <TextBlock>
          <Font.Copy>
            {t(
              'This means that either the URL path does not exist or you do not have permission to access it.'
            )}
          </Font.Copy>
        </TextBlock>
        <ButtonBlock>
          <Link to="/">
            <Button.Accent>{t('Back home')}</Button.Accent>
          </Link>
        </ButtonBlock>
      </div>
    </>
  )
}

export default NotFound
