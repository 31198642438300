import { List, ListItem } from 'components'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash/lang'
import { useReactRouter, useRoutePathParams } from 'hooks/router'
import { useCurrentFlagContext } from './contexts/CurrentFlagContext'
import { getFlagPackets } from './utils'

export const PacketList = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [{ packetId: stringPacketId }, replaceRouteParams] =
    useRoutePathParams()
  const packetId = +stringPacketId

  const { currentFlag } = useCurrentFlagContext()
  const packets = getFlagPackets(currentFlag)

  const pushToPacket = newPacketId => {
    if (newPacketId === packetId) return
    const newRoute = replaceRouteParams({ packetId: newPacketId })
    return history.push(newRoute)
  }

  return (
    <List>
      {!isEmpty(packets) &&
        packets.map(p => (
          <ListItem
            key={p.id}
            isActive={p.id === packetId}
            onClick={() => pushToPacket(p.id)}
            primaryText={p.name}
            secondaryText={`${p.forms_count} ${t('total scans')}`}
          />
        ))}
    </List>
  )
}

export default PacketList
