import { useTranslation } from 'react-i18next'
import { Step } from 'components'
import { useRouteMatch } from 'react-router'
import {
  ScannerAssemblyContext,
  ScannerAssemblyProvider,
} from './ScannerAssembly/ScannerAssemblyContext'
import ScannerAssembly from './ScannerAssembly'

const ScanAssemblyStep = ({
  updateDeliveryStatus,
  ...props
}: {
  updateDeliveryStatus: (
    deliveryId: string,
    status: string,
    scanIds?: Array<number | string>
  ) => void
  [k: string]: unknown
}) => {
  const { t } = useTranslation()
  const match = useRouteMatch<{ id: string }>()

  return (
    <ScannerAssemblyProvider
      onNext={() => updateDeliveryStatus(match.params.id, 'review')}
    >
      <ScannerAssemblyContext.Consumer>
        {context => (
          <Step
            label={t('Preparation')}
            nextButtonLabel={t('Complete review')}
            previousButtonLabel={t('Go back to selection')}
            onNext={context?.validateAndTransitionDelivery}
            onPrevious={() =>
              updateDeliveryStatus(match.params.id, 'select', [])
            }
            {...props}
          >
            <ScannerAssembly />
          </Step>
        )}
      </ScannerAssemblyContext.Consumer>
    </ScannerAssemblyProvider>
  )
}

export default ScanAssemblyStep
