import {
  basePartyLeanColumns,
  partyLeanDataDictionaryUrl,
} from 'registrationReports/reportsConfig/columns/basePartyLeanColumns'

export default {
  id: 'party_lean_by_location',
  name: 'Party Lean',
  permissionId: 'party_lean_report',
  description:
    'This report provides information on the party lean values of registrants. It summarizes this information by the canvassing location.',
  dataDictionaryUrl: partyLeanDataDictionaryUrl,
  columns: [
    {
      dataKey: 'location_name',
      title: 'Location Name',
      type: 'string',
      resizable: true,
    },
    ...basePartyLeanColumns,
  ],
}
