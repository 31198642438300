import moment from 'moment'
import {
  buildDefaultFilters,
  calculatePercent,
  combineJsonPairs,
  combineName,
  formatDeliveryStatus,
  formatQcStatus,
  secondsToDays,
  summaryFunctions,
} from 'utils/reporting'

export default {
  id: 'packet',
  name: 'Packet Report',
  permissionId: 'registration_general',
  description:
    'This report provides summary information on packets from shift creation through form delivery. The "Packet Information" tab shows data from the cover sheet and shift creation. The "Visual QC" tab shows data on the visual QC process, including the number of forms identified with phone numbers during visual QC. The "Phone Verification" tab shows data on the phone QC process, including if the packet passed 25% phone call verification or if three passes were completed. The final tab provides the date of data entry when data comes back from Captricity.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=0',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'shift_id',
      title: 'Shift ID',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'canvasser_name',
      title: 'Canvasser Name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.canvasser_first_name, rowData.canvasser_last_name),
    },
    {
      dataKey: 'location_name',
      title: 'Location Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'packet_name',
      title: 'Packet Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'current_assignee',
      title: 'Current Assignee',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.assignee_first_name, rowData.assignee_last_name),
    },
    {
      dataKey: 'upload_date',
      title: 'Upload Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'collection_date',
      title: 'Collection Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'total_scans',
      title: 'Total Scans',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'shifts_ready_for_phone_verification_at',
      title: 'Ready for phone verification date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'shifts_completed_at',
      title: 'QC completed date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'turnaround_time',
      title: 'QC Turnaround Time (Days)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => secondsToDays(rowData.turnaround_time),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'days_since_collection',
      title: 'Days Since Collection',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        moment().diff(moment(rowData.collection_date), 'days'),
    },
    {
      dataKey: 'status',
      title: 'QC Status',
      type: 'string',
      deriveFunction: ({ rowData }) => formatQcStatus(rowData.status),
      resizable: true,
    },
    {
      dataKey: 'delivered',
      title: 'Delivery Status',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) => formatDeliveryStatus(rowData.delivered),
    },
    {
      dataKey: 'qc_stopped',
      title: 'QC Stopped',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_scans_cover',
      title: 'Total Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'scans_with_phones_cover',
      title: 'Forms with Phones (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'verification_status',
      title: 'Verification Status',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) => {
        const verified =
          rowData.verified_calls_percent || rowData.verified_calls_rounds

        switch (verified) {
          case true: {
            return 'Verified'
          }
          default: {
            return 'Not verified'
          }
        }
      },
    },
    {
      dataKey: 'percent_verified',
      title: 'Percent Verified',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc || 0,
          rowData.total_scans_qc || 0
        ),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'visual_qc_staffers',
      title: 'Visual QC Staffers',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineJsonPairs(
          rowData.visual_qc_staffers,
          'first_name',
          'last_name',
          ' ',
          ', '
        ),
    },
    {
      dataKey: 'visual_qc_dates',
      title: 'Visual QC Dates',
      type: 'date',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.visual_qc_dates ? rowData.visual_qc_dates.join(', ') : null,
    },
    {
      dataKey: 'total_scans_qc',
      title: 'Total Forms Through QC',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'scans_with_phones_qc',
      title: 'Forms with Phones (QC)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'phone_qc_staffers',
      title: 'Phone QC Staffers',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineJsonPairs(
          rowData.phone_qc_staffers,
          'first_name',
          'last_name',
          ' ',
          ', '
        ),
    },
    {
      dataKey: 'phone_qc_dates',
      title: 'Phone QC Dates',
      type: 'date',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.phone_qc_dates ? rowData.phone_qc_dates.join(', ') : null,
    },
    {
      dataKey: 'total_scans_called_qc',
      title: 'Total Forms Called',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'rounds_called_qc',
      title: 'Rounds Called',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'forms_created_at',
      title: 'Data Entry Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'shift_notes',
      title: 'Shift Notes',
      type: 'string',
      resizable: true,
      csvCell: ({ shift_notes }) => (shift_notes || '').replace(/\n/g, ' '),
    },
    {
      dataKey: 'percent_disconnected_qc',
      title: 'Percent Disconnected (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.disconnected_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_wrong_number_qc',
      title: 'Percent Wrong Number (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.wrong_number_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_confirmed_registered_qc',
      title: 'Percent Confirmed Registration on Phone (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.confirmed_registered_qc,
          rowData.total_scans_called_qc
        ),
    },
    {
      dataKey: 'percent_complete_scans_qc',
      title: 'Complete Form Percent (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.complete_scans_qc, rowData.total_scans_qc),
      summaryFunction: summaryFunctions.percent(
        'complete_scans_qc',
        'total_scans_qc'
      ),
    },
    {
      dataKey: 'percent_with_phones_qc',
      title: 'Percent with Phones (QC)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(rowData.scans_with_phones_qc, rowData.total_scans_qc),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'scans_with_phones_qc',
          'total_scans_qc',
        ]),
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Packet Information',
        visibleColumns: [
          'turf_name',
          'canvasser_name',
          'packet_name',
          'upload_date',
          'collection_date',
          'status',
          'delivered',
          'total_scans_cover',
          'total_scans_qc',
          'shift_notes',
        ],
      },
      {
        title: 'Verification Status',
        visibleColumns: [
          'turf_name',
          'canvasser_name',
          'packet_name',
          'verification_status',
          'total_scans_called_qc',
        ],
      },
      {
        title: 'Visual QC',
        visibleColumns: [
          'turf_name',
          'canvasser_name',
          'packet_name',
          'visual_qc_staffers',
          'visual_qc_dates',
          'scans_with_phones_cover',
          'upload_date',
          'shift_notes',
          'total_scans_cover',
        ],
      },
      {
        title: 'Phone Verification',
        visibleColumns: [
          'turf_name',
          'canvasser_name',
          'packet_name',
          'phone_qc_staffers',
          'phone_qc_dates',
          'total_scans_called_qc',
          'scans_with_phones_cover',
          'verification_status',
        ],
      },
      {
        title: 'Data Entry & Delivered',
        visibleColumns: [
          'turf_name',
          'canvasser_name',
          'packet_name',
          'forms_created_at',
        ],
      },
      {
        title: 'Packet Timeline',
        visibleColumns: [
          'turf_name',
          'shift_id',
          'packet_name',
          'collection_date',
          'upload_date',
          'turnaround_time',
          'qc_stopped',
          'visual_qc_dates',
          'phone_qc_dates',
          'forms_created_at',
        ],
      },
      {
        title: 'Packets Completed QC',
        visibleColumns: [
          'turf_name',
          'canvasser_name',
          'packet_name',
          'collection_date',
        ],
        rules: [
          {
            column: 'status',
            operator: 'containing',
            param: 'completed',
            id: 'statuscontainingcompleted',
          },
        ],
      },
    ])
  },
}
