import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchInspectedUser = (id, params) =>
  fetchJSON(`/api/v1/users/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchPacket = (id, params) =>
  fetchJSON(`/api/v1/shifts/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const patchTurfPacketsDeadline = (turfId, deadline) =>
  fetchJSON(
    `/api/v1/turfs/${turfId}/shifts/reprioritize`,
    'PATCH',
    {
      new_deadline: deadline,
    },
    {
      useJwt: true,
    }
  )

export const autoAssignPacketsToUsers = (shiftIds = [], userIds = []) =>
  fetchJSON(
    '/api/v1/shifts/auto_assign',
    'POST',
    {
      options: {
        shift_ids: shiftIds,
        user_ids: userIds,
      },
    },
    {
      useJwt: true,
    }
  )

export const putPacket = (id, shift) =>
  fetchJSON(
    `/api/v1/shifts/${id}`,
    'PUT',
    { shift },
    {
      useJwt: true,
    }
  )

export const putPackets = async packets => {
  const requests = packets.map(({ id, ...packetAttributes }) =>
    putPacket(id, packetAttributes)
  )

  await Promise.all(requests)
}
