import {
  FieldBlock,
  Sheet,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import styles from './ReportPicker.module.scss'

const ReportsPicker = ({ reportType, reportTypes, changeReportType }) => {
  const { doesCurrentUserHavePermission } = useCurrent()

  return (
    <Sheet>
      <FieldBlock>
        <SelectField
          id="report-type"
          className={styles.select}
          label="Report"
          options={reportTypes
            .filter(type =>
              doesCurrentUserHavePermission({
                resource: type.permissionId,
                ability: 'view',
              })
            )
            .map(type => ({
              value: type.id,
              label: type.name,
            }))}
          deleteKeys={['columns']}
          value={reportType}
          onSelect={changeReportType}
        />
      </FieldBlock>
    </Sheet>
  )
}

export default ReportsPicker
