import moment from 'moment-timezone'
import { defaultTo, get } from 'lodash'
import {
  packetStatus,
  getPacketTimeZoneName,
  getPacketTimeZoneOffset,
} from 'utils/qualityControl'
import humanizeString from 'humanize-string'

export const getDaysLeft = packet => {
  const tz = getPacketTimeZoneName(packet)
  const daysLeft = moment
    .utc(packet.qc_deadline)
    .tz(tz)
    .startOf('day')
    .diff(moment.tz(tz).startOf('day'), 'days')
  if (Number.isNaN(daysLeft)) return 'No due date'
  if (daysLeft < 0) {
    return daysLeft === -1 ? '1 day overdue' : `${daysLeft * -1} days overdue`
  }
  if (daysLeft === 1) return '1 day left'
  return `${daysLeft} days left`
}

export const getScanFormCount = packet => `${packet.forms_count} forms`

export const getScanPhoneCount = shift =>
  `${shift.forms_with_phone_count} with phone`

export const getPacketLanguage = ({ turf }) =>
  turf.phone_verification_language === 'en' ? 'EN' : 'ES'

export const getScanUSState = packet => get(packet, 'location.state')

export const getPacketTimeZoneLabel = packet => {
  const name = getPacketTimeZoneName(packet)
  const offset = getPacketTimeZoneOffset(packet)
  const state = getScanUSState(packet)

  if (name) return `${name} (${state})`
  return `UTC ${offset} (${state})`
}

export const getScanLabel = packet =>
  `${getScanFormCount(packet)} - ${getDaysLeft(packet)} - ${packetStatus(
    packet
  ).replace(/_/g, ' ')} (${getScanUSState(packet)})`

export const generateCategoriesForVRIssues = (options, option, index) => {
  const groupingMeta = defaultTo(option.grouping_metadata, {})
  const category = groupingMeta.category
    ? {
        label: groupingMeta.category,
        position: index,
      }
    : {
        label: 'Other concern',
        position: 100,
      }
  const subcategory = groupingMeta.subcategory
    ? {
        label: groupingMeta.subcategory,
        position: index,
        exists: true,
      }
    : { exists: false }

  const currentCategoryOption = options[category.label]

  if (!currentCategoryOption) {
    options[category.label] = {
      id: category.label,
      label: humanizeString(category.label),
      position: category.position,
      children: [],
      subcategories: {},
    }
  } else {
    const currentSubcategoryOption =
      currentCategoryOption.subcategories[subcategory.label]

    if (subcategory.exists && !currentSubcategoryOption) {
      options[category.label].subcategories[subcategory.label] = {
        id: subcategory.label,
        label: humanizeString(subcategory.label),
        position: subcategory.position,
        children: [],
      }
    }
  }

  if (
    subcategory.exists &&
    options[category.label].subcategories[subcategory.label]
  ) {
    options[category.label].subcategories[subcategory.label].children.push({
      ...option,
      position: groupingMeta.position,
    })
  } else {
    options[category.label].children.push({
      ...option,
      position: groupingMeta.position,
    })
  }

  return options
}
