import { useTranslation } from 'react-i18next'
import { useReactRouter, useRequest, useRoutePathParams } from 'hooks'
import { CardError } from 'components'
import {
  ButtonBlock,
  Button,
  TextBlock,
  useToast,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { advanceFlagStatus, getNextReviewableFlagId } from 'requests/flags'
import {
  canFlagBeAdvanced,
  getReadyButtonSubText,
  getReadyButtonText,
  isFlagTechnical,
} from './utils'
import DeleteFlagButton from './buttons/DeleteFlagButton'
import { usePacketAssignment } from '../packetAssignment/PacketAssignmentContext'
import { useCurrentFlagContext } from './contexts/CurrentFlagContext'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'

const FlagSingleReadyButton = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const [, replaceParams] = useRoutePathParams()
  const { setToast } = useToast()
  const { type, originalType } = match.params

  const { currentTurfPerformsExternalQC } = useCurrent()
  const { unassignPacket } = usePacketAssignment()

  const { currentFlag, hasDuplicates } = useCurrentFlagContext()
  const { filters } = useFlagsFilteredListContext()

  const needsReupload = currentFlag.trigger.needs_reupload
  const isComplete = currentFlag.status === 'complete'

  const advanceFlagRequest = useRequest(
    async (flagId, flagAction) => advanceFlagStatus(flagId, flagAction),
    {
      onSuccess: async () => {
        const text =
          type === 'reopen'
            ? 'Flag has been restored'
            : 'Flag has been advanced'

        const nextId = await getNextReviewableFlagId(
          filters,
          currentFlag.id,
          type,
          currentTurfPerformsExternalQC
        )

        setToast({ message: t(text), variant: 'success' })

        nextId
          ? history.push(replaceParams({ flagId: nextId }))
          : history.push(`/quality_control/flags/${originalType}`)
      },
      onError: () => {
        setToast({
          message: t('Failed to advance flag'),
          variant: 'error',
        })
      },
    }
  )

  const duplicatesPresent = isComplete && hasDuplicates

  if (needsReupload && isComplete) {
    return null
  }

  return (
    <div>
      <ProgressBar show={advanceFlagRequest.isLoading} />
      <CardError
        hide={!advanceFlagRequest.hasErrors}
        message={formatErrorMessage(advanceFlagRequest.errors)}
      />
      <div>
        <ButtonBlock>
          <Button.Accent
            onClick={() => advanceFlagRequest.makeRequest(currentFlag.id, type)}
            disabled={
              !canFlagBeAdvanced(currentFlag, type, needsReupload) ||
              duplicatesPresent
            }
          >
            {t(getReadyButtonText(type))}
          </Button.Accent>
          {currentFlag.status === 'reviewed' && (
            <Button.Secondary
              onClick={() =>
                advanceFlagRequest.makeRequest(currentFlag.id, 'reopen')
              }
              disabled={
                !canFlagBeAdvanced(currentFlag, 'reopen', needsReupload) ||
                duplicatesPresent
              }
            >
              {t(getReadyButtonText('reopen'))}
            </Button.Secondary>
          )}
          {type !== 'review' && (
            <DeleteFlagButton
              onDelete={async () => {
                if (isFlagTechnical(currentFlag)) {
                  await unassignPacket(currentFlag.shift)
                }
                history.push('/quality_control/flags/start')
              }}
              flag={currentFlag}
            />
          )}
        </ButtonBlock>
        {!duplicatesPresent && canFlagBeAdvanced(currentFlag, type) && (
          <TextBlock>{t(getReadyButtonSubText(type))}</TextBlock>
        )}
      </div>
    </div>
  )
}

export default FlagSingleReadyButton
