import countiesByState from 'utils/counties.json'
import { uniqueCounties } from 'utils/constants'

export const buildOption = county => ({
  value: county,
  label: county,
})

export function getAsNormalizedIfValidCountyValue(possibleCounty, states = []) {
  if (!possibleCounty) return

  const dedupedCounties = states.length
    ? Array.from(new Set(states.flatMap(state => countiesByState[state] ?? [])))
    : uniqueCounties

  return dedupedCounties.find(
    county => county.toLowerCase() === possibleCounty.toLowerCase()
  )
}
