import { useEffect, useState } from 'react'
import {
  FieldBlock,
  TextField,
  SelectField,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useCurrent, useFilters } from 'contexts'
import { debounce } from 'lodash'
import { QC_OFFICE_OPTIONS } from 'users/constants'
import { useRequest } from 'hooks'
import { fetchSchedulesOnDate } from 'requests/qualityControl'
import { currentDateEasternYMDDash } from 'utils/dateTime'

const AssignmentTableFilters = () => {
  const { t } = useTranslation()
  const { filterRules, updateFilterRule, removeFilterRule } = useFilters()
  const { currentTurfPerformsExternalQC } = useCurrent()

  const [userOptions, setUserOptions] = useState([])

  // eslint-disable-next-line blocks/missing-response-error
  const fetchScheduledUsers = useRequest(fetchSchedulesOnDate, {
    onSuccess: ({ schedules }) =>
      setUserOptions(
        schedules
          .filter(
            ({ turf }) =>
              turf.qc_config.performs_external_qc ===
              currentTurfPerformsExternalQC
          )
          .map(({ user }) => ({ value: user.name, label: user.name }))
      ),
  })

  useEffect(() => {
    fetchScheduledUsers.makeRequest(currentDateEasternYMDDash, {
      fields: [
        {
          user: ['name'],
        },
        { turf: ['qc_config'] },
      ],
    })
  }, [])

  const toggleOnlyUnassignedCheckbox = value => {
    if (value) {
      updateFilterRule({
        id: 'unassigned',
        column: 'assignee',
        operator: 'is_nil',
      })
    } else {
      removeFilterRule('unassigned')
    }
  }

  const updatePacketNameFilter = term => {
    if (term) {
      updateFilterRule({
        id: 'name',
        column: 'name',
        operator: 'containing',
        param: term,
      })
    } else {
      removeFilterRule('packet_name')
    }
  }

  const updateQCOfficeFilter = term => {
    if (term) {
      updateFilterRule({
        id: 'qc_office',
        column: 'assignee_qc_office',
        operator: 'is',
        param: term,
      })
    } else {
      removeFilterRule('qc_office')
    }
  }

  const updateStaffName = term => {
    if (term) {
      updateFilterRule({
        id: 'staff_member',
        column: 'assignee_full_name',
        operator: 'containing',
        param: term,
      })
    } else {
      removeFilterRule('staff_member')
    }
  }

  const staffName = filterRules.find(f => f.id === 'staff_member')?.param || ''

  const qcOfficeOption =
    filterRules.find(f => f.id === 'qc_office')?.param || ''

  const isOnlyUnassignedChecked =
    filterRules.find(f => f.id === 'unassigned') || false

  return (
    <div>
      <FieldBlock>
        <TextField
          id="packet-name-search"
          type="search"
          onChange={debounce(updatePacketNameFilter, 300)}
          label={t('Packet name')}
        />
        <SelectField
          loading={fetchScheduledUsers.isLoading}
          id="user"
          label={t('Staff member')}
          value={staffName}
          options={userOptions}
          onSelect={debounce(updateStaffName, 300)}
          key={userOptions.id}
          clearable
          onClear={updateStaffName}
        />
        <SelectField
          id="packet-assignment-qc-office-filter"
          label={t('QC Office')}
          options={QC_OFFICE_OPTIONS}
          value={qcOfficeOption}
          onSelect={debounce(updateQCOfficeFilter, 300)}
          clearable
          onClear={updateQCOfficeFilter}
        />
      </FieldBlock>
      <FieldBlock variant="medium">
        <Checkbox
          id="unassigned-packets-checkbox"
          name="unassigned-packets-checkbox"
          label={t('Only display packets that are unassigned')}
          onChange={toggleOnlyUnassignedCheckbox}
          checked={isOnlyUnassignedChecked}
        />
      </FieldBlock>
    </div>
  )
}

export default AssignmentTableFilters
