import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import {
  ZipCodeField,
  CardError,
  CountySelectField,
  TurfSelectField,
} from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  SelectField,
  TextField,
  ProgressBar,
  Checkbox,
  NumberField,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import {
  acceptablePrecisionValues,
  fetchAddressOptions,
} from 'components/AddressValidator/smartyStreetsApiHelpers'
import { LOCATION_TYPES } from 'constants/locations'
import { postLocation, putLocation } from 'requests/locations'
import { buildForm } from './utils'

const EventLocationForm = ({ location: eventLocation }) => {
  const isEditForm = !!eventLocation
  const formAction = isEditForm
    ? formData => putLocation(eventLocation.id, formData)
    : postLocation
  const { t } = useTranslation()
  const { history, location } = useReactRouter()
  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTenantStateOptions,
    currentTurfLocationTurfable: locationTurfable,
  } = useCurrent()
  const [missingAddress, setMissingAddress] = useState(false)
  const [duplicateAddress, setDuplicateAddress] = useState(false)

  const { formData, setField, setFormData } = useForm()
  const { makeRequest, isLoading, hasErrors, isRequestComplete, response } =
    useRequest(formAction, {
      onError: e => {
        if (e.json.error.delivery_point_barcode) {
          setDuplicateAddress(true)
        }
      },
    })

  const {
    makeRequest: addressCheck,
    isLoading: addressCheckIsLoading,
    hasErrors: addressCheckHasErrors,
  } = useRequest(fetchAddressOptions, {
    onSuccess: ({ candidates }) => {
      if (
        candidates.length > 0 &&
        candidates[0].metadata.latitude &&
        candidates[0].metadata.longitude &&
        candidates[0].delivery_point_barcode &&
        acceptablePrecisionValues.includes(candidates[0].metadata.precision)
      ) {
        makeRequest({
          ...formData,
          latitude: candidates[0].metadata.latitude,
          longitude: candidates[0].metadata.longitude,
          location_type: LOCATION_TYPES.venue.value,
          turf_id: formData.turf_id || turfId,
          delivery_point_barcode: candidates[0].delivery_point_barcode,
        })
      } else {
        setMissingAddress(true)
      }
    },
  })

  useEffect(() => {
    eventLocation && setFormData(buildForm(eventLocation))
  }, [])

  useEffect(() => {
    if (hasErrors || !isRequestComplete) return
    history.push(location.state?.returnPath || '/organize/event_locations')
  }, [isRequestComplete, hasErrors, response])

  const isFormValid = () =>
    !isEmpty(formData.name) &&
    !isEmpty(formData.street_address) &&
    !isEmpty(formData.city) &&
    !isEmpty(formData.state) &&
    !isEmpty(formData.zipcode) &&
    !isEmpty(formData.county) &&
    (locationTurfable ? !!formData.turf_id : true)

  const submitForm = e => {
    e.preventDefault()
    setMissingAddress(false)
    setDuplicateAddress(false)
    addressCheck({
      fields: {
        street: formData.street_address,
        city: formData.city,
        state: formData.state,
        zipcode: formData.zipcode,
      },
      match: 'invalid',
    })
  }

  return (
    <>
      <ProgressBar show={isLoading || addressCheckIsLoading} />
      <CardError
        hide={
          !hasErrors &&
          !missingAddress &&
          !duplicateAddress &&
          !addressCheckHasErrors
        }
        hideSupportLink
        message={
          (duplicateAddress && 'Duplicate address') ||
          ((hasErrors || addressCheckHasErrors) && 'Error creating location') ||
          (missingAddress && 'Could not verify address')
        }
      />
      <form onSubmit={submitForm}>
        <FieldBlock>
          <TextField
            id="name"
            label={t('Name')}
            value={formData.name}
            onChange={val => setField(val, 'name')}
            required
          />
          <TextField
            id="street_address"
            label={t('Street Address')}
            value={formData.street_address}
            onChange={val => setField(val, 'street_address')}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="city"
            label={t('City')}
            value={formData.city}
            onChange={val => setField(val, 'city')}
            required
          />
          <SelectField
            id="state"
            label={t('State')}
            options={currentTenantStateOptions}
            value={formData.state}
            onSelect={val => {
              if (val !== formData.state) {
                setField('', 'county')
              }
              setField(val, 'state')
            }}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <ZipCodeField
            id="zipcode"
            label={t('Zip Code')}
            value={formData.zipcode}
            onChange={val => setField(val, 'zipcode')}
            required
          />
          <CountySelectField
            state={formData.state}
            county={formData.county}
            onSelect={val => setField(val, 'county')}
            required
          />
        </FieldBlock>
        {locationTurfable && (
          <FieldBlock>
            <TurfSelectField
              id="turf_id"
              label={t('Turf')}
              value={formData.turf_id}
              onSelect={val => setField(val, 'turf_id')}
              required
              showArchived={isEditForm}
              disableArchived
            />
          </FieldBlock>
        )}
        <FieldBlock>
          <Checkbox
            id="hosted-event"
            name="hosted-event"
            label={t('Hosted an event?')}
            checked={formData.has_hosted_event}
            onChange={val => setField(val, 'has_hosted_event')}
          />
          <Checkbox
            id="support"
            name="support"
            label={t('IT Support')}
            checked={formData.has_it_support}
            onChange={val => setField(val, 'has_it_support')}
          />
          <Checkbox
            id="wifi"
            name="wifi"
            label={t('Has Wifi')}
            checked={formData.has_wifi}
            onChange={val => setField(val, 'has_wifi')}
          />
        </FieldBlock>
        <FieldBlock>
          <Checkbox
            id="av"
            name="av"
            label={t('Has A/V')}
            checked={formData.has_av}
            onChange={val => setField(val, 'has_av')}
          />
          <Checkbox
            id="projector"
            name="projector"
            label={t('Has Projector')}
            checked={formData.has_projector}
            onChange={val => setField(val, 'has_projector')}
          />
          <Checkbox
            id="public-transportation"
            name="public-transportation"
            label={t('Public Transportation')}
            checked={formData.has_public_transportation}
            onChange={val => setField(val, 'has_public_transportation')}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="contact-person"
            label={t('Specific contact person')}
            value={formData.contact_name}
            onChange={val => setField(val, 'contact_name')}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="contact-number"
            label={t('Contact phone number')}
            value={formData.contact_phone}
            onChange={val => setField(val, 'contact_phone')}
            type="tel"
          />
          <TextField
            id="contact-email"
            label={t('Contact e-mail')}
            value={formData.contact_email}
            onChange={val => setField(val, 'contact_email')}
            type="email"
          />
        </FieldBlock>
        <FieldBlock>
          <NumberField
            id="largest-size"
            label={t('Largest size')}
            value={formData.largest_turnout}
            onChange={val => setField(val, 'largest_turnout')}
          />
          <NumberField
            id="maximum-size"
            label={t('Maximum size')}
            value={formData.maximum_occupancy}
            onChange={val => setField(val, 'maximum_occupancy')}
          />
        </FieldBlock>
        <FieldBlock>
          <NumberField
            id="parking-available"
            label={t('Number of available parking spots')}
            value={formData.parking_spots_available}
            onChange={val => setField(val, 'parking_spots_available')}
          />
          <NumberField
            id="rooms-available"
            label={t('Rooms available')}
            value={formData.rooms_available}
            onChange={val => setField(val, 'rooms_available')}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="notes"
            label={t('Notes')}
            value={formData.notes}
            onChange={val => setField(val, 'notes')}
          />
        </FieldBlock>
        <ButtonBlock>
          <Button.Accent
            aria-label={t(isEditForm ? 'Save Location' : 'Create Location')}
            type="submit"
            disabled={!isFormValid() || isLoading}
          >
            {t(isEditForm ? 'Save Location' : 'Create Location')}
          </Button.Accent>
        </ButtonBlock>
      </form>
    </>
  )
}

export default EventLocationForm
