import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useCurrent, QueryParamProvider } from 'contexts'
import { View, ViewContainer } from 'components'
import { TextBlock } from '@politechdev/blocks-design-system'
import DashboardFilters from '../DashboardFilters/DashboardFilters'
import DashboardWidgets from '../DashboardWidgets/DashboardWidgets'
import DashboardFilterProvider from '../DashboardFilterContext/DashboardFilterContext'

const Dashboard = () => {
  const { t } = useTranslation()

  const {
    currentUser: {
      role: {
        dashboard_layout: {
          content: { widgets },
        },
      },
    },
  } = useCurrent()

  if (isEmpty(widgets)) {
    return (
      <View>
        <ViewContainer>
          <TextBlock>
            {t(
              'Your dashboard has not been set yet. Please contact your administrator to set it up for you'
            )}
          </TextBlock>
        </ViewContainer>
      </View>
    )
  }

  return (
    <View>
      <ViewContainer>
        <DashboardFilterProvider>
          <QueryParamProvider>
            <DashboardFilters />
          </QueryParamProvider>
          <DashboardWidgets />
        </DashboardFilterProvider>
      </ViewContainer>
    </View>
  )
}

export default Dashboard
