const usePacketDetail = (
  currentPacket,
  eligibleFormIds,
  excludedFormIds,
  updateExcluded,
  deliveryId
) => {
  const forms =
    currentPacket.forms?.filter(
      form =>
        !form.visual_reviews.some(
          review => review.response.implies_not_form === true
        )
    ) ?? []
  const currentFormIds = forms.map(form => form.id)
  const allFormsExcluded = currentFormIds.every(formId =>
    excludedFormIds.includes(formId)
  )
  const eligibleForms = forms.filter(({ id }) => eligibleFormIds.includes(id))
  const ineligibleForms = forms.filter(
    form => !eligibleFormIds.includes(form.id)
  )

  const excludeForm = formId => {
    const newExcludedIds = [...excludedFormIds, formId]
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  const excludeAllForms = () => {
    const newExcludedIds = [...excludedFormIds, ...currentFormIds]
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  const includeForm = formId => {
    const newExcludedIds = excludedFormIds.filter(id => id !== formId)
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  const includeAllForms = () => {
    const newExcludedIds = excludedFormIds.filter(
      id => !currentFormIds.includes(id)
    )
    updateExcluded(deliveryId, {
      excluded_form_ids: newExcludedIds,
    })
  }
  return {
    allFormsExcluded,
    eligibleForms,
    ineligibleForms,
    excludeForm,
    excludeAllForms,
    includeForm,
    includeAllForms,
  }
}

export default usePacketDetail
