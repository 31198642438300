import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { Font } from '@politechdev/blocks-design-system'
import { useLocationMap } from '../LocationMapProvider/LocationMapProvider'
import LocationMapSidebarItem from '../LocationMapSidebarItem/LocationMapSidebarItem'
import LocationMapPagination from '../LocationMapPagination/LocationMapPagination'
import styles from './LocationMapSidebar.module.scss'

const cx = classNames.bind(styles)

const LocationMapSidebar = () => {
  const { t } = useTranslation()
  const {
    activeLocationRef,
    activeLocationVisible,
    locations,
    totalLocations,
    isLoading,
  } = useLocationMap()

  const sidebarRef = useRef()

  useEffect(() => {
    if (isLoading && sidebarRef.current) {
      sidebarRef.current.scrollTo({
        top: 0,
      })
    }
  }, [isLoading])

  useEffect(() => {
    if (
      sidebarRef.current &&
      activeLocationRef.current &&
      !activeLocationVisible
    ) {
      sidebarRef.current.scrollTo({
        top: activeLocationRef.current.offsetTop - sidebarRef.current.offsetTop,
      })
      activeLocationRef.current = null
    }
  }, [sidebarRef.current, activeLocationRef.current, activeLocationVisible])

  return (
    <div className={styles.sidebar}>
      {totalLocations > 0 && (
        <>
          <div
            ref={sidebarRef}
            className={cx('locations', {
              'locations--loading': isLoading,
            })}
          >
            {locations.map(location => (
              <LocationMapSidebarItem key={location.id} location={location} />
            ))}
          </div>
          <LocationMapPagination />
        </>
      )}
      {isLoading && totalLocations === 0 && (
        <div className={styles.message}>
          <h2>{t('Loading locations')}</h2>
        </div>
      )}
      {!isLoading && totalLocations === 0 && (
        <div className={styles.message}>
          <Font.Copy variant="highlight">{t('No locations found')}</Font.Copy>
        </div>
      )}
    </div>
  )
}

export default LocationMapSidebar
