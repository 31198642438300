import { formatDecimalCell } from 'utils/reporting'
import canvassersConfig from './canvasserReports'
import locationsConfig from './locationReports'
import turfsConfig from './turfReports'
import verificationConfig from './verificationReports'
import resourcesConfig from './resourceReports'
import { columnSummaryFunctions } from './columnTotals'
import userReports from './userReports'

export const prepareReportTypes = reportConfigs =>
  reportConfigs.map(reportConfig => ({
    ...reportConfig,
    columns: reportConfig.columns.map(column => ({
      ...column,
      bodyCell:
        column.type === 'number' && !column.bodyCell
          ? formatDecimalCell
          : column.bodyCell,
      summaryFunction:
        column.summaryFunction || columnSummaryFunctions[column.dataKey],
    })),
  }))

export const prepareReports = reportCategory => {
  const categoryConfigs = [
    canvassersConfig,
    turfsConfig,
    locationsConfig,
    userReports,
    verificationConfig,
    resourcesConfig,
  ]

  const config = categoryConfigs.find(
    categoryConfig => categoryConfig.reportCategory === reportCategory
  )

  return {
    categoryTitle: config.categoryTitle,
    reportTypes: prepareReportTypes(config.reportConfigs),
  }
}
