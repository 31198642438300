import { flattenDeep, orderBy } from 'lodash'
import { formatDisplayName } from 'utils/formatting'

export const getFormattedFeedback = canvasser =>
  orderBy(
    flattenDeep(
      canvasser.shifts.map(shift =>
        shift.forms
          .map(scan => ({
            feedback: scan.phone_verification_responses.find(response =>
              response.response.includes('feedback')
            )?.response,
            content: scan.phone_verification_responses.find(
              response => response.notes
            )?.notes,
            author: scan.phone_verification_responses.find(
              response => response.caller
            )?.caller,
            created_at: scan.phone_verification_responses.find(
              response => response.created_at
            )?.created_at,
          }))
          .filter(
            feedbackObj =>
              feedbackObj.feedback && feedbackObj.feedback !== 'no_feedback'
          )
          .map(feedbackObj => ({
            ...feedbackObj,
            author: formatDisplayName(feedbackObj.author),
          }))
      )
    ),
    ['created_at'],
    ['desc']
  )
