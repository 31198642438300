import baseQcSummaryColumns from 'registrationReports/reportsConfig/columns/baseQcSummaryColumns'

export default {
  id: 'qc_summary_by_user',
  name: 'Individual QC Staff Work',
  permissionId: 'qc_workflow',
  description:
    'This report provides information on quality control shifts, totals and rates for individual QC staff members.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1486695993',
  columns: [
    {
      dataKey: 'user_id',
      title: 'Blocks user ID',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'staff_name',
      title: 'Staff name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        [rowData.first_name, rowData.last_name].join(' '),
    },
    ...baseQcSummaryColumns,
  ],
}
