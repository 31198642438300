import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useRequest } from 'hooks'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  TextField,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { putForm } from 'requests/registrationForms'
import styles from './LookupCodeInput.module.scss'

const cx = classNames.bind(styles)

type Props = {
  currentScan: {
    id: number
    lookup_code: string
  }
  hidden?: boolean
}

export const LookupCodeInput: React.FC<Props> = ({
  currentScan: { id, lookup_code },
  hidden = false,
}) => {
  const { t } = useTranslation()

  const [lookupCode, setLookupCode] = useState<string>()
  const [savedCode, setSavedCode] = useState(false)

  const { makeRequest, clearRequest, isLoading, hasErrors, errors } =
    useRequest(putForm, {
      onSuccess: () => {
        setSavedCode(true)
      },
    })
  const saveCode = () => makeRequest(id, { lookup_code: lookupCode })

  useEffect(() => {
    setLookupCode(lookup_code)
    setSavedCode(!!lookup_code)
    clearRequest()
  }, [id])

  if (hidden) return null

  return (
    <div className={cx('lookup')}>
      <FieldBlock className={cx('lookup__field')}>
        <TextField
          label={t('Lookup code')}
          value={lookupCode ?? ''}
          onChange={value => {
            setLookupCode(value as string)
            setSavedCode(false)
          }}
          loading={isLoading}
          icon={(hasErrors && 'CircleX') || (savedCode && 'Check')}
          error={hasErrors}
          errorMessage={
            errors.lookup_code === 'has already been taken'
              ? 'Code has already been taken.'
              : 'Something went wrong.'
          }
        />
      </FieldBlock>
      <ButtonBlock>
        <Button.Secondary onClick={saveCode}>Update</Button.Secondary>
      </ButtonBlock>
    </div>
  )
}
