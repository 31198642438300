import { useState } from 'react'
import {
  List,
  ListItem,
  SectionLabel,
  CardError,
  PersonSelectField,
  ResponsibilitySelectField,
} from 'components'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import ShiftDeleteWarningModal from '../ShiftDeleteWarningModal/ShiftDeleteWarningModal'

const VolunteerAssignment = ({
  assignmentLabel,
  volunteers,
  showRole,
  allowMultiple,
  label,
  eventId,
  assignmentId,
  onAddVolunteer,
  onUpdateVolunteer,
  onRemoveAssignment,
  onRemoveVolunteer,
}) => {
  const { t } = useTranslation()
  const [newVolunteer, setNewVolunteer] = useState({})
  const [volunteerToUpdate, setVolunteerToUpdate] = useState({})
  const [shiftDeleteModalIsOpen, setShiftDeleteModalIsOpen] = useState(false)
  const [volunteerFormIsOpen, setVolunteerFormIsOpen] = useState(false)
  const [newVolunteerError, setNewVolunteerError] = useState(false)
  const [updatedVolunteerError, setUpdatedVolunteerError] = useState(false)

  const resetVolunteerForm = openForm => {
    setNewVolunteer({})
    setVolunteerFormIsOpen(openForm)
    setNewVolunteerError(false)
  }

  const assignVolunteer = () => {
    onAddVolunteer(
      eventId,
      assignmentId,
      {
        ...newVolunteer,
        person_id: newVolunteer.person?.id,
        responsibility: newVolunteer.responsibility,
      },
      {
        fields: ['id', 'responsibility', { person: ['id', 'name'] }],
      }
    )
      .then(() => resetVolunteerForm(false))
      .catch(() => {
        setNewVolunteerError(true)
      })
  }

  const updateVolunteer = volunteerId => {
    onUpdateVolunteer(eventId, assignmentId, volunteerId, {
      ...volunteerToUpdate,
      person_id: volunteerToUpdate.person?.id,
      responsibility: volunteerToUpdate.responsibility,
    })
      .then(() => {
        setVolunteerToUpdate({})
      })
      .catch(() => {
        setUpdatedVolunteerError(true)
      })
  }

  const setNewPersonField = (field, val) => {
    setNewVolunteer(prevState => ({ ...prevState, [field]: val }))
  }

  const setUpdatedPersonField = (field, val) => {
    setVolunteerToUpdate(prevState => ({ ...prevState, [field]: val }))
  }

  const handleRemoveAssignment = () => {
    onRemoveAssignment(eventId, assignmentId)
  }

  const handleRemoveVolunteer = volunteerId => {
    onRemoveVolunteer(eventId, assignmentId, volunteerId).then(() => {
      setVolunteerToUpdate({})
    })
  }

  const renderNewVolunteerButton = () => {
    if (allowMultiple !== false) {
      return (
        <ButtonBlock>
          <Button
            aria-label={t('Add volunteer')}
            onClick={() => resetVolunteerForm(true)}
          >
            <Icon.Plus />
          </Button>
        </ButtonBlock>
      )
    }
    return null
  }

  const renderVolunteers = () => {
    const addVolunteerControls = (
      <form>
        <FieldBlock>
          <PersonSelectField
            label={t('Search person name')}
            person={newVolunteer.person}
            excludedPersonIds={volunteers.map(({ person: { id } }) => id)}
            onSelect={val => setNewPersonField('person', val)}
            excludeDeceased
          />
          {showRole ? (
            <ResponsibilitySelectField
              label={t('Responsibility')}
              responsibility={newVolunteer.responsibility}
              onSelect={val => setNewPersonField('responsibility', val)}
            />
          ) : null}
        </FieldBlock>
        <ButtonBlock>
          <Button.Accent
            disabled={
              isEmpty(newVolunteer) ||
              (showRole && !newVolunteer.responsibility)
            }
            onClick={assignVolunteer}
          >
            {t('Assign')}
          </Button.Accent>
          <Button.Secondary onClick={() => resetVolunteerForm(false)}>
            {t('Cancel')}
          </Button.Secondary>
        </ButtonBlock>
        <CardError
          hide={!newVolunteerError}
          message="Please complete all fields"
        />
      </form>
    )
    const updateVolunteerControls = (
      <form>
        <FieldBlock>
          <PersonSelectField
            label={t('Search person name')}
            person={volunteerToUpdate.person}
            disabled
          />
          {showRole ? (
            <ResponsibilitySelectField
              label={t('Responsibility')}
              responsibility={volunteerToUpdate.responsibility}
              onSelect={val => setUpdatedPersonField('responsibility', val)}
            />
          ) : null}
        </FieldBlock>
        <ButtonBlock>
          <Button.Accent
            disabled={
              isEmpty(volunteerToUpdate) ||
              (showRole && !volunteerToUpdate.responsibility)
            }
            onClick={() => updateVolunteer(volunteerToUpdate.id)}
          >
            {t('Assign')}
          </Button.Accent>
          <Button.Danger
            onClick={() => handleRemoveVolunteer(volunteerToUpdate.id)}
          >
            {t('Remove')}
          </Button.Danger>
          <Button.Secondary onClick={() => setVolunteerToUpdate({})}>
            {t('Cancel')}
          </Button.Secondary>
        </ButtonBlock>
        <CardError
          hide={!updatedVolunteerError}
          message="Please complete all fields"
        />
      </form>
    )

    const volunteerList = volunteers?.map(volunteer =>
      volunteer.id === volunteerToUpdate.id ? (
        updateVolunteerControls
      ) : (
        <ListItem
          key={volunteer.id}
          primaryText={volunteer.person.name}
          secondaryText={volunteer.responsibility}
          editable
          onEdit={() => {
            setVolunteerToUpdate(volunteer)
          }}
        />
      )
    )

    if (volunteerList.length === 0) {
      if (volunteerFormIsOpen) {
        return addVolunteerControls
      }
      return (
        <ButtonBlock>
          <Button onClick={() => resetVolunteerForm(true)}>
            {t('Add volunteer')}
          </Button>
          <Button.Danger onClick={handleRemoveAssignment}>
            {t('Delete')} {assignmentLabel}
          </Button.Danger>
        </ButtonBlock>
      )
    }

    const volunteerActions = volunteerFormIsOpen
      ? addVolunteerControls
      : renderNewVolunteerButton()

    return (
      <>
        <List>{volunteerList}</List>
        {volunteerActions}
      </>
    )
  }

  return (
    <div>
      <ButtonBlock>
        <SectionLabel>{label}</SectionLabel>
        <Button.Secondary
          aria-label={t('Delete shift')}
          onClick={() => setShiftDeleteModalIsOpen(true)}
        >
          <Icon.TrashAlt />
        </Button.Secondary>
      </ButtonBlock>
      {renderVolunteers()}
      <ShiftDeleteWarningModal
        isOpen={shiftDeleteModalIsOpen}
        setIsOpen={setShiftDeleteModalIsOpen}
        shiftDeleteRequest={handleRemoveAssignment}
        type="shift"
      />
    </div>
  )
}

export default VolunteerAssignment
