import {
  buildDefaultFilters,
  calculateRate,
  summaryFunctions,
} from 'utils/reporting'

export default {
  id: 'location',
  name: 'Field',
  permissionId: 'cover_sheet',
  description:
    'This report provides information on location performance. The "Address and Coordinates" tab provides information useful for mapping and tracking locations. The "Canvass Totals" tab provides information from the cover sheet and information related to the shift at that location. The "Shift Performance" tab compares shifts with field start times before noon versus field start times that being noon or later.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=126332639',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'location',
      title: 'Location',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'location_category',
      title: 'Location Category',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'street_address',
      title: 'Street Address',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'city',
      title: 'City',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'zipcode',
      title: 'Zipcode',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'county',
      title: 'County',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'latitude',
      title: 'Latitude',
      type: 'number',
      bodyCell: ({ cellData }) => cellData,
      resizable: true,
    },
    {
      dataKey: 'longitude',
      title: 'Longitude',
      type: 'number',
      bodyCell: ({ cellData }) => cellData,
      resizable: true,
    },
    {
      dataKey: 'total_canvassers',
      title: 'Total Canvassers',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_shifts',
      title: 'Total Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'last_canvass_date',
      title: 'Last Canvass Date',
      type: 'date',
      resizable: true,
    },
    {
      dataKey: 'days_since_last_canvass',
      title: 'Days Since Last Canvass',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_forms_cover',
      title: 'Total Forms (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_field_hours_cover',
      title: 'Total Field Hours (Cover)',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'forms_per_field_hour_cover',
      title: 'Forms per Field Hour (Cover)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(
          rowData.total_forms_cover,
          rowData.total_field_hours_cover
        ),
    },
    {
      dataKey: 'total_shift_hours',
      title: 'Total Shift Hours',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'morning_shifts',
      title: 'Morning Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_forms_morning_shifts',
      title: 'Total Forms from Morning Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'afternoon_shifts',
      title: 'Afternoon Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_forms_afternoon_shifts',
      title: 'Total Forms from Afternoon Shifts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_pledge_cards',
      title: 'Total Pledge Cards',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.label, [
      {
        title: 'Default Filter',
        visibleColumns: [
          'turf_name',
          'location',
          'street_address',
          'county',
          'total_shifts',
          'days_since_last_canvass',
          'total_forms_cover',
          'total_field_hours_cover',
          'forms_per_field_hour_cover',
        ],
      },
      {
        title: 'Addresses',
        visibleColumns: [
          'turf_name',
          'location',
          'street_address',
          'city',
          'zipcode',
          'county',
        ],
      },
      {
        title: 'Canvass Totals',
        visibleColumns: [
          'turf_name',
          'location',
          'total_canvassers',
          'total_shifts',
          'total_forms_cover',
          'total_field_hours_cover',
          'forms_per_field_hour_cover',
        ],
      },
    ])
  },
}
