import { sumBy, meanBy, round } from 'lodash/math'
import { calculateRate, calculatePercent } from 'utils/reporting'

export const sum = ({ data, key }) =>
  sumBy(data, row => parseFloat(row[key]) || 0)

export const findOtherColTotals = (data, possibleSource, keys) =>
  keys.map(key => {
    const possibleValue = parseFloat(possibleSource[key])
    if (possibleValue || possibleValue === 0) {
      return possibleValue
    }
    return sum({ data, key })
  })

export const average = ({ data, key }) =>
  round(meanBy(data, row => parseFloat(row[key]) || 0))

export const customPercent = (data, acc, keys) => {
  const [val1, val2] = findOtherColTotals(data, acc, keys)
  return calculatePercent(val1, val2)
}

export const customRatio = (data, acc, keys) => {
  const [val1, val2] = findOtherColTotals(data, acc, keys)
  return calculateRate(val1, val2)
}

export const columnSummaryFunctions = {
  total_scans_cover: sum,
  total_forms_cover: sum,
  total_field_hours_cover: sum,
  total_shift_hours: sum,
  complete_scans_cover: sum,
  incomplete_scans_cover: sum,
  scans_with_phones_cover: sum,
  complete_scan_percent_cover: ({ data, acc }) =>
    customPercent(data, acc, ['complete_scans_cover', 'total_scans_cover']),
  incomplete_scan_percent_cover: ({ data, acc }) =>
    customPercent(data, acc, ['incomplete_scans_cover', 'total_scans_cover']),
  scans_per_field_hour_cover: ({ data, acc }) =>
    customRatio(data, acc, ['total_scans_cover', 'total_field_hours_cover']),
  forms_per_field_hour_cover: ({ data, acc }) =>
    customRatio(data, acc, ['total_forms_cover', 'total_field_hours_cover']),
  total_scans: sum,
  total_scans_qc: sum,
  complete_forms_cover: sum,
  complete_scans_qc: sum,
  incomplete_forms_cover: sum,
  incomplete_scans_qc: sum,
  scans_with_phones_qc: sum,
  days_since_collection: average,
  approved_qc: sum,
  missing_phone_qc: sum,
  needs_reupload_qc: sum,
  handwriting_qc: sum,
  not_form_qc: sum,
  canvasser_assistance_qc: sum,
  other_qc: sum,
  contacted_qc: sum,
  phone_needs_attention_qc: sum,
  confirmed_registered_qc: sum,
  did_not_register_qc: sum,
  correct_address_qc: sum,
  incorrect_address_qc: sum,
  correct_dob_qc: sum,
  incorrect_dob_qc: sum,
  received_feedback_qc: sum,
  duplicate_scans_qc: sum,
  not_home_qc: sum,
  disconnected_qc: sum,
  call_back_qc: sum,
  language_barrier_qc: sum,
  refused_qc: sum,
  wrong_number_qc: sum,
  wrong_voicemail_qc: sum,
  busy_qc: sum,
  do_not_call_qc: sum,
  total_canvassers: sum,
  total_shifts: sum,
  days_since_last_canvass: average,
  morning_shifts: sum,
  total_forms_morning_shifts: sum,
  afternoon_shifts: sum,
  total_forms_afternoon_shifts: sum,
  percent_contacted_qc: ({ data, acc }) =>
    customPercent(data, acc, ['contacted_qc', 'total_scans_called_qc']),
  percent_phone_needs_attention_qc: ({ data, acc }) =>
    customPercent(data, acc, [
      'phone_needs_attention_qc',
      'total_scans_called_qc',
    ]),
  percent_confirmed_registered_qc: ({ data, acc }) =>
    customPercent(data, acc, [
      'confirmed_registered_qc',
      'total_scans_called_qc',
    ]),
  percent_did_not_register_qc: ({ data, acc }) =>
    customPercent(data, acc, ['did_not_register_qc', 'total_scans_called_qc']),
  percent_correct_address_qc: ({ data, acc }) =>
    customPercent(data, acc, ['correct_address_qc', 'total_scans_called_qc']),
  percent_incorrect_address_qc: ({ data, acc }) =>
    customPercent(data, acc, ['incorrect_address_qc', 'total_scans_called_qc']),
  percent_correct_dob_qc: ({ data, acc }) =>
    customPercent(data, acc, ['correct_dob_qc', 'total_scans_called_qc']),
  percent_incorrect_dob_qc: ({ data, acc }) =>
    customPercent(data, acc, ['incorrect_dob_qc', 'total_scans_called_qc']),
  percent_received_feedback_qc: ({ data, acc }) =>
    customPercent(data, acc, ['received_feedback_qc', 'total_scans_called_qc']),
  percent_duplicate_scan_qc: ({ data, acc }) =>
    customPercent(data, acc, ['duplicate_scans_qc', 'total_scans_called_qc']),
  percent_not_home_qc: ({ data, acc }) =>
    customPercent(data, acc, ['not_home_qc', 'total_calls']),
  percent_disconnected_qc: ({ data, acc }) =>
    customPercent(data, acc, ['disconnected_qc', 'total_scans_called_qc']),
  percent_call_back_qc: ({ data, acc }) =>
    customPercent(data, acc, ['call_back_qc', 'total_calls']),
  percent_language_barrier_qc: ({ data, acc }) =>
    customPercent(data, acc, ['language_barrier_qc', 'total_scans_called_qc']),
  percent_refused_qc: ({ data, acc }) =>
    customPercent(data, acc, ['refused_qc', 'total_scans_called_qc']),
  percent_wrong_number_qc: ({ data, acc }) =>
    customPercent(data, acc, ['wrong_number_qc', 'total_scans_called_qc']),
  percent_wrong_voicemail_qc: ({ data, acc }) =>
    customPercent(data, acc, ['wrong_voicemail_qc', 'total_scans_called_qc']),
  percent_busy_qc: ({ data, acc }) =>
    customPercent(data, acc, ['busy_qc', 'total_calls']),
  percent_do_not_call_qc: ({ data, acc }) =>
    customPercent(data, acc, ['do_not_call_qc', 'total_scans_called_qc']),
  reupload_qc: sum,
  percent_approved_qc: ({ data, acc }) =>
    customPercent(data, acc, ['approved_qc', 'total_scans_qc']),
  percent_missing_phone_qc: ({ data, acc }) =>
    customPercent(data, acc, ['missing_phone_qc', 'total_scans_qc']),
  percent_reupload_qc: ({ data, acc }) =>
    customPercent(data, acc, ['reupload_qc', 'total_scans_qc']),
  percent_handwriting_qc: ({ data, acc }) =>
    customPercent(data, acc, ['handwriting_qc', 'total_scans_qc']),
  percent_canvasser_assistance_qc: ({ data, acc }) =>
    customPercent(data, acc, ['canvasser_assistance_qc', 'total_scans_qc']),
  percent_other_qc: ({ data, acc }) =>
    customPercent(data, acc, ['other_qc', 'total_scans_qc']),
  average_rounds_called_qc: ({ data, acc }) =>
    customRatio(data, acc, ['rowData.calls', 'rowData.total_scans_called_qc']),
  total_scans_called_qc: sum,
  percent_scans_called_qc: ({ data, acc }) =>
    customPercent(data, acc, ['total_scans_called_qc', 'scans_with_phones_qc']),
  percent_not_form_qc: ({ data, acc }) =>
    customPercent(data, acc, ['not_form_qc', 'total_scans']),
  overdue: sum,
  zero_days: sum,
  one_days: sum,
  two_days: sum,
  three_days: sum,
  four_days: sum,
  five_days: sum,
  six_days: sum,
  seven_days: sum,
  eight_days: sum,
  nine_days: sum,
  ten_days: sum,
}
