import { uniq } from 'lodash'
import { formatDeliveryStatus } from 'utils/reporting'

export const shiftPacketCsvCell = data =>
  data.name || data.petition_packet?.original_filename

export const shiftPacketCountyCsvCell = data => data.petition_packet?.county

export const shiftTurfNameCsvCell = data => data.turf?.name

export const shiftLocationNameCsvCell = data => data.location?.name

export const shiftCanvasserNameCsvCell = data => data.canvasser?.full_name

export const assigneeNameCsvCell = data =>
  data.petition_packet?.assignee?.full_name

export const shiftCampaignNameCsvCell = data => data.campaign?.name

export const shiftPacketUploadDateCsvCell = data => data.ready_for_qc_at

export const shiftDueDateCsvCell = data => data.qc_deadline

export const shiftVRRCsvCell = data =>
  uniq(data.visual_review_responses.map(response => response.description)).join(
    ', '
  )

export const DeliveryStatusCSVCell = data =>
  formatDeliveryStatus(data.delivered)
