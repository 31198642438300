export default {
  id: 'qc_scans_by_day',
  name: 'Cards Due by State',
  permissionId: 'qc_workflow',
  description:
    'This report provides information on how many scans are left to QC broken down by state and day.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1067817092',
  columns: [
    {
      dataKey: 'state',
      title: 'State',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'overdue',
      title: 'Overdue scans',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'zero_days',
      title: 'Due today',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'one_days',
      title: 'Due tomorrow',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'two_days',
      title: 'Due in 2 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'three_days',
      title: 'Due in 3 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'four_days',
      title: 'Due in 4 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'five_days',
      title: 'Due in 5 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'six_days',
      title: 'Due in 6 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'seven_days',
      title: 'Due in 7 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'eight_days',
      title: 'Due in 8 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'nine_days',
      title: 'Due in 9 days',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'ten_days',
      title: 'Due in 10 days',
      type: 'number',
      resizable: true,
    },
  ],
}
