import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  Icon,
  TextBlock,
  Font,
} from '@politechdev/blocks-design-system'
import { getToken } from 'utils/auth'
import { getApiUrl } from 'utils/req'
import ReviewTable from './ReviewTable'

const ManualReview = ({ deliveryId }) => {
  const { t } = useTranslation()

  const fetchDeliveryCoverSheet = () => {
    window.open(
      getApiUrl(
        `/api/v1/deliveries/${deliveryId}/cover_sheet.pdf?jwt=${getToken()}`
      ),
      '_blank'
    )
  }

  return (
    <>
      <TextBlock>
        <Font.Display variant="small" element="h2">
          {t('Review')}
        </Font.Display>
      </TextBlock>
      <TextBlock>
        <Font.Copy variant="reading-block" element="p">
          {t('Review the selected forms below before delivering.')}
        </Font.Copy>
        <Font.Copy variant="reading-block" element="p">
          {t(
            'Once you are done reviewing the forms, assemble the forms into a delivery package and print two copies of the delivery coversheet provided below. Attach one of the copies to the package and leave the other copy with the registrar.'
          )}
        </Font.Copy>
      </TextBlock>
      <ButtonBlock>
        <Button className="margin--top" onClick={fetchDeliveryCoverSheet}>
          <Icon.ArrowAltToBottom
            aria-label={t('Download form delivery sheet')}
          />
          <span>{t('Form delivery sheet')}</span>
        </Button>
      </ButtonBlock>
      <ReviewTable />
    </>
  )
}

export default memo(ManualReview)
