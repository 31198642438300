import React, { useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import LocationSelect from '../LocationSelect/LocationSelect'
import LocationNew from '../LocationNew/LocationNew'
import LocationEdit from '../LocationEdit/LocationEdit'
import EventLocationNew from '../EventLocationNew/EventLocationNew'
import EventLocationEdit from '../EventLocationEdit/EventLocationEdit'
import EventLocationSingle from '../EventLocationSingle/EventLocationSingle'

const LocationRoutes = ({ match }) => {
  const routeType = useMemo(() => {
    if (match.path.includes('organize/event_locations')) {
      return 'event'
    }
    return 'registration'
  }, [match.path])

  if (routeType === 'event') {
    return (
      <Switch>
        <Route exact path={match.path} component={LocationSelect} />
        <Route exact path={`${match.path}/new`} component={EventLocationNew} />
        <Route
          exact
          path={`${match.path}/:id`}
          component={EventLocationSingle}
        />
        <Route path={`${match.path}/:id/edit`} component={EventLocationEdit} />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact path={match.path} component={LocationSelect} />
      <Route exact path={`${match.path}/new`} component={LocationNew} />
      <Route path={`${match.path}/:id/edit`} component={LocationEdit} />
    </Switch>
  )
}

export default LocationRoutes
