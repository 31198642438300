import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { orderBy } from 'lodash'
import { useContextOrThrow } from 'utils/contexts'
import { isEligibleScanForDelivery } from '../utils'
import {
  EligibleShift,
  EligibleRegistrationForm,
  useDeliveryState,
} from '../DeliveryContext'
import NotAllEligibleDeliveredModal from './Modals/NotAllEligibleDeliveredModal'

type ScannerAssemblyContextState = {
  assembled: string[]
  setAssembled: Dispatch<SetStateAction<string[]>>
  assemblablePackets: EligibleShift[]
  validateAndTransitionDelivery: () => void
}

export const ScannerAssemblyContext = createContext<
  ScannerAssemblyContextState | undefined
>(undefined)

export const ScannerAssemblyProvider: React.FC<{
  onNext: () => void
}> = ({ children, onNext }) => {
  const { currentDelivery, packets } = useDeliveryState()

  const [assembled, setAssembled] = useState<string[]>([])
  const [isAssemblyCompletionModalOpen, setIsFormCompletionModalOpen] =
    useState(false)

  const isEligibleForm = isEligibleScanForDelivery(currentDelivery) as (
    form: EligibleRegistrationForm
  ) => boolean

  const assemblablePackets = !currentDelivery
    ? []
    : packets
        .map(packet => ({
          ...packet,
          forms: orderBy(
            packet.forms.filter(
              form =>
                isEligibleForm(form) || assembled.includes(form.id.toString())
            ),
            'form_number'
          ),
        }))
        .filter(packet => !!packet.forms.length)

  const getAreAllEligibleFormsAssembled = () => {
    const assembledSet = new Set(assembled)
    return assemblablePackets
      .flatMap(packet => packet.forms)
      .every(form => assembledSet.has(form.id.toString()))
  }

  const transitionToNextDeliveryStep = () => {
    setIsFormCompletionModalOpen(false)
    onNext()
  }

  const validateAndTransitionDelivery = () => {
    const areAllEligibleFormsAssembled = getAreAllEligibleFormsAssembled()
    if (areAllEligibleFormsAssembled) {
      transitionToNextDeliveryStep()
    } else {
      setIsFormCompletionModalOpen(true)
    }
  }

  return (
    <ScannerAssemblyContext.Provider
      value={{
        assembled,
        setAssembled,
        assemblablePackets,
        validateAndTransitionDelivery,
      }}
    >
      {children}
      <NotAllEligibleDeliveredModal
        isOpen={isAssemblyCompletionModalOpen}
        setIsOpen={setIsFormCompletionModalOpen}
        handleContinue={transitionToNextDeliveryStep}
      />
    </ScannerAssemblyContext.Provider>
  )
}

export const useScannerAssemblyContext = () =>
  useContextOrThrow(ScannerAssemblyContext)
