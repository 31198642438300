import moment from 'moment'
import {
  PHONE,
  DOB,
  REGISTERED,
  ADDRESS,
  NOT_CONTACTED_REASON,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { usePhoneVerificationQuestions } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import QuestionItem from './QuestionItem'

const PhoneVerificationQuestionItem = ({
  questionKey,
  editable,
  setHasEditedChanges,
  setContactedStatus,
  verificationResponses,
  setVerificationResponses,
  phoneNumber,
  currentScan,
  currentRegistrationForm,
}) => {
  const [questions] = usePhoneVerificationQuestions()
  const { choices, content } = questions[questionKey]

  const registrationDate = currentScan?.shift
    ? moment(currentScan.shift.shift_start).format('MMM D, YYYY')
    : ''

  const { voting_street_address_one: address, date_of_birth: dob } =
    currentRegistrationForm || {}

  const interpolationValues = {
    [DOB]: { dob },
    [PHONE]: { phoneNumber },
    [REGISTERED]: { registrationDate },
    [ADDRESS]: { address },
  }

  const questionContent = content(interpolationValues[questionKey])

  const disabled = !!editable

  const onSelect = response => {
    setVerificationResponses(current => ({
      ...current,
      [questions[questionKey].id]: response,
      [questions[NOT_CONTACTED_REASON].id]: '',
    }))
    setContactedStatus('t')
    setHasEditedChanges(true)
  }

  return (
    <QuestionItem
      questionKey={questionKey}
      disabled={disabled}
      choices={choices}
      content={questionContent}
      onSelect={onSelect}
      selectedChoice={
        editable ? undefined : verificationResponses[questions[questionKey].id]
      }
    />
  )
}

export default PhoneVerificationQuestionItem
