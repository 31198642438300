import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuth } from 'contexts'
import { useReactRouter } from 'hooks'
import Login from '../Login/Login'
import TwoFactor from '../TwoFactor/TwoFactor'
import SetupTwoFactor from '../SetupTwoFactor/SetupTwoFactor'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import ChangePassword from '../ChangePassword/ChangePassword'

const AuthRoutes = () => {
  const { location } = useReactRouter()
  const { validToken, twoFactorRequired, twoFactorEnabled, locked } = useAuth()

  if (
    validToken &&
    !locked &&
    twoFactorRequired &&
    twoFactorEnabled &&
    location.pathname !== '/auth/two_factor'
  ) {
    return <Redirect to="/auth/two_factor" />
  }

  if (
    validToken &&
    !locked &&
    twoFactorRequired &&
    !twoFactorEnabled &&
    location.pathname !== '/auth/two_factor/setup'
  ) {
    return <Redirect to="/auth/two_factor/setup" />
  }

  return (
    <Switch>
      <Route exact path="/auth/login" component={Login} />
      <Route exact path="/auth/two_factor" component={TwoFactor} />
      <Route exact path="/auth/two_factor/setup" component={SetupTwoFactor} />
      <Route exact path="/auth/forgot_password" component={ForgotPassword} />
      <Route
        exact
        path="/auth/create_password"
        render={() => <ChangePassword type="create" />}
      />
      <Route
        exact
        path="/auth/reset_password"
        render={() => <ChangePassword type="reset" />}
      />
      <Route>
        <Redirect to="/auth/login" />
      </Route>
    </Switch>
  )
}

export default AuthRoutes
