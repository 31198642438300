import { TextBlock } from 'components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import { interpolateContent } from './utils'

const DialogueItem = ({
  editable,
  content,
  currentScan,
  currentRegistrationForm,
}) => {
  const { t } = useTranslation()

  const {
    currentUser: { name: callerName },
  } = useCurrent()

  const registrationDate = currentScan?.shift
    ? moment(currentScan.shift.shift_start).format('MMM D, YYYY')
    : ''
  const {
    voting_street_address_one: address,
    date_of_birth: dob,
    first_name: firstName,
    last_name: lastName,
  } = currentRegistrationForm || {}

  const interpolatedContent = editable
    ? content
    : interpolateContent(content, [
        {
          target: t('[registrant name]'),
          value: `${firstName || ''} ${lastName || ''}`,
        },
        {
          target: t('[caller name]'),
          value: callerName,
        },
        {
          target: t('[organization name]'),
          value: currentScan?.turf?.name,
        },
        {
          target: t('[dob]'),
          value: dob,
        },
        {
          target: t('[address]'),
          value: address,
        },
        {
          target: t('[date]'),
          value: registrationDate,
        },
      ])

  return (
    <TextBlock>
      <p>{interpolatedContent}</p>
    </TextBlock>
  )
}

export default DialogueItem
