export const getQcFlagFilter = ({ only360 = false, exclude360 = false }) => {
  if (only360 || exclude360) {
    return {
      column: 'shift_qc_external',
      operator: 'is',
      param: !exclude360 || only360,
    }
  }
  return undefined
}
