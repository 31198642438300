import Uppy, { BasePlugin } from '@uppy/core'
import { PDFDocument } from 'pdf-lib'

export default class PDFSplitter extends BasePlugin {
  constructor(uppy: Uppy, opts: { id?: string } = {}) {
    super(uppy, opts)
    this.id = opts.id || 'pdfsplitter'
    this.type = 'preprocessor'
  }

  processPDFs = async (fileIDs: string[]): Promise<void> => {
    await Promise.all(fileIDs.map(this.splitPDF))
  }

  splitPDF = async (fileId: string) => {
    const { files } = this.uppy.getState()
    const file = files[fileId]

    if (file.source === this.id) return fileId

    this.uppy.removeFile(fileId)

    const pdfBytesBuffer = await file.data.arrayBuffer()
    const pdfDoc = await PDFDocument.load(new Uint8Array(pdfBytesBuffer), {
      updateMetadata: false,
    })

    const separatedPages = pdfDoc.getPages().map(async (_, index, pages) => {
      const subDocument = await PDFDocument.create({ updateMetadata: false })
      const [page] = await subDocument.copyPages(pdfDoc, [index])
      subDocument.addPage(page)

      const pageBytes = await subDocument.save()
      const pageNumber = index.toString().padStart(3, '0')
      const totalPages = pages.length.toString().padStart(3, '0')
      return new File(
        [pageBytes.buffer as ArrayBuffer],
        `${file.name.split('.pdf')[0]}_page_${pageNumber}_of_${totalPages}.pdf`,
        {
          type: file.type,
        }
      )
    })

    const uploadReadyPages = await Promise.all(separatedPages)
    this.uppy.addFiles(
      uploadReadyPages.map(f => ({
        source: this.id,
        name: f.name,
        type: f.type,
        data: f,
      }))
    )
  }

  install() {
    this.uppy.addPreProcessor(this.processPDFs)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.processPDFs)
  }
}
