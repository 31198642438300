import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonBlock,
  FieldBlock,
  List,
  ProgressBar,
  Sheet,
  TextField,
} from '@politechdev/blocks-design-system'
import {
  CardError,
  Paginator,
  TurfSelectField,
  UserSelectField,
  View,
  ViewContainer,
} from 'components'
import { useRequest } from 'hooks'
import { fetchTwilioCalls } from 'requests/callRecordings'
import { formatErrorMessage, getGeneralError } from 'utils/formatting'
import RecordingItem from './RecordingItem'

const CallRecordings = () => {
  const { t } = useTranslation()

  const [activeRecording, setActiveRecording] = useState(null)

  const [filters, setFilters] = useState({
    turfId: null,
    search: '',
    user: null,
    page: 1,
  })

  const [response, setResponse] = useState(null)
  const fetchCalls = useRequest(fetchTwilioCalls, { onSuccess: setResponse })

  const doFetchCalls = filterData => {
    const filters = [
      { column: 'twilio_recording_sid', operator: 'is_nil', invert: true },
    ]

    if (filterData.turfId) {
      filters.push({
        column: 'phone_verification_call_in_turf_tree',
        param: filterData.turfId,
      })
    }

    if (filterData.user) {
      filters.push({
        column: 'user_id',
        operator: 'is',
        param: filterData.user.id,
      })
    }

    if (filterData.search) {
      filters.push({
        column: 'phone_verification_call_id',
        operator: 'containing',
        param: filterData.search,
      })
    }

    return fetchCalls.makeRequest({
      fields: [
        'id',
        'twilio_recording_sid',
        'created_at',
        { user: ['full_name', 'email', 'spoken_language_locales'] },
        {
          phone_verification_call: [
            'id',
            { shift: 'id' },
            { voter_registration_form: ['first_name', 'last_name', 'id'] },
          ],
        },
      ],
      current_page: filterData.page,
      filters: {
        rules: filters,
      },
    })
  }

  useEffect(() => {
    doFetchCalls(filters)
  }, [filters])

  const handleFilterChange = field => value => {
    setFilters(current => ({ ...current, page: 1, [field]: value }))
  }

  const calls = response?.twilio_calls || []
  const responseMeta = response?.meta || null

  const totalPages = responseMeta
    ? Math.ceil(responseMeta.total_count / responseMeta.per)
    : 0

  const handlePageChange = delta => {
    setFilters(current => ({
      ...current,
      page: current.page + delta,
    }))
  }

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <ProgressBar show={fetchCalls.isLoading} />
          <CardError
            hide={!fetchCalls.hasErrors}
            message={
              getGeneralError(fetchCalls.errors) ||
              formatErrorMessage(fetchCalls.errors)
            }
          />
          <FieldBlock>
            <TextField
              label={t('Search')}
              value={filters.search}
              onChange={handleFilterChange('search')}
              placeholder={t('Call id')}
            />
            <UserSelectField
              label={t('Staff member')}
              user={filters.user}
              onSelect={handleFilterChange('user')}
              clearable
              onClear={handleFilterChange('user')}
            />
            <TurfSelectField
              label={t('Turf')}
              value={filters.turfId}
              onSelect={handleFilterChange('turfId')}
              clearable
              onClear={handleFilterChange('turfId')}
            />
          </FieldBlock>
          <List
            itemData={calls}
            render={call => (
              <RecordingItem
                key={call.id}
                call={call}
                isActive={activeRecording === call.id}
                setIsActive={() => setActiveRecording(call.id)}
              />
            )}
            loading={fetchCalls.isLoading}
          />
          <ButtonBlock justify="right">
            <Paginator
              currentPage={filters.page}
              totalPages={totalPages || 1}
              onNext={() => handlePageChange(1)}
              onPrevious={() => handlePageChange(-1)}
            />
          </ButtonBlock>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default CallRecordings
