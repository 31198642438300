import { CardError, Sheet } from 'components'
import {
  Button,
  ProgressBar,
  ButtonBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoTableProvider, useCurrent } from 'contexts'
import { useReactRouter, useBackgroundRequest } from 'hooks'
import { useRequest } from 'hooks/useRequest'
import PacketTable from 'qualityControl/packetAssignment/packetSummary/PacketTable'
import ProgressCharts from 'qualityControl/packetAssignment/packetSummary/ProgressCharts'
import { requestChartId, requestChart } from 'requests/charts'
import { currentDateYMDDash, startOfCurrentYearYMDDash } from 'utils/dateTime'
import { ALL_PACKETS_TYPE, qcPacketFields } from 'constants/qualityControl'
import { formatErrorMessage } from 'utils/formatting'
import { fetchPacket } from 'requests/tableQcManager'
import ReassignPacketsModal from '../modals/ReassignPacketsModal'
import ReprioritizePacketsModal from '../modals/ReprioritizePacketsModal'
import ReprioritizeTurfPacketsModal from '../modals/ReprioritizeTurfPacketsModal/ReprioritizeTurfPacketsModal'
import { SummaryPacketsCheckboxProvider } from './CheckboxContextProvider'
import AssignPacketsToUserModal from '../modals/AssignPacketsToUserModal/AssignPacketsToUserModal'
import { usePacketAssignment } from '../PacketAssignmentContext'

const PacketSummary = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [packetTableVisible, toggleAllPacketsTable] = useState(false)

  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false)

  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    setPacketsBeingAssigned,
    setIsReassignmentModalOpen,
    setPacketsBeingReprioritized,
    setIsReprioritizationModalOpen,
  } = usePacketAssignment()

  const [qcProgressData, setQcProgressData] = useState([])
  const [qcProgressBarsData, setQcProgressBarsData] = useState([])

  const {
    makeRequest: deadlineRequest,
    isLoading: deadlineLoading,
    errorMsg: deadlineError,
    isSuccessful: deadlineSuccessful,
    response: deadlineResponse,
  } = useBackgroundRequest({
    key: 'turf_qc_deadlines',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters: {
          startDate: startOfCurrentYearYMDDash,
          endDate: currentDateYMDDash,
        },
      }),
    requestData: requestChart,
  })

  const {
    makeRequest: progressRequest,
    isLoading: progressLoading,
    errorMsg: progressError,
    isSuccessful: progressSuccessful,
    response: progressResponse,
  } = useBackgroundRequest({
    key: 'turf_daily_qc_progress',
    requestId: type =>
      requestChartId({
        type,
        userTurfId: turfId,
        currentTurfPerformsExternalQC,
        filters: {
          startDate: startOfCurrentYearYMDDash,
          endDate: currentDateYMDDash,
        },
      }),
    requestData: requestChart,
  })

  const chartsLoading = deadlineLoading || progressLoading
  const chartsError = deadlineError || progressError
  const chartsSuccessful = deadlineSuccessful && progressSuccessful

  useEffect(() => {
    deadlineRequest()
    progressRequest()
  }, [])

  useEffect(() => {
    if (deadlineResponse) {
      setQcProgressData(deadlineResponse.rows)
    }
  }, [deadlineResponse])

  useEffect(() => {
    if (progressResponse) {
      setQcProgressBarsData(progressResponse.rows)
    }
  }, [progressResponse])

  const {
    makeRequest: fetchReassignModalDataReq,
    isLoading: reassignModalDataLoading,
    errors: reassignModalDataErrors,
  } = useRequest(fetchPacket, {
    onSuccess: ({ shift }) => {
      setPacketsBeingAssigned([shift])
      setIsReassignmentModalOpen(true)
    },
  })

  const reassignModalDataErrorMsg = formatErrorMessage(reassignModalDataErrors)

  const {
    makeRequest: fetchReprioritizeModalDataReq,
    isLoading: reprioritizeModalDataLoading,
    errors: reprioritizeModalDataErrors,
  } = useRequest(fetchPacket, {
    onSuccess: ({ shift }) => {
      setPacketsBeingReprioritized([shift])
      setIsReprioritizationModalOpen(true)
    },
  })

  const reprioritizeModalDataErrorMsg = formatErrorMessage(
    reprioritizeModalDataErrors
  )

  const viewInbox = ({ assigned_user }) => {
    history.push(`/quality_control/manage/assign/${assigned_user.id}/inbox`)
  }

  const assignPacket = partialPacket => {
    fetchReassignModalDataReq(partialPacket.id, {
      fields: qcPacketFields,
    })
  }

  const prioritizePacket = partialPacket => {
    fetchReprioritizeModalDataReq(partialPacket.id, {
      fields: qcPacketFields,
    })
  }

  return (
    <AutoTableProvider
      initialTableId="all-packets"
      enableQueryParams
      enablePagination
      enableFilters
      enableSort
      showFilterEditor
      defaultPageSize={100}
    >
      <CardError
        hide={
          !(
            chartsError ||
            reassignModalDataErrorMsg ||
            reprioritizeModalDataErrorMsg
          )
        }
        message={
          chartsError ||
          reassignModalDataErrorMsg ||
          reprioritizeModalDataErrorMsg
        }
      />
      {chartsSuccessful && (
        <ProgressCharts
          qcProgressBarsData={qcProgressBarsData}
          qcProgressData={qcProgressData}
        />
      )}
      <Sheet>
        <ProgressBar
          show={
            chartsLoading ||
            reassignModalDataLoading ||
            reprioritizeModalDataLoading
          }
        />
        <ButtonBlock>
          <Button.Secondary
            onClick={() => toggleAllPacketsTable(!packetTableVisible)}
          >
            <span>
              {`${packetTableVisible ? t('Hide') : t('View')}`} {t('packets')}
            </span>
            {packetTableVisible ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
          </Button.Secondary>
        </ButtonBlock>
        {packetTableVisible && (
          <PacketTable
            assignPacket={assignPacket}
            prioritizePacket={prioritizePacket}
            viewInbox={viewInbox}
            openReassignModal={() => setIsReassignModalOpen(true)}
          />
        )}
      </Sheet>
      <AssignPacketsToUserModal
        isOpen={isReassignModalOpen}
        setIsOpen={setIsReassignModalOpen}
      />
      <ReassignPacketsModal type={ALL_PACKETS_TYPE} />
      <ReprioritizePacketsModal />
      <ReprioritizeTurfPacketsModal />
    </AutoTableProvider>
  )
}

export default () => (
  <SummaryPacketsCheckboxProvider>
    <PacketSummary />
  </SummaryPacketsCheckboxProvider>
)
