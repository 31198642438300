import {
  contactMethodOptions,
  phoneTypeOptions,
  emailTypeOptions,
  interestLevelOptions,
} from 'constants/people'
import {
  eventResponsibilitiesFormatForUI,
  eventResponsibilitiesFormatForAPI,
  fromUiEventResponsibilitiesValidator,
  fromApiEventResponsibilitiesValidator,
} from './EventResponsibilitiesField/utils'
import {
  fromApiPhonebankTagsValidator,
  fromUiPhonebankTagsValidator,
  phonebankTagsFormatForAPI,
  phonebankTagsFormatForUI,
} from './PhoneBankTagField/utils'
import { FilterType, Option } from './types'

export const FILTER_GROUPS = [
  {
    id: 'requiredFilters',
    validationKey: 'requiredFiltersAreValid',
    title: 'Match all',
    subtitle: 'Show records that match selections in all applied filter boxes',
  },
  {
    id: 'conditionalFilters',
    validationKey: 'conditionalFiltersAreValid',
    title: 'Match any',
    subtitle: 'Show records that match selections in any applied filter box',
  },
  {
    id: 'excludedFilters',
    validationKey: 'excludedFiltersAreValid',
    title: 'Exclude any',
    subtitle: 'Exclude records that match selections in any applied filter box',
  },
]

export const FILTER_SECTIONS = [
  {
    id: 1,
    name: 'Locations',
  },
  {
    id: 2,
    name: 'Districts',
  },
  {
    id: 3,
    name: 'Demographics',
  },
  {
    id: 4,
    name: 'Contact Information',
  },
  {
    id: 5,
    name: 'Affiliations',
  },
  {
    id: 6,
    name: 'Actions',
  },
  {
    id: 7,
    name: 'Events',
  },
  {
    id: 8,
    name: 'Phone Banking',
  },
  {
    id: 9,
    name: 'History',
  },
  {
    id: 10,
    name: 'Additional Information',
  },
]

export const BASE_FILTER_TYPES: FilterType[] = [
  {
    id: 'counties',
    name: 'Counties',
    sectionId: 1,
    resourceLabelKey: 'name',
  },
  {
    id: 'states',
    name: 'States',
    sectionId: 1,
    column: 'state',
  },
  {
    id: 'zipcodes',
    name: 'Zip Codes',
    sectionId: 1,
  },
  {
    id: 'cities',
    name: 'Cities',
    sectionId: 1,
    column: 'residential_address_city',
  },
  {
    id: 'turfs',
    name: 'Turfs',
    sectionId: 1,
    column: 'in_turf_tree',
  },
  {
    id: 'congressional_districts',
    name: 'Congressional Districts',
    sectionId: 2,
    column: 'residential_address_congressional_district',
    districtType: 'congressional',
  },
  {
    id: 'state_legislative_upper_districts',
    name: 'State Legislative Upper Districts',
    sectionId: 2,
    column: 'residential_address_state_legislative_upper_district',
    districtType: 'state_legislative_upper',
  },
  {
    id: 'state_legislative_lower_districts',
    name: 'State Legislative Lower Districts',
    sectionId: 2,
    column: 'residential_address_state_legislative_lower_district',
    districtType: 'state_legislative_lower',
  },
  {
    id: 'ethnicities',
    name: 'Race/Ethnicity',
    sectionId: 3,
  },
  {
    id: 'genders',
    name: 'Gender',
    sectionId: 3,
  },
  {
    id: 'ages',
    name: 'Age',
    sectionId: 3,
  },
  {
    id: 'primary_languages',
    name: 'Primary Languages',
    sectionId: 3,
  },
  {
    id: 'languages',
    name: 'Secondary Languages',
    sectionId: 3,
  },
  {
    id: 'skills',
    name: 'Skills',
    sectionId: 3,
  },
  {
    id: 'denominations',
    name: 'Denominations',
    sectionId: 3,
    resourceLabelKey: 'description',
  },
  {
    id: 'best_contact_methods',
    name: 'Best Contact Methods',
    sectionId: 4,
    fieldOptions: contactMethodOptions as Option[],
  },
  {
    id: 'phone_types',
    name: 'Phone Types',
    sectionId: 4,
    fieldOptions: phoneTypeOptions as Option[],
  },
  {
    id: 'email_types',
    name: 'Email Types',
    sectionId: 4,
    fieldOptions: emailTypeOptions as Option[],
  },
  {
    id: 'organizations',
    name: 'Organizations',
    sectionId: 5,
    resourceLabelKey: 'name',
  },
  {
    id: 'teams',
    name: 'Teams',
    sectionId: 5,
    resourceLabelKey: 'name',
  },
  {
    id: 'issues',
    name: 'Issues',
    sectionId: 6,
  },
  {
    id: 'responsibilities',
    name: 'Team Roles',
    sectionId: 6,
    resourceLabelKey: 'name',
  },
  {
    id: 'interest_level',
    name: 'Interest Level',
    sectionId: 6,
    fieldOptions: interestLevelOptions,
  },
  {
    id: 'events_attended',
    name: 'Events Attended',
    sectionId: 7,
    resourceLabelKey: 'name',
  },
  {
    id: 'events_attended_by_date',
    name: 'Events by Date',
    sectionId: 7,
  },
  {
    id: 'meetings_by_date',
    name: 'Meetings by Date',
    sectionId: 7,
  },
  {
    id: 'meetings_with_user',
    name: 'Meetings with Host',
    sectionId: 7,
    resourceLabelKey: 'name',
  },
  {
    id: 'events_by_type',
    name: 'Event by type',
    sectionId: 7,
    resourceLabelKey: 'name',
  },
  {
    id: 'events_by_venue',
    name: 'Event by Venue',
    sectionId: 7,
    resourceLabelKey: 'name',
  },
  {
    id: 'events_by_org',
    name: 'Event by Institution',
    sectionId: 7,
    resourceLabelKey: 'name',
  },
  {
    id: 'events_by_team',
    name: 'Event by Team',
    sectionId: 7,
  },
  {
    id: 'event_responsibilities',
    name: 'Event Responsibilities',
    sectionId: 7,
    validateFromAPI: fromApiEventResponsibilitiesValidator,
    validateFromUI: fromUiEventResponsibilitiesValidator,
    formatForUI: eventResponsibilitiesFormatForUI,
    formatForAPI: eventResponsibilitiesFormatForAPI,
  },
  {
    id: 'event_by_creator',
    name: 'Events by Creator',
    sectionId: 7,
  },
  {
    id: 'phone_banking_response',
    name: 'Phone bank response',
    sectionId: 8,
  },
  {
    id: 'phone_banking_tag',
    name: 'Phone bank tags',
    sectionId: 8,
    validateFromAPI: fromApiPhonebankTagsValidator,
    validateFromUI: fromUiPhonebankTagsValidator,
    formatForUI: phonebankTagsFormatForUI,
    formatForAPI: phonebankTagsFormatForAPI,
  },
  {
    id: 'created_at',
    name: 'Date added to Blocks',
    sectionId: 9,
  },
  {
    id: 'assigned_to',
    name: 'Organizer',
    sectionId: 9,
    resourceLabelKey: 'full_name',
  },
  {
    id: 'deceased',
    name: 'Deceased',
    sectionId: 9,
  },
]
