import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchForm = <T>(id: string | number, params: unknown) =>
  fetchJSON<T>(`/api/v1/forms/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const putForm = <T>(
  id: string | number,
  form: Partial<T>,
  params: unknown
) =>
  fetchJSON<T>(
    `/api/v1/forms/${id}?${stringifyParams(params)}`,
    'PUT',
    { form },
    {
      useJwt: true,
    }
  )

export const fetchForms = <T>(params: unknown) =>
  fetchJSON<T>(`/api/v1/forms?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })
