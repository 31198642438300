import { formatTextforLabels } from 'catalistReports/CatalistPlot/plotUtils'
import { TRUE_FALSE_OPTIONS } from 'registrationReports/constants'
import {
  calculatePercent,
  formatDecimalCell,
  summaryFunctions,
} from 'utils/reporting'

export const baseMatchProcessColumns = [
  {
    dataKey: 'match_status',
    title: 'Match Status',
    type: 'string',
    resizable: true,
  },
  {
    dataKey: 'voter_status',
    title: 'Catalist Voter Status',
    type: 'string',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      rowData.voter_status ? formatTextforLabels(rowData.voter_status) : '',
  },
  {
    dataKey: 'address_match',
    title: 'Catalist Address Matches',
    options: TRUE_FALSE_OPTIONS,
    type: 'enum',
    resizable: true,
    deriveFunction: ({ rowData }) => rowData.address_match?.toString(),
  },
  {
    dataKey: 'forms',
    title: 'Forms',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'percent_of_total_forms',
    title: 'Percent of Total Forms',
    type: 'number',
    resizable: true,
    bodyCell: formatDecimalCell,
    deriveFunction: ({ rowData }) =>
      calculatePercent(rowData.forms, rowData.total_forms),
  },
]
