import { formatPhoneNumber } from 'utils/formatting'

export const buildOption = (person, isDisabled, disabledPersonReason) => {
  const personName =
    person.name ||
    [person.first_name, person.middle_name, person.last_name]
      .filter(Boolean)
      .join(' ')

  const city = person.residential_address?.city
  const state = person.residential_address?.state
  const zip = person.residential_address?.zipcode
  const addressItems = []
  city && addressItems.push(state ? `${city}, ` : city)
  state && addressItems.push(state)
  zip && addressItems.push(zip)
  const personAddress = addressItems.join(' ')

  if (isDisabled) {
    return {
      value: person.id,
      label: `${personName} - (${disabledPersonReason})`,
      address: personAddress,
      phone: formatPhoneNumber(person.primary_phone_number),
      disabled: true,
    }
  }

  return {
    value: person.id,
    label: personName,
    address: personAddress,
    phone: formatPhoneNumber(person.primary_phone_number),
  }
}

export const buildParams = ({ query, filters = [], fields = [] }) => ({
  filters: {
    rules: filters.concat(
      query
        ? [{ column: 'full_name', param: query, operator: 'containing' }]
        : []
    ),
  },
  sort_attr: 'full_name',
  sort_dir: 'asc',
  per: 25,
  indexed: true,
  fields: [
    'id',
    'name',
    ...fields.filter(field => field !== 'id' && field !== 'name'),
  ],
})

export const buildOptions = (
  person,
  disabledPersonIds,
  disabledPersonReason
) => {
  if (disabledPersonIds.includes(person.id)) {
    return buildOption(person, true, disabledPersonReason)
  }
  return buildOption(person, false, disabledPersonReason)
}
