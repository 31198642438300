export const permissionCategories = [
  {
    id: 'general',
    name: 'General',
    reportTypes: ['packet'],
    permissionId: 'registration_general',
  },
  {
    id: 'coverSheet',
    name: 'Field',
    reportTypes: [
      'canvasser_quality_by_group',
      'canvasser_quality',
      'county',
      'location',
    ],
    permissionId: 'cover_sheet',
  },
  {
    id: 'coverSheetTablet',
    name: 'Field (tablet)',
    reportTypes: [
      'canvasser_quality_by_group_tablet',
      'canvasser_quality_tablet',
      'county_tablet',
      'location_tablet',
    ],
    permissionId: 'cover_sheet_tablet',
  },
  {
    id: 'coverSheetParty',
    name: 'Field (partisan)',
    reportTypes: [
      'canvasser_quality_by_group_party',
      'canvasser_quality_party',
      'county',
      'location_party',
    ],
    permissionId: 'cover_sheet_party_report',
  },
  {
    id: 'qcProgram',
    name: 'Program',
    reportTypes: [
      'qc_status',
      'qc_by_canvasser',
      'qc_by_group',
      'qc_by_visual_response',
    ],
    permissionId: 'qc_program',
  },
  {
    id: 'validation',
    name: 'Verification',
    reportTypes: [
      'validated_forms_by_turf',
      'validated_forms_by_canvasser',
      'duplicate_applicants_by_canvasser',
    ],
    isSelectable: false,
    permissionId: 'form_validation',
  },
  {
    id: 'party',
    name: 'Party Preference',
    reportTypes: ['party_by_turf', 'party_by_canvasser', 'party_by_location'],
    permissionId: 'party_report',
  },
  {
    id: 'party_lean',
    name: 'Party Lean',
    reportTypes: [
      'party_lean_by_turf',
      'party_lean_by_canvasser',
      'party_lean_by_location',
    ],
    permissionId: 'party_lean_report',
  },
  {
    id: 'district',
    name: 'District',
    reportTypes: [
      'district_by_turf',
      'district_by_canvasser',
      'district_by_location',
    ],
    permissionId: 'district_report',
  },
  {
    id: 'partisan_score',
    name: 'Partisan Score',
    reportTypes: [
      'partisan_score_by_turf',
      'partisan_score_by_canvasser',
      'partisan_score_by_location',
    ],
    permissionId: 'partisan_score_report',
  },
  {
    id: 'qcFlags',
    name: 'Flags',
    reportTypes: ['qc_flag_status', 'qc_flags_by_date'],
    permissionId: 'qc_flags',
  },
  {
    id: 'qcWorkflow',
    name: 'QC Workflow',
    reportTypes: [
      'qc_summary_by_user',
      'qc_summary_by_role',
      'qc_scans_by_day',
      'qc_notes_by_scan',
    ],
    permissionId: 'qc_workflow',
  },
  {
    id: 'qcStaff',
    name: 'QC Staff',
    reportTypes: [
      'qc_phone_calls_status',
      'qc_phone_calls_by_user',
      'daily_qc',
      'viz_by_day',
      'phone_by_day',
      'qc_by_response',
      'qc_packets_by_day',
    ],
    permissionId: 'qc_staff',
  },
]

export const permissionIds = permissionCategories.map(
  category => category.permissionId
)
