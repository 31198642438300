import { mean } from 'lodash/math'
import moment from 'moment-timezone'
import { customRatio } from 'registrationReports/reportsConfig/columnTotals'
import { calculateRate, combineName } from 'utils/reporting'

export default {
  id: 'qc_phone_calls_by_user',
  name: 'Phone Calls by QC Staff',
  permissionId: 'qc_staff',
  description: 'This report provides information on QC calls grouped by user.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1BVEQgVRqNt8YB1P_FU2RZSs_udtVtSnF3ME3LiFg8Xc/edit#gid=1396168495',
  columns: [
    {
      dataKey: 'caller_name',
      title: 'Staff name',
      type: 'string',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        combineName(rowData.first_name, rowData.last_name),
    },
    {
      dataKey: 'qc_office',
      title: 'QC Office',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'total_calls',
      title: 'Total calls',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'total_contacts',
      title: 'Total contacts',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'contact_rate',
      title: 'Contact rate',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_contacts, rowData.total_calls),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, ['total_contacts', 'total_calls']),
    },
    {
      dataKey: 'total_verified',
      title: 'Total verified',
      type: 'number',
      resizable: true,
    },
    {
      dataKey: 'verified_rate',
      title: 'Verified rate',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_verified, rowData.total_calls),
      summaryFunction: ({ data, acc }) =>
        customRatio(data, acc, ['total_verified', 'total_calls']),
    },
    {
      dataKey: 'avg_length_of_call',
      title: 'Avg length of call (in mins)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => rowData.avg_length_of_call * 1000,
      bodyCell: ({ cellData }) =>
        Number.isNaN(cellData)
          ? '-'
          : moment.utc(cellData).format('HH:mm:ss.S'),
      csvCell: ({ avg_length_of_call }) =>
        Number.isNaN(avg_length_of_call)
          ? avg_length_of_call
          : moment.utc(avg_length_of_call).format('HH:mm:ss.S'),
      summaryFunction: ({ data }) => {
        if (!data[0]) return '-'
        const allValues = Object.values(data).map(value =>
          parseFloat(value.avg_length_of_call)
        )
        const realNumberValues = allValues.filter(value => !Number.isNaN(value))
        const meanValue = mean(realNumberValues)
        if (Number.isNaN(meanValue)) {
          return '-'
        }
        return moment
          .utc(Math.round(meanValue / 100) * 100)
          .format('HH:mm:ss.S')
      },
    },
  ],
}
