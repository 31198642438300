import { useState } from 'react'
import {
  ChartToolbar,
  ChartContainer,
  ChartResponsive,
  BarChart,
} from 'components'
import {
  SelectField,
  FieldBlock,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import ReprioritizeTurfPacketButton from './ReprioritizeTurfPacketButton'
import ProgressBars from './ProgressBars'

const ProgressCharts = ({ qcProgressBarsData, qcProgressData }) => {
  const { t } = useTranslation()

  const [selectedState, setSelectedState] = useState()
  const receiveCurrentState = state => {
    setSelectedState(state === 'all' ? null : state)
  }

  const normalizeBarChartData = originalData => {
    const chartCounter = {
      Overdue: {},
      'Due today': {},
      '1 day left': {},
      '2-5 days left': {},
      '5 or more days left': {},
    }

    const updateCounter = (series, dataIndex) => {
      Object.keys(series).forEach(key => {
        key === 'daysLeft'
          ? (chartCounter[dataIndex].daysLeft = dataIndex)
          : (chartCounter[dataIndex][key] =
              (chartCounter[dataIndex][key] || 0) + series[key])
      })
    }

    const buildRow = series => ({
      [series.state]: series.forms_count,
      daysLeft: series.days_left,
    })

    originalData.forEach(series => {
      if (Number.isNaN(series.days_left)) return
      if (series.days_left < 0) {
        updateCounter(buildRow(series), 'Overdue')
      }
      if (series.days_left === 0) {
        updateCounter(buildRow(series), 'Due today')
      }
      if (series.days_left === 1) {
        updateCounter(buildRow(series), '1 day left')
      }
      if (series.days_left > 1 && series.days_left < 6) {
        updateCounter(buildRow(series), '2-5 days left')
      }
      if (series.days_left > 5) {
        updateCounter(buildRow(series), '5 or more days left')
      }
    })

    const normalizedData = []
    Object.values(chartCounter).forEach(item => {
      if (Object.entries(item).length) normalizedData.push(item)
    })

    return normalizedData
  }

  const chartData = qcProgressData ? normalizeBarChartData(qcProgressData) : []

  const states = qcProgressData
    ? [...new Set(qcProgressData?.map(row => row.state))]
    : []

  const stateOptions = states
    ? [
        { label: t('All States'), value: 'all' },
        ...states.map(state => ({ label: state, value: state })),
      ]
    : [{ label: t('All States'), value: 'all' }]

  return (
    <div>
      <ChartContainer title={t('Scans left to QC')}>
        <ChartToolbar>
          <FieldBlock>
            <SelectField
              id="select-state"
              label={t('State')}
              name="state"
              onSelect={receiveCurrentState}
              options={stateOptions}
              value={selectedState || 'all'}
            />
          </FieldBlock>
        </ChartToolbar>
        <ChartResponsive>
          <BarChart
            data={chartData}
            keys={selectedState ? [selectedState] : states}
            indexBy="daysLeft"
            yAxisLabel={t('Scans')}
            emptyMessage={t('No packets found')}
            interactiveLegend={!selectedState}
            legendWidth={40}
            possibleKeys={states}
          />
        </ChartResponsive>
        <ProgressBars data={qcProgressBarsData} />
        <ChartToolbar>
          <ButtonBlock justify="right">
            <ReprioritizeTurfPacketButton />
          </ButtonBlock>
        </ChartToolbar>
      </ChartContainer>
    </div>
  )
}

export default ProgressCharts
