import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, Calendar, TurfSelectField } from 'components'
import {
  SelectField,
  FieldBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchShifts } from 'requests/shifts'
import { useCurrent, useTurfs } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import ShiftCalendarItem from '../ShiftCalendarItem/ShiftCalendarItem'
import ShiftCalendarLegend from '../ShiftCalendarLegend/ShiftCalendarLegend'
import { buildParams, buildShifts } from './utils'
import ShiftCalendarWeekDownload from '../ShiftCalendarWeekDownload/ShiftCalendarWeekDownload'

const ShiftCalendar = () => {
  const { t } = useTranslation()
  const {
    currentUser: {
      turf: { id: topLevelTurfId },
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const canViewShifts = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'view',
  })

  if (!canViewShifts) {
    return (
      <CardError
        hide={canViewShifts}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const [turfId, setTurfId] = useState(topLevelTurfId)
  const [shiftType, setShiftType] = useState('all')

  useEffect(() => {
    refreshCurrentTurfs({
      associations: [''],
      fields: ['id', 'name', 'parent_id', 'archived'],
      indexed: true,
    })
  }, [])

  const [dateRange, setDateRange] = useState(null)
  const fetchShiftsRequest = useRequest(fetchShifts)

  useEffect(() => {
    dateRange &&
      fetchShiftsRequest.makeRequest(
        buildParams({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          turfId,
          shiftType,
        })
      )
  }, [dateRange, turfId, shiftType])

  const shifts = (fetchShiftsRequest.response?.shifts || []).map(buildShifts)

  return (
    <div>
      <Calendar
        items={shifts}
        isLoading={fetchShiftsRequest.isLoading || turfRefreshIsLoading}
        errorMsg={
          fetchShiftsRequest.hasErrors
            ? formatErrorMessage(fetchShiftsRequest.errors)
            : null
        }
        onRangeChange={setDateRange}
        renderItem={ShiftCalendarItem}
        renderFilters={() => (
          <FieldBlock>
            <TurfSelectField label={t('Turf')} onSelect={setTurfId} />
            <SelectField
              id="shift-type"
              label={t('Shift type')}
              options={[
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'petitions',
                  label: 'Petitions',
                },
                {
                  value: 'voter_registration',
                  label: 'Voter Registration',
                },
              ]}
              value={shiftType}
              onSelect={setShiftType}
            />
          </FieldBlock>
        )}
        dateKey="shift_start"
        WeekDownload={ShiftCalendarWeekDownload}
      />
      <ContentBlock>
        <ShiftCalendarLegend />
      </ContentBlock>
    </div>
  )
}

export default ShiftCalendar
