import { useTranslation } from 'react-i18next'
import { Font, Button, Icon } from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest, useRouteQueryParams } from 'hooks'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import { advanceShiftStatus } from 'requests/qualityControl'
import { COMPLETED, COMPLETED_TAB } from 'constants/qualityControl'
import styles from './SubmitForDeliveryBanner.module.scss'

const SubmitForDeliveryBanner = ({ show, currentPacket }) => {
  const { t } = useTranslation()

  const { history } = useReactRouter()
  const [{ inspectedUserId }] = useRouteQueryParams()
  const { setLastActiveInboxTab } = useInbox()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: completePacket } = useRequest(
    shift => advanceShiftStatus(shift.id, COMPLETED),
    {
      onSuccess: () => {
        setLastActiveInboxTab(COMPLETED_TAB)
        inspectedUserId
          ? history.push(
              `/quality_control/manage/assign/${inspectedUserId}/inbox`
            )
          : history.push(`/quality_control/inbox`)
      },
    }
  )

  return show ? (
    <div className={styles.container}>
      <Font.Copy variant="highlight" className={styles.text}>
        {t('This packet meets the phone verification criteria')}
      </Font.Copy>
      <Button.Accent onClick={() => completePacket(currentPacket)}>
        <span>{t('Submit phone verification')}</span>
        <Icon.PaperPlane />
      </Button.Accent>
    </div>
  ) : null
}

export default SubmitForDeliveryBanner
