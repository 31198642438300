import { useTranslation } from 'react-i18next'
import 'react-multi-carousel/lib/styles.css'
import {
  CoverPdfViewer,
  ScanPdfViewer,
} from 'qualityControl/scanReview/PdfViewer'
import GalleryCarousel from '../GalleryCarousel/GalleryCarousel'
import styles from './GalleryViewer.module.scss'

const GalleryViewer = ({ cover, selectedNum, forms }) => {
  const { t } = useTranslation()

  const selectedScan = forms.find(f => f.scan_number === +selectedNum)

  return (
    <div className={styles.container}>
      <div className={styles.viewer}>
        <div className={styles.scan}>
          {selectedNum === 'cover' ? (
            <CoverPdfViewer
              className={styles.pdf}
              url={cover.file_url}
              removedAt={cover.file_locator?.metadata?.redacted_at}
            />
          ) : (
            <ScanPdfViewer
              className={styles.pdf}
              scanUrl={selectedScan.file_url}
              removedAt={selectedScan.file_locator?.metadata?.redacted_at}
            />
          )}
        </div>
        <h2>
          {selectedNum === 'cover'
            ? t('Cover Sheet')
            : `${t('Scan')} #${selectedScan.scan_number}`}
        </h2>
      </div>
      <GalleryCarousel
        cover={cover}
        forms={forms}
        selectedScanNums={[selectedNum]}
      />
    </div>
  )
}

export default GalleryViewer
