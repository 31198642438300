import React, { createContext, useContext, useState } from 'react'

const NavigationContext = createContext()

export const NavigationProvider = ({ children }) => {
  const [isCollapsed, setCollapsed] = useState(false)

  const toggleCollapsed = () => setCollapsed(e => !e)

  return (
    <NavigationContext.Provider
      value={{
        isCollapsed,
        setCollapsed,
        toggleCollapsed,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }
  return context
}
