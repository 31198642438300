import { useEffect, useState } from 'react'
import {
  MultiSelectField,
  SelectField,
} from '@politechdev/blocks-design-system'
import countiesByState from 'utils/counties.json'
import { useTranslation } from 'react-i18next'
import { uniqueCounties } from 'utils/constants'
import { alphaNumericCompare } from 'utils/array'
import { buildOption } from './utils'

const CountySelectField = ({
  id = 'county',
  label = 'County',
  onSelect,
  state = '',
  states = [],
  county,
  counties = [],
  isMulti = false,
  extraOptions = [],
  scrollValue = undefined,
  ...props
}) => {
  const { t } = useTranslation()

  const countyStates = state ? [state] : states

  const dedupedCounties = countyStates.length
    ? Array.from(
        new Set(countyStates.flatMap(state => countiesByState[state] ?? []))
      )
    : uniqueCounties

  const countyOptions = Array.from(dedupedCounties)
    .toSorted(alphaNumericCompare)
    .map(buildOption)
    .concat(extraOptions)

  const [value, setValue] = useState(county)
  const [multiValue, setMultiValue] = useState(counties)

  useEffect(() => {
    setValue(county)
  }, [county])

  useEffect(() => {
    setMultiValue(counties)
  }, [JSON.stringify(counties)])

  const handleChange = selection => {
    onSelect && onSelect(selection)
  }

  const handleMultiChange = selections => {
    onSelect && onSelect(selections)
  }

  return isMulti ? (
    <MultiSelectField
      id={id}
      label={label || t('Counties')}
      options={countyOptions}
      value={multiValue}
      onSelect={handleMultiChange}
      noOptionsMessage={t('Select a state first')}
      {...props}
    />
  ) : (
    <div>
      <SelectField
        id={id}
        label={label || t('County')}
        options={countyOptions}
        value={value}
        onSelect={handleChange}
        noOptionsMessage={t('Select a state first')}
        scrollValue={!value ? scrollValue : value}
        {...props}
      />
    </div>
  )
}

export default CountySelectField
