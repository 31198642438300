import {
  Button,
  ButtonBlock,
  Font,
  Modal,
  Section,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const NotAllEligibleDeliveredModal = ({
  isOpen,
  setIsOpen,
  handleContinue,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  handleContinue: () => void
}) => {
  const { t } = useTranslation()

  const onContinue = () => {
    handleContinue()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal title={t('Continue with unscanned forms?')} isOpen={isOpen}>
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {t(
                'There are still some eligible forms in this delivery that were not scanned for assembly.'
              )}
            </Font.Copy>
            <Font.Copy Element="p">
              {t(
                'Any forms that are not scanned will be considered excluded from this delivery and eligible for future deliveries. Are you sure you want to continue without those remaining forms?'
              )}
            </Font.Copy>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onContinue}>{t('Continue')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default NotAllEligibleDeliveredModal
